/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRubric = /* GraphQL */ `
  mutation CreateRubric(
    $input: CreateRubricInput!
    $condition: ModelRubricConditionInput
  ) {
    createRubric(input: $input, condition: $condition) {
      id
      taskType
      rubricName
      description
      maxScore
      progressComments {
        items {
          id
          rubricId
          score
          taskType
          progressComment
          createdAt
          updatedAt
        }
        nextToken
      }
      benchmarks {
        items {
          id
          taskType
          promptID
          yearLevelID
          rubricID
          benchmark
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRubric = /* GraphQL */ `
  mutation UpdateRubric(
    $input: UpdateRubricInput!
    $condition: ModelRubricConditionInput
  ) {
    updateRubric(input: $input, condition: $condition) {
      id
      taskType
      rubricName
      description
      maxScore
      progressComments {
        items {
          id
          rubricId
          score
          taskType
          progressComment
          createdAt
          updatedAt
        }
        nextToken
      }
      benchmarks {
        items {
          id
          taskType
          promptID
          yearLevelID
          rubricID
          benchmark
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRubric = /* GraphQL */ `
  mutation DeleteRubric(
    $input: DeleteRubricInput!
    $condition: ModelRubricConditionInput
  ) {
    deleteRubric(input: $input, condition: $condition) {
      id
      taskType
      rubricName
      description
      maxScore
      progressComments {
        items {
          id
          rubricId
          score
          taskType
          progressComment
          createdAt
          updatedAt
        }
        nextToken
      }
      benchmarks {
        items {
          id
          taskType
          promptID
          yearLevelID
          rubricID
          benchmark
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRubricProgressComments = /* GraphQL */ `
  mutation CreateRubricProgressComments(
    $input: CreateRubricProgressCommentsInput!
    $condition: ModelRubricProgressCommentsConditionInput
  ) {
    createRubricProgressComments(input: $input, condition: $condition) {
      id
      rubricId
      rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      score
      taskType
      progressComment
      createdAt
      updatedAt
    }
  }
`;
export const updateRubricProgressComments = /* GraphQL */ `
  mutation UpdateRubricProgressComments(
    $input: UpdateRubricProgressCommentsInput!
    $condition: ModelRubricProgressCommentsConditionInput
  ) {
    updateRubricProgressComments(input: $input, condition: $condition) {
      id
      rubricId
      rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      score
      taskType
      progressComment
      createdAt
      updatedAt
    }
  }
`;
export const deleteRubricProgressComments = /* GraphQL */ `
  mutation DeleteRubricProgressComments(
    $input: DeleteRubricProgressCommentsInput!
    $condition: ModelRubricProgressCommentsConditionInput
  ) {
    deleteRubricProgressComments(input: $input, condition: $condition) {
      id
      rubricId
      rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      score
      taskType
      progressComment
      createdAt
      updatedAt
    }
  }
`;
export const createPrompt = /* GraphQL */ `
  mutation CreatePrompt(
    $input: CreatePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    createPrompt(input: $input, condition: $condition) {
      id
      taskType
      promptName
      text
      stimulus
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePrompt = /* GraphQL */ `
  mutation UpdatePrompt(
    $input: UpdatePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    updatePrompt(input: $input, condition: $condition) {
      id
      taskType
      promptName
      text
      stimulus
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePrompt = /* GraphQL */ `
  mutation DeletePrompt(
    $input: DeletePromptInput!
    $condition: ModelPromptConditionInput
  ) {
    deletePrompt(input: $input, condition: $condition) {
      id
      taskType
      promptName
      text
      stimulus
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      classroomID
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      AssignedStudents
      SelectedRubrics {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      schoolID
      schoolYear
      creatorEmail
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      classroomID
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      AssignedStudents
      SelectedRubrics {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      schoolID
      schoolYear
      creatorEmail
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      classroomID
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      AssignedStudents
      SelectedRubrics {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      schoolID
      schoolYear
      creatorEmail
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const createRubricBenchmark = /* GraphQL */ `
  mutation CreateRubricBenchmark(
    $input: CreateRubricBenchmarkInput!
    $condition: ModelRubricBenchmarkConditionInput
  ) {
    createRubricBenchmark(input: $input, condition: $condition) {
      id
      taskType
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      yearLevelID
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      benchmark
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRubricBenchmark = /* GraphQL */ `
  mutation UpdateRubricBenchmark(
    $input: UpdateRubricBenchmarkInput!
    $condition: ModelRubricBenchmarkConditionInput
  ) {
    updateRubricBenchmark(input: $input, condition: $condition) {
      id
      taskType
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      yearLevelID
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      benchmark
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRubricBenchmark = /* GraphQL */ `
  mutation DeleteRubricBenchmark(
    $input: DeleteRubricBenchmarkInput!
    $condition: ModelRubricBenchmarkConditionInput
  ) {
    deleteRubricBenchmark(input: $input, condition: $condition) {
      id
      taskType
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      yearLevelID
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      benchmark
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSchoolWAM = /* GraphQL */ `
  mutation CreateSchoolWAM(
    $input: CreateSchoolWAMInput!
    $condition: ModelSchoolWAMConditionInput
  ) {
    createSchoolWAM(input: $input, condition: $condition) {
      id
      schoolID
      currentLicencesAvailable
      currentStatus
      moduleType
      totalLicences
      startDate
      endDate
      createdAt
      updatedAt
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const updateSchoolWAM = /* GraphQL */ `
  mutation UpdateSchoolWAM(
    $input: UpdateSchoolWAMInput!
    $condition: ModelSchoolWAMConditionInput
  ) {
    updateSchoolWAM(input: $input, condition: $condition) {
      id
      schoolID
      currentLicencesAvailable
      currentStatus
      moduleType
      totalLicences
      startDate
      endDate
      createdAt
      updatedAt
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const deleteSchoolWAM = /* GraphQL */ `
  mutation DeleteSchoolWAM(
    $input: DeleteSchoolWAMInput!
    $condition: ModelSchoolWAMConditionInput
  ) {
    deleteSchoolWAM(input: $input, condition: $condition) {
      id
      schoolID
      currentLicencesAvailable
      currentStatus
      moduleType
      totalLicences
      startDate
      endDate
      createdAt
      updatedAt
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const createWAMLicenceRequestHistory = /* GraphQL */ `
  mutation CreateWAMLicenceRequestHistory(
    $input: CreateWAMLicenceRequestHistoryInput!
    $condition: ModelWAMLicenceRequestHistoryConditionInput
  ) {
    createWAMLicenceRequestHistory(input: $input, condition: $condition) {
      id
      schoolID
      startDate
      endDate
      numberOfLicencesAssigned
      transactionType
      requesterEmail
      schoolYear
      moduleType
      remarks
      isATrial
      wasReversed
      createdAt
      updatedAt
      Requester {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const updateWAMLicenceRequestHistory = /* GraphQL */ `
  mutation UpdateWAMLicenceRequestHistory(
    $input: UpdateWAMLicenceRequestHistoryInput!
    $condition: ModelWAMLicenceRequestHistoryConditionInput
  ) {
    updateWAMLicenceRequestHistory(input: $input, condition: $condition) {
      id
      schoolID
      startDate
      endDate
      numberOfLicencesAssigned
      transactionType
      requesterEmail
      schoolYear
      moduleType
      remarks
      isATrial
      wasReversed
      createdAt
      updatedAt
      Requester {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const deleteWAMLicenceRequestHistory = /* GraphQL */ `
  mutation DeleteWAMLicenceRequestHistory(
    $input: DeleteWAMLicenceRequestHistoryInput!
    $condition: ModelWAMLicenceRequestHistoryConditionInput
  ) {
    deleteWAMLicenceRequestHistory(input: $input, condition: $condition) {
      id
      schoolID
      startDate
      endDate
      numberOfLicencesAssigned
      transactionType
      requesterEmail
      schoolYear
      moduleType
      remarks
      isATrial
      wasReversed
      createdAt
      updatedAt
      Requester {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const createWAMStudentLicenceHistory = /* GraphQL */ `
  mutation CreateWAMStudentLicenceHistory(
    $input: CreateWAMStudentLicenceHistoryInput!
    $condition: ModelWAMStudentLicenceHistoryConditionInput
  ) {
    createWAMStudentLicenceHistory(input: $input, condition: $condition) {
      id
      schoolID
      allocatorEmail
      schoolStudentID
      transactionType
      moduleType
      schoolYear
      createdAt
      updatedAt
      Allocator {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      SchoolStudent {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateWAMStudentLicenceHistory = /* GraphQL */ `
  mutation UpdateWAMStudentLicenceHistory(
    $input: UpdateWAMStudentLicenceHistoryInput!
    $condition: ModelWAMStudentLicenceHistoryConditionInput
  ) {
    updateWAMStudentLicenceHistory(input: $input, condition: $condition) {
      id
      schoolID
      allocatorEmail
      schoolStudentID
      transactionType
      moduleType
      schoolYear
      createdAt
      updatedAt
      Allocator {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      SchoolStudent {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteWAMStudentLicenceHistory = /* GraphQL */ `
  mutation DeleteWAMStudentLicenceHistory(
    $input: DeleteWAMStudentLicenceHistoryInput!
    $condition: ModelWAMStudentLicenceHistoryConditionInput
  ) {
    deleteWAMStudentLicenceHistory(input: $input, condition: $condition) {
      id
      schoolID
      allocatorEmail
      schoolStudentID
      transactionType
      moduleType
      schoolYear
      createdAt
      updatedAt
      Allocator {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      SchoolStudent {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createRubricVideo = /* GraphQL */ `
  mutation CreateRubricVideo(
    $input: CreateRubricVideoInput!
    $condition: ModelRubricVideoConditionInput
  ) {
    createRubricVideo(input: $input, condition: $condition) {
      id
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      yearLevelID
      videoLink
      createdAt
      updatedAt
      YearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRubricVideo = /* GraphQL */ `
  mutation UpdateRubricVideo(
    $input: UpdateRubricVideoInput!
    $condition: ModelRubricVideoConditionInput
  ) {
    updateRubricVideo(input: $input, condition: $condition) {
      id
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      yearLevelID
      videoLink
      createdAt
      updatedAt
      YearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRubricVideo = /* GraphQL */ `
  mutation DeleteRubricVideo(
    $input: DeleteRubricVideoInput!
    $condition: ModelRubricVideoConditionInput
  ) {
    deleteRubricVideo(input: $input, condition: $condition) {
      id
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      yearLevelID
      videoLink
      createdAt
      updatedAt
      YearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBooklet = /* GraphQL */ `
  mutation CreateBooklet(
    $input: CreateBookletInput!
    $condition: ModelBookletConditionInput
  ) {
    createBooklet(input: $input, condition: $condition) {
      id
      name
      bookletContent
      areQuestionsRandomised
      createdAt
      updatedAt
      bookletQuestions {
        items {
          id
          acCodeID
          yearLevelID
          learningAreaID
          strandID
          substrandID
          question
          questionType
          imageRow
          answerSelectionType
          answerTitle
          messageForCorrectAnswer
          messageForIncorrectAnswer
          explanation
          points
          difficulty
          hasHTMLContent
          isPremium
          bookletID
          orderInBooklet
          isClozeQuestion
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBooklet = /* GraphQL */ `
  mutation UpdateBooklet(
    $input: UpdateBookletInput!
    $condition: ModelBookletConditionInput
  ) {
    updateBooklet(input: $input, condition: $condition) {
      id
      name
      bookletContent
      areQuestionsRandomised
      createdAt
      updatedAt
      bookletQuestions {
        items {
          id
          acCodeID
          yearLevelID
          learningAreaID
          strandID
          substrandID
          question
          questionType
          imageRow
          answerSelectionType
          answerTitle
          messageForCorrectAnswer
          messageForIncorrectAnswer
          explanation
          points
          difficulty
          hasHTMLContent
          isPremium
          bookletID
          orderInBooklet
          isClozeQuestion
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBooklet = /* GraphQL */ `
  mutation DeleteBooklet(
    $input: DeleteBookletInput!
    $condition: ModelBookletConditionInput
  ) {
    deleteBooklet(input: $input, condition: $condition) {
      id
      name
      bookletContent
      areQuestionsRandomised
      createdAt
      updatedAt
      bookletQuestions {
        items {
          id
          acCodeID
          yearLevelID
          learningAreaID
          strandID
          substrandID
          question
          questionType
          imageRow
          answerSelectionType
          answerTitle
          messageForCorrectAnswer
          messageForIncorrectAnswer
          explanation
          points
          difficulty
          hasHTMLContent
          isPremium
          bookletID
          orderInBooklet
          isClozeQuestion
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createStudentEALDProgressMapLevelResponse = /* GraphQL */ `
  mutation CreateStudentEALDProgressMapLevelResponse(
    $input: CreateStudentEALDProgressMapLevelResponseInput!
    $condition: ModelStudentEALDProgressMapLevelResponseConditionInput
  ) {
    createStudentEALDProgressMapLevelResponse(
      input: $input
      condition: $condition
    ) {
      id
      studentProgressMapId
      LevelId
      achievement
      comment
      createdAt
      updatedAt
      studentProgressMapItemResponse {
        items {
          id
          studentProgressMapId
          studentProgressMapLevelResponseId
          itemId
          achievement
          createdAt
          updatedAt
        }
        nextToken
      }
      studentProgressMap {
        id
        studentId
        isLocked
        isCarryOver
        previousMapId
        nextMapId
        stage
        currentYearLevelID
        schoolYear
        createdAt
        updatedAt
        studentProgressMapLevelResponses {
          nextToken
        }
        currentYearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateStudentEALDProgressMapLevelResponse = /* GraphQL */ `
  mutation UpdateStudentEALDProgressMapLevelResponse(
    $input: UpdateStudentEALDProgressMapLevelResponseInput!
    $condition: ModelStudentEALDProgressMapLevelResponseConditionInput
  ) {
    updateStudentEALDProgressMapLevelResponse(
      input: $input
      condition: $condition
    ) {
      id
      studentProgressMapId
      LevelId
      achievement
      comment
      createdAt
      updatedAt
      studentProgressMapItemResponse {
        items {
          id
          studentProgressMapId
          studentProgressMapLevelResponseId
          itemId
          achievement
          createdAt
          updatedAt
        }
        nextToken
      }
      studentProgressMap {
        id
        studentId
        isLocked
        isCarryOver
        previousMapId
        nextMapId
        stage
        currentYearLevelID
        schoolYear
        createdAt
        updatedAt
        studentProgressMapLevelResponses {
          nextToken
        }
        currentYearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteStudentEALDProgressMapLevelResponse = /* GraphQL */ `
  mutation DeleteStudentEALDProgressMapLevelResponse(
    $input: DeleteStudentEALDProgressMapLevelResponseInput!
    $condition: ModelStudentEALDProgressMapLevelResponseConditionInput
  ) {
    deleteStudentEALDProgressMapLevelResponse(
      input: $input
      condition: $condition
    ) {
      id
      studentProgressMapId
      LevelId
      achievement
      comment
      createdAt
      updatedAt
      studentProgressMapItemResponse {
        items {
          id
          studentProgressMapId
          studentProgressMapLevelResponseId
          itemId
          achievement
          createdAt
          updatedAt
        }
        nextToken
      }
      studentProgressMap {
        id
        studentId
        isLocked
        isCarryOver
        previousMapId
        nextMapId
        stage
        currentYearLevelID
        schoolYear
        createdAt
        updatedAt
        studentProgressMapLevelResponses {
          nextToken
        }
        currentYearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createStudentEALDProgressMapItemResponse = /* GraphQL */ `
  mutation CreateStudentEALDProgressMapItemResponse(
    $input: CreateStudentEALDProgressMapItemResponseInput!
    $condition: ModelStudentEALDProgressMapItemResponseConditionInput
  ) {
    createStudentEALDProgressMapItemResponse(
      input: $input
      condition: $condition
    ) {
      id
      studentProgressMapId
      studentProgressMapLevelResponseId
      itemId
      achievement
      createdAt
      updatedAt
      studentProgressMapLevelResponse {
        id
        studentProgressMapId
        LevelId
        achievement
        comment
        createdAt
        updatedAt
        studentProgressMapItemResponse {
          nextToken
        }
        studentProgressMap {
          id
          studentId
          isLocked
          isCarryOver
          previousMapId
          nextMapId
          stage
          currentYearLevelID
          schoolYear
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateStudentEALDProgressMapItemResponse = /* GraphQL */ `
  mutation UpdateStudentEALDProgressMapItemResponse(
    $input: UpdateStudentEALDProgressMapItemResponseInput!
    $condition: ModelStudentEALDProgressMapItemResponseConditionInput
  ) {
    updateStudentEALDProgressMapItemResponse(
      input: $input
      condition: $condition
    ) {
      id
      studentProgressMapId
      studentProgressMapLevelResponseId
      itemId
      achievement
      createdAt
      updatedAt
      studentProgressMapLevelResponse {
        id
        studentProgressMapId
        LevelId
        achievement
        comment
        createdAt
        updatedAt
        studentProgressMapItemResponse {
          nextToken
        }
        studentProgressMap {
          id
          studentId
          isLocked
          isCarryOver
          previousMapId
          nextMapId
          stage
          currentYearLevelID
          schoolYear
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteStudentEALDProgressMapItemResponse = /* GraphQL */ `
  mutation DeleteStudentEALDProgressMapItemResponse(
    $input: DeleteStudentEALDProgressMapItemResponseInput!
    $condition: ModelStudentEALDProgressMapItemResponseConditionInput
  ) {
    deleteStudentEALDProgressMapItemResponse(
      input: $input
      condition: $condition
    ) {
      id
      studentProgressMapId
      studentProgressMapLevelResponseId
      itemId
      achievement
      createdAt
      updatedAt
      studentProgressMapLevelResponse {
        id
        studentProgressMapId
        LevelId
        achievement
        comment
        createdAt
        updatedAt
        studentProgressMapItemResponse {
          nextToken
        }
        studentProgressMap {
          id
          studentId
          isLocked
          isCarryOver
          previousMapId
          nextMapId
          stage
          currentYearLevelID
          schoolYear
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createStudentEALDProgressMap = /* GraphQL */ `
  mutation CreateStudentEALDProgressMap(
    $input: CreateStudentEALDProgressMapInput!
    $condition: ModelStudentEALDProgressMapConditionInput
  ) {
    createStudentEALDProgressMap(input: $input, condition: $condition) {
      id
      studentId
      isLocked
      isCarryOver
      previousMapId
      nextMapId
      stage
      currentYearLevelID
      schoolYear
      createdAt
      updatedAt
      studentProgressMapLevelResponses {
        items {
          id
          studentProgressMapId
          LevelId
          achievement
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      currentYearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateStudentEALDProgressMap = /* GraphQL */ `
  mutation UpdateStudentEALDProgressMap(
    $input: UpdateStudentEALDProgressMapInput!
    $condition: ModelStudentEALDProgressMapConditionInput
  ) {
    updateStudentEALDProgressMap(input: $input, condition: $condition) {
      id
      studentId
      isLocked
      isCarryOver
      previousMapId
      nextMapId
      stage
      currentYearLevelID
      schoolYear
      createdAt
      updatedAt
      studentProgressMapLevelResponses {
        items {
          id
          studentProgressMapId
          LevelId
          achievement
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      currentYearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteStudentEALDProgressMap = /* GraphQL */ `
  mutation DeleteStudentEALDProgressMap(
    $input: DeleteStudentEALDProgressMapInput!
    $condition: ModelStudentEALDProgressMapConditionInput
  ) {
    deleteStudentEALDProgressMap(input: $input, condition: $condition) {
      id
      studentId
      isLocked
      isCarryOver
      previousMapId
      nextMapId
      stage
      currentYearLevelID
      schoolYear
      createdAt
      updatedAt
      studentProgressMapLevelResponses {
        items {
          id
          studentProgressMapId
          LevelId
          achievement
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      currentYearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      wondeID
      MISID
      email
      mobileNumber
      userGroup
      userType
      enabled
      avatar {
        bucket
        region
        key
      }
      userSchoolID
      lastSignIn
      dbType
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      wondeID
      MISID
      email
      mobileNumber
      userGroup
      userType
      enabled
      avatar {
        bucket
        region
        key
      }
      userSchoolID
      lastSignIn
      dbType
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userId
      firstName
      lastName
      wondeID
      MISID
      email
      mobileNumber
      userGroup
      userType
      enabled
      avatar {
        bucket
        region
        key
      }
      userSchoolID
      lastSignIn
      dbType
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const createUserSettingsData = /* GraphQL */ `
  mutation CreateUserSettingsData(
    $input: CreateUserSettingsDataInput!
    $condition: ModelUserSettingsDataConditionInput
  ) {
    createUserSettingsData(input: $input, condition: $condition) {
      id
      email
      settingsKey
      settingsData
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSettingsData = /* GraphQL */ `
  mutation UpdateUserSettingsData(
    $input: UpdateUserSettingsDataInput!
    $condition: ModelUserSettingsDataConditionInput
  ) {
    updateUserSettingsData(input: $input, condition: $condition) {
      id
      email
      settingsKey
      settingsData
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSettingsData = /* GraphQL */ `
  mutation DeleteUserSettingsData(
    $input: DeleteUserSettingsDataInput!
    $condition: ModelUserSettingsDataConditionInput
  ) {
    deleteUserSettingsData(input: $input, condition: $condition) {
      id
      email
      settingsKey
      settingsData
      createdAt
      updatedAt
    }
  }
`;
export const createSystemParameter = /* GraphQL */ `
  mutation CreateSystemParameter(
    $input: CreateSystemParameterInput!
    $condition: ModelSystemParameterConditionInput
  ) {
    createSystemParameter(input: $input, condition: $condition) {
      id
      key
      paramData
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemParameter = /* GraphQL */ `
  mutation UpdateSystemParameter(
    $input: UpdateSystemParameterInput!
    $condition: ModelSystemParameterConditionInput
  ) {
    updateSystemParameter(input: $input, condition: $condition) {
      id
      key
      paramData
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemParameter = /* GraphQL */ `
  mutation DeleteSystemParameter(
    $input: DeleteSystemParameterInput!
    $condition: ModelSystemParameterConditionInput
  ) {
    deleteSystemParameter(input: $input, condition: $condition) {
      id
      key
      paramData
      createdAt
      updatedAt
    }
  }
`;
export const createLearningArea = /* GraphQL */ `
  mutation CreateLearningArea(
    $input: CreateLearningAreaInput!
    $condition: ModelLearningAreaConditionInput
  ) {
    createLearningArea(input: $input, condition: $condition) {
      id
      areaName
      colour
      createdAt
      updatedAt
    }
  }
`;
export const updateLearningArea = /* GraphQL */ `
  mutation UpdateLearningArea(
    $input: UpdateLearningAreaInput!
    $condition: ModelLearningAreaConditionInput
  ) {
    updateLearningArea(input: $input, condition: $condition) {
      id
      areaName
      colour
      createdAt
      updatedAt
    }
  }
`;
export const deleteLearningArea = /* GraphQL */ `
  mutation DeleteLearningArea(
    $input: DeleteLearningAreaInput!
    $condition: ModelLearningAreaConditionInput
  ) {
    deleteLearningArea(input: $input, condition: $condition) {
      id
      areaName
      colour
      createdAt
      updatedAt
    }
  }
`;
export const createAcStrand = /* GraphQL */ `
  mutation CreateAcStrand(
    $input: CreateAcStrandInput!
    $condition: ModelAcStrandConditionInput
  ) {
    createAcStrand(input: $input, condition: $condition) {
      id
      strandName
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      substrands {
        items {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAcStrand = /* GraphQL */ `
  mutation UpdateAcStrand(
    $input: UpdateAcStrandInput!
    $condition: ModelAcStrandConditionInput
  ) {
    updateAcStrand(input: $input, condition: $condition) {
      id
      strandName
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      substrands {
        items {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAcStrand = /* GraphQL */ `
  mutation DeleteAcStrand(
    $input: DeleteAcStrandInput!
    $condition: ModelAcStrandConditionInput
  ) {
    deleteAcStrand(input: $input, condition: $condition) {
      id
      strandName
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      substrands {
        items {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAcSubstrand = /* GraphQL */ `
  mutation CreateAcSubstrand(
    $input: CreateAcSubstrandInput!
    $condition: ModelAcSubstrandConditionInput
  ) {
    createAcSubstrand(input: $input, condition: $condition) {
      id
      substrandName
      strandID
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
    }
  }
`;
export const updateAcSubstrand = /* GraphQL */ `
  mutation UpdateAcSubstrand(
    $input: UpdateAcSubstrandInput!
    $condition: ModelAcSubstrandConditionInput
  ) {
    updateAcSubstrand(input: $input, condition: $condition) {
      id
      substrandName
      strandID
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
    }
  }
`;
export const deleteAcSubstrand = /* GraphQL */ `
  mutation DeleteAcSubstrand(
    $input: DeleteAcSubstrandInput!
    $condition: ModelAcSubstrandConditionInput
  ) {
    deleteAcSubstrand(input: $input, condition: $condition) {
      id
      substrandName
      strandID
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
    }
  }
`;
export const createYearLevel = /* GraphQL */ `
  mutation CreateYearLevel(
    $input: CreateYearLevelInput!
    $condition: ModelYearLevelConditionInput
  ) {
    createYearLevel(input: $input, condition: $condition) {
      id
      yearCode
      numericYearCode
      description
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateYearLevel = /* GraphQL */ `
  mutation UpdateYearLevel(
    $input: UpdateYearLevelInput!
    $condition: ModelYearLevelConditionInput
  ) {
    updateYearLevel(input: $input, condition: $condition) {
      id
      yearCode
      numericYearCode
      description
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteYearLevel = /* GraphQL */ `
  mutation DeleteYearLevel(
    $input: DeleteYearLevelInput!
    $condition: ModelYearLevelConditionInput
  ) {
    deleteYearLevel(input: $input, condition: $condition) {
      id
      yearCode
      numericYearCode
      description
      type
      createdAt
      updatedAt
    }
  }
`;
export const createAcCode = /* GraphQL */ `
  mutation CreateAcCode(
    $input: CreateAcCodeInput!
    $condition: ModelAcCodeConditionInput
  ) {
    createAcCode(input: $input, condition: $condition) {
      id
      acCode
      strandID
      substrandID
      yearLevelID
      learningAreaID
      curriculumEntry
      skill
      criteria
      elaboration
      type
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      substrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAcCode = /* GraphQL */ `
  mutation UpdateAcCode(
    $input: UpdateAcCodeInput!
    $condition: ModelAcCodeConditionInput
  ) {
    updateAcCode(input: $input, condition: $condition) {
      id
      acCode
      strandID
      substrandID
      yearLevelID
      learningAreaID
      curriculumEntry
      skill
      criteria
      elaboration
      type
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      substrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAcCode = /* GraphQL */ `
  mutation DeleteAcCode(
    $input: DeleteAcCodeInput!
    $condition: ModelAcCodeConditionInput
  ) {
    deleteAcCode(input: $input, condition: $condition) {
      id
      acCode
      strandID
      substrandID
      yearLevelID
      learningAreaID
      curriculumEntry
      skill
      criteria
      elaboration
      type
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      substrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSchoolNetwork = /* GraphQL */ `
  mutation CreateSchoolNetwork(
    $input: CreateSchoolNetworkInput!
    $condition: ModelSchoolNetworkConditionInput
  ) {
    createSchoolNetwork(input: $input, condition: $condition) {
      id
      networkName
      createdAt
      updatedAt
      schools {
        items {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSchoolNetwork = /* GraphQL */ `
  mutation UpdateSchoolNetwork(
    $input: UpdateSchoolNetworkInput!
    $condition: ModelSchoolNetworkConditionInput
  ) {
    updateSchoolNetwork(input: $input, condition: $condition) {
      id
      networkName
      createdAt
      updatedAt
      schools {
        items {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSchoolNetwork = /* GraphQL */ `
  mutation DeleteSchoolNetwork(
    $input: DeleteSchoolNetworkInput!
    $condition: ModelSchoolNetworkConditionInput
  ) {
    deleteSchoolNetwork(input: $input, condition: $condition) {
      id
      networkName
      createdAt
      updatedAt
      schools {
        items {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      dummy
      schoolName
      motto
      studentLoginEnabled
      ealdProgress
      wondeID
      MISID
      networkID
      countryID
      stateID
      logo {
        bucket
        region
        key
      }
      overrideWAMAccess
      overridePAMAccess
      createdAt
      updatedAt
      schoolNetwork {
        id
        networkName
        createdAt
        updatedAt
        schools {
          nextToken
        }
      }
      attributeCategories {
        items {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolIcseas {
        items {
          id
          schoolID
          schoolYear
          icsea
          createdAt
          updatedAt
        }
        nextToken
      }
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
      state {
        id
        name
        stateCode
        countryID
        createdAt
        updatedAt
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
      }
      students {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      dummy
      schoolName
      motto
      studentLoginEnabled
      ealdProgress
      wondeID
      MISID
      networkID
      countryID
      stateID
      logo {
        bucket
        region
        key
      }
      overrideWAMAccess
      overridePAMAccess
      createdAt
      updatedAt
      schoolNetwork {
        id
        networkName
        createdAt
        updatedAt
        schools {
          nextToken
        }
      }
      attributeCategories {
        items {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolIcseas {
        items {
          id
          schoolID
          schoolYear
          icsea
          createdAt
          updatedAt
        }
        nextToken
      }
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
      state {
        id
        name
        stateCode
        countryID
        createdAt
        updatedAt
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
      }
      students {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      dummy
      schoolName
      motto
      studentLoginEnabled
      ealdProgress
      wondeID
      MISID
      networkID
      countryID
      stateID
      logo {
        bucket
        region
        key
      }
      overrideWAMAccess
      overridePAMAccess
      createdAt
      updatedAt
      schoolNetwork {
        id
        networkName
        createdAt
        updatedAt
        schools {
          nextToken
        }
      }
      attributeCategories {
        items {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolIcseas {
        items {
          id
          schoolID
          schoolYear
          icsea
          createdAt
          updatedAt
        }
        nextToken
      }
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
      state {
        id
        name
        stateCode
        countryID
        createdAt
        updatedAt
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
      }
      students {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSchoolAttributeCategory = /* GraphQL */ `
  mutation CreateSchoolAttributeCategory(
    $input: CreateSchoolAttributeCategoryInput!
    $condition: ModelSchoolAttributeCategoryConditionInput
  ) {
    createSchoolAttributeCategory(input: $input, condition: $condition) {
      id
      schoolID
      categoryName
      createdAt
      updatedAt
      attributes {
        items {
          id
          schoolID
          categoryID
          attributeName
          attributeType
          defaultValue
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSchoolAttributeCategory = /* GraphQL */ `
  mutation UpdateSchoolAttributeCategory(
    $input: UpdateSchoolAttributeCategoryInput!
    $condition: ModelSchoolAttributeCategoryConditionInput
  ) {
    updateSchoolAttributeCategory(input: $input, condition: $condition) {
      id
      schoolID
      categoryName
      createdAt
      updatedAt
      attributes {
        items {
          id
          schoolID
          categoryID
          attributeName
          attributeType
          defaultValue
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSchoolAttributeCategory = /* GraphQL */ `
  mutation DeleteSchoolAttributeCategory(
    $input: DeleteSchoolAttributeCategoryInput!
    $condition: ModelSchoolAttributeCategoryConditionInput
  ) {
    deleteSchoolAttributeCategory(input: $input, condition: $condition) {
      id
      schoolID
      categoryName
      createdAt
      updatedAt
      attributes {
        items {
          id
          schoolID
          categoryID
          attributeName
          attributeType
          defaultValue
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSchoolStudentAttribute = /* GraphQL */ `
  mutation CreateSchoolStudentAttribute(
    $input: CreateSchoolStudentAttributeInput!
    $condition: ModelSchoolStudentAttributeConditionInput
  ) {
    createSchoolStudentAttribute(input: $input, condition: $condition) {
      id
      schoolID
      categoryID
      attributeName
      attributeType
      defaultValue
      createdAt
      updatedAt
      category {
        id
        schoolID
        categoryName
        createdAt
        updatedAt
        attributes {
          nextToken
        }
      }
    }
  }
`;
export const updateSchoolStudentAttribute = /* GraphQL */ `
  mutation UpdateSchoolStudentAttribute(
    $input: UpdateSchoolStudentAttributeInput!
    $condition: ModelSchoolStudentAttributeConditionInput
  ) {
    updateSchoolStudentAttribute(input: $input, condition: $condition) {
      id
      schoolID
      categoryID
      attributeName
      attributeType
      defaultValue
      createdAt
      updatedAt
      category {
        id
        schoolID
        categoryName
        createdAt
        updatedAt
        attributes {
          nextToken
        }
      }
    }
  }
`;
export const deleteSchoolStudentAttribute = /* GraphQL */ `
  mutation DeleteSchoolStudentAttribute(
    $input: DeleteSchoolStudentAttributeInput!
    $condition: ModelSchoolStudentAttributeConditionInput
  ) {
    deleteSchoolStudentAttribute(input: $input, condition: $condition) {
      id
      schoolID
      categoryID
      attributeName
      attributeType
      defaultValue
      createdAt
      updatedAt
      category {
        id
        schoolID
        categoryName
        createdAt
        updatedAt
        attributes {
          nextToken
        }
      }
    }
  }
`;
export const createSchoolIcsea = /* GraphQL */ `
  mutation CreateSchoolIcsea(
    $input: CreateSchoolIcseaInput!
    $condition: ModelSchoolIcseaConditionInput
  ) {
    createSchoolIcsea(input: $input, condition: $condition) {
      id
      schoolID
      schoolYear
      icsea
      createdAt
      updatedAt
    }
  }
`;
export const updateSchoolIcsea = /* GraphQL */ `
  mutation UpdateSchoolIcsea(
    $input: UpdateSchoolIcseaInput!
    $condition: ModelSchoolIcseaConditionInput
  ) {
    updateSchoolIcsea(input: $input, condition: $condition) {
      id
      schoolID
      schoolYear
      icsea
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchoolIcsea = /* GraphQL */ `
  mutation DeleteSchoolIcsea(
    $input: DeleteSchoolIcseaInput!
    $condition: ModelSchoolIcseaConditionInput
  ) {
    deleteSchoolIcsea(input: $input, condition: $condition) {
      id
      schoolID
      schoolYear
      icsea
      createdAt
      updatedAt
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      name
      countryCode
      createdAt
      updatedAt
      states {
        items {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      name
      countryCode
      createdAt
      updatedAt
      states {
        items {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      name
      countryCode
      createdAt
      updatedAt
      states {
        items {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createState = /* GraphQL */ `
  mutation CreateState(
    $input: CreateStateInput!
    $condition: ModelStateConditionInput
  ) {
    createState(input: $input, condition: $condition) {
      id
      name
      stateCode
      countryID
      createdAt
      updatedAt
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
    }
  }
`;
export const updateState = /* GraphQL */ `
  mutation UpdateState(
    $input: UpdateStateInput!
    $condition: ModelStateConditionInput
  ) {
    updateState(input: $input, condition: $condition) {
      id
      name
      stateCode
      countryID
      createdAt
      updatedAt
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
    }
  }
`;
export const deleteState = /* GraphQL */ `
  mutation DeleteState(
    $input: DeleteStateInput!
    $condition: ModelStateConditionInput
  ) {
    deleteState(input: $input, condition: $condition) {
      id
      name
      stateCode
      countryID
      createdAt
      updatedAt
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
    }
  }
`;
export const createClassroom = /* GraphQL */ `
  mutation CreateClassroom(
    $input: CreateClassroomInput!
    $condition: ModelClassroomConditionInput
  ) {
    createClassroom(input: $input, condition: $condition) {
      id
      classType
      focusGroupType
      className
      schoolYear
      schoolID
      wondeID
      MISID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      yearLevels {
        items {
          id
          classroomID
          schoolID
          yearLevelID
          createdAt
          updatedAt
        }
        nextToken
      }
      teachers {
        items {
          id
          classroomID
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      learningAreas {
        items {
          id
          classroomID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      students {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateClassroom = /* GraphQL */ `
  mutation UpdateClassroom(
    $input: UpdateClassroomInput!
    $condition: ModelClassroomConditionInput
  ) {
    updateClassroom(input: $input, condition: $condition) {
      id
      classType
      focusGroupType
      className
      schoolYear
      schoolID
      wondeID
      MISID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      yearLevels {
        items {
          id
          classroomID
          schoolID
          yearLevelID
          createdAt
          updatedAt
        }
        nextToken
      }
      teachers {
        items {
          id
          classroomID
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      learningAreas {
        items {
          id
          classroomID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      students {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteClassroom = /* GraphQL */ `
  mutation DeleteClassroom(
    $input: DeleteClassroomInput!
    $condition: ModelClassroomConditionInput
  ) {
    deleteClassroom(input: $input, condition: $condition) {
      id
      classType
      focusGroupType
      className
      schoolYear
      schoolID
      wondeID
      MISID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      yearLevels {
        items {
          id
          classroomID
          schoolID
          yearLevelID
          createdAt
          updatedAt
        }
        nextToken
      }
      teachers {
        items {
          id
          classroomID
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      learningAreas {
        items {
          id
          classroomID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      students {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createClassroomYearLevel = /* GraphQL */ `
  mutation CreateClassroomYearLevel(
    $input: CreateClassroomYearLevelInput!
    $condition: ModelClassroomYearLevelConditionInput
  ) {
    createClassroomYearLevel(input: $input, condition: $condition) {
      id
      classroomID
      schoolID
      yearLevelID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const updateClassroomYearLevel = /* GraphQL */ `
  mutation UpdateClassroomYearLevel(
    $input: UpdateClassroomYearLevelInput!
    $condition: ModelClassroomYearLevelConditionInput
  ) {
    updateClassroomYearLevel(input: $input, condition: $condition) {
      id
      classroomID
      schoolID
      yearLevelID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const deleteClassroomYearLevel = /* GraphQL */ `
  mutation DeleteClassroomYearLevel(
    $input: DeleteClassroomYearLevelInput!
    $condition: ModelClassroomYearLevelConditionInput
  ) {
    deleteClassroomYearLevel(input: $input, condition: $condition) {
      id
      classroomID
      schoolID
      yearLevelID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const createClassroomTeacher = /* GraphQL */ `
  mutation CreateClassroomTeacher(
    $input: CreateClassroomTeacherInput!
    $condition: ModelClassroomTeacherConditionInput
  ) {
    createClassroomTeacher(input: $input, condition: $condition) {
      id
      classroomID
      email
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const updateClassroomTeacher = /* GraphQL */ `
  mutation UpdateClassroomTeacher(
    $input: UpdateClassroomTeacherInput!
    $condition: ModelClassroomTeacherConditionInput
  ) {
    updateClassroomTeacher(input: $input, condition: $condition) {
      id
      classroomID
      email
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const deleteClassroomTeacher = /* GraphQL */ `
  mutation DeleteClassroomTeacher(
    $input: DeleteClassroomTeacherInput!
    $condition: ModelClassroomTeacherConditionInput
  ) {
    deleteClassroomTeacher(input: $input, condition: $condition) {
      id
      classroomID
      email
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const createClassroomLearningArea = /* GraphQL */ `
  mutation CreateClassroomLearningArea(
    $input: CreateClassroomLearningAreaInput!
    $condition: ModelClassroomLearningAreaConditionInput
  ) {
    createClassroomLearningArea(input: $input, condition: $condition) {
      id
      classroomID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const updateClassroomLearningArea = /* GraphQL */ `
  mutation UpdateClassroomLearningArea(
    $input: UpdateClassroomLearningAreaInput!
    $condition: ModelClassroomLearningAreaConditionInput
  ) {
    updateClassroomLearningArea(input: $input, condition: $condition) {
      id
      classroomID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const deleteClassroomLearningArea = /* GraphQL */ `
  mutation DeleteClassroomLearningArea(
    $input: DeleteClassroomLearningAreaInput!
    $condition: ModelClassroomLearningAreaConditionInput
  ) {
    deleteClassroomLearningArea(input: $input, condition: $condition) {
      id
      classroomID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const createClassroomStudent = /* GraphQL */ `
  mutation CreateClassroomStudent(
    $input: CreateClassroomStudentInput!
    $condition: ModelClassroomStudentConditionInput
  ) {
    createClassroomStudent(input: $input, condition: $condition) {
      id
      classroomID
      studentID
      createdAt
      updatedAt
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const updateClassroomStudent = /* GraphQL */ `
  mutation UpdateClassroomStudent(
    $input: UpdateClassroomStudentInput!
    $condition: ModelClassroomStudentConditionInput
  ) {
    updateClassroomStudent(input: $input, condition: $condition) {
      id
      classroomID
      studentID
      createdAt
      updatedAt
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const deleteClassroomStudent = /* GraphQL */ `
  mutation DeleteClassroomStudent(
    $input: DeleteClassroomStudentInput!
    $condition: ModelClassroomStudentConditionInput
  ) {
    deleteClassroomStudent(input: $input, condition: $condition) {
      id
      classroomID
      studentID
      createdAt
      updatedAt
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const createSchoolStudent = /* GraphQL */ `
  mutation CreateSchoolStudent(
    $input: CreateSchoolStudentInput!
    $condition: ModelSchoolStudentConditionInput
  ) {
    createSchoolStudent(input: $input, condition: $condition) {
      id
      schoolID
      studentID
      schoolYear
      yearLevelID
      firstName
      lastName
      userId
      hasAccessToWAM
      assignedDateToWAM
      hasAccessToPremiumAssessment
      assignedDateToPremiumAssessment
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const updateSchoolStudent = /* GraphQL */ `
  mutation UpdateSchoolStudent(
    $input: UpdateSchoolStudentInput!
    $condition: ModelSchoolStudentConditionInput
  ) {
    updateSchoolStudent(input: $input, condition: $condition) {
      id
      schoolID
      studentID
      schoolYear
      yearLevelID
      firstName
      lastName
      userId
      hasAccessToWAM
      assignedDateToWAM
      hasAccessToPremiumAssessment
      assignedDateToPremiumAssessment
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const deleteSchoolStudent = /* GraphQL */ `
  mutation DeleteSchoolStudent(
    $input: DeleteSchoolStudentInput!
    $condition: ModelSchoolStudentConditionInput
  ) {
    deleteSchoolStudent(input: $input, condition: $condition) {
      id
      schoolID
      studentID
      schoolYear
      yearLevelID
      firstName
      lastName
      userId
      hasAccessToWAM
      assignedDateToWAM
      hasAccessToPremiumAssessment
      assignedDateToPremiumAssessment
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      wondeID
      MISID
      gender
      birthDate
      photo {
        bucket
        region
        key
      }
      yearLevelID
      createdAt
      updatedAt
      currentYear {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      classrooms {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolYears {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
      studentData {
        items {
          id
          studentID
          schoolYear
          attributeID
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      wondeID
      MISID
      gender
      birthDate
      photo {
        bucket
        region
        key
      }
      yearLevelID
      createdAt
      updatedAt
      currentYear {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      classrooms {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolYears {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
      studentData {
        items {
          id
          studentID
          schoolYear
          attributeID
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      wondeID
      MISID
      gender
      birthDate
      photo {
        bucket
        region
        key
      }
      yearLevelID
      createdAt
      updatedAt
      currentYear {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      classrooms {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolYears {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
      studentData {
        items {
          id
          studentID
          schoolYear
          attributeID
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createStudentData = /* GraphQL */ `
  mutation CreateStudentData(
    $input: CreateStudentDataInput!
    $condition: ModelStudentDataConditionInput
  ) {
    createStudentData(input: $input, condition: $condition) {
      id
      studentID
      schoolYear
      attributeID
      value
      createdAt
      updatedAt
      attribute {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const updateStudentData = /* GraphQL */ `
  mutation UpdateStudentData(
    $input: UpdateStudentDataInput!
    $condition: ModelStudentDataConditionInput
  ) {
    updateStudentData(input: $input, condition: $condition) {
      id
      studentID
      schoolYear
      attributeID
      value
      createdAt
      updatedAt
      attribute {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const deleteStudentData = /* GraphQL */ `
  mutation DeleteStudentData(
    $input: DeleteStudentDataInput!
    $condition: ModelStudentDataConditionInput
  ) {
    deleteStudentData(input: $input, condition: $condition) {
      id
      studentID
      schoolYear
      attributeID
      value
      createdAt
      updatedAt
      attribute {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      acCodeID
      yearLevelID
      learningAreaID
      strandID
      substrandID
      question
      questionType
      image {
        bucket
        region
        key
      }
      imageRow
      answerSelectionType
      answerTitle
      messageForCorrectAnswer
      messageForIncorrectAnswer
      explanation
      points
      difficulty
      hasHTMLContent
      isPremium
      bookletID
      booklet {
        id
        name
        bookletContent
        areQuestionsRandomised
        createdAt
        updatedAt
        bookletQuestions {
          nextToken
        }
      }
      orderInBooklet
      isClozeQuestion
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      subStrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      answers {
        items {
          id
          questionID
          text
          correct
          inputFieldWidth
          weight
          score
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      acCodeID
      yearLevelID
      learningAreaID
      strandID
      substrandID
      question
      questionType
      image {
        bucket
        region
        key
      }
      imageRow
      answerSelectionType
      answerTitle
      messageForCorrectAnswer
      messageForIncorrectAnswer
      explanation
      points
      difficulty
      hasHTMLContent
      isPremium
      bookletID
      booklet {
        id
        name
        bookletContent
        areQuestionsRandomised
        createdAt
        updatedAt
        bookletQuestions {
          nextToken
        }
      }
      orderInBooklet
      isClozeQuestion
      isArchived
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      subStrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      answers {
        items {
          id
          questionID
          text
          correct
          inputFieldWidth
          weight
          score
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      acCodeID
      yearLevelID
      learningAreaID
      strandID
      substrandID
      question
      questionType
      image {
        bucket
        region
        key
      }
      imageRow
      answerSelectionType
      answerTitle
      messageForCorrectAnswer
      messageForIncorrectAnswer
      explanation
      points
      difficulty
      hasHTMLContent
      isPremium
      bookletID
      booklet {
        id
        name
        bookletContent
        areQuestionsRandomised
        createdAt
        updatedAt
        bookletQuestions {
          nextToken
        }
      }
      orderInBooklet
      isClozeQuestion
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      subStrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      answers {
        items {
          id
          questionID
          text
          correct
          inputFieldWidth
          weight
          score
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      questionID
      text
      image {
        bucket
        region
        key
      }
      correct
      inputFieldWidth
      weight
      score
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      questionID
      text
      image {
        bucket
        region
        key
      }
      correct
      inputFieldWidth
      weight
      score
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      questionID
      text
      image {
        bucket
        region
        key
      }
      correct
      inputFieldWidth
      weight
      score
      createdAt
      updatedAt
    }
  }
`;
export const createSubTestType = /* GraphQL */ `
  mutation CreateSubTestType(
    $input: CreateSubTestTypeInput!
    $condition: ModelSubTestTypeConditionInput
  ) {
    createSubTestType(input: $input, condition: $condition) {
      id
      subTestTypeName
      description
      typeID
      createdAt
      updatedAt
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSubTestType = /* GraphQL */ `
  mutation UpdateSubTestType(
    $input: UpdateSubTestTypeInput!
    $condition: ModelSubTestTypeConditionInput
  ) {
    updateSubTestType(input: $input, condition: $condition) {
      id
      subTestTypeName
      description
      typeID
      createdAt
      updatedAt
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSubTestType = /* GraphQL */ `
  mutation DeleteSubTestType(
    $input: DeleteSubTestTypeInput!
    $condition: ModelSubTestTypeConditionInput
  ) {
    deleteSubTestType(input: $input, condition: $condition) {
      id
      subTestTypeName
      description
      typeID
      createdAt
      updatedAt
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createTestType = /* GraphQL */ `
  mutation CreateTestType(
    $input: CreateTestTypeInput!
    $condition: ModelTestTypeConditionInput
  ) {
    createTestType(input: $input, condition: $condition) {
      id
      typeName
      description
      testUnitID
      createdAt
      updatedAt
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTestType = /* GraphQL */ `
  mutation UpdateTestType(
    $input: UpdateTestTypeInput!
    $condition: ModelTestTypeConditionInput
  ) {
    updateTestType(input: $input, condition: $condition) {
      id
      typeName
      description
      testUnitID
      createdAt
      updatedAt
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTestType = /* GraphQL */ `
  mutation DeleteTestType(
    $input: DeleteTestTypeInput!
    $condition: ModelTestTypeConditionInput
  ) {
    deleteTestType(input: $input, condition: $condition) {
      id
      typeName
      description
      testUnitID
      createdAt
      updatedAt
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTestUnit = /* GraphQL */ `
  mutation CreateTestUnit(
    $input: CreateTestUnitInput!
    $condition: ModelTestUnitConditionInput
  ) {
    createTestUnit(input: $input, condition: $condition) {
      id
      unitName
      createdAt
      updatedAt
    }
  }
`;
export const updateTestUnit = /* GraphQL */ `
  mutation UpdateTestUnit(
    $input: UpdateTestUnitInput!
    $condition: ModelTestUnitConditionInput
  ) {
    updateTestUnit(input: $input, condition: $condition) {
      id
      unitName
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestUnit = /* GraphQL */ `
  mutation DeleteTestUnit(
    $input: DeleteTestUnitInput!
    $condition: ModelTestUnitConditionInput
  ) {
    deleteTestUnit(input: $input, condition: $condition) {
      id
      unitName
      createdAt
      updatedAt
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      dataType
      typeID
      subTestTypeID
      testName
      testYearLevelId
      year
      nationalMean
      creatorUserID
      sharedByUserID
      sharedWithUserIDs
      isRandomOrder
      isSortedByComplexity
      testUnitID
      schoolID
      thresholdScaledBottom
      thresholdScaledTop
      thresholdScoreBottom
      thresholdScoreTop
      thresholdStanineBottom
      thresholdStanineTop
      shortName
      folderID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      subTestType {
        id
        subTestTypeName
        description
        typeID
        createdAt
        updatedAt
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
      learningAreas {
        items {
          id
          testID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          createdAt
          updatedAt
        }
        nextToken
      }
      testUploads {
        items {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      dataType
      typeID
      subTestTypeID
      testName
      testYearLevelId
      year
      nationalMean
      creatorUserID
      sharedByUserID
      sharedWithUserIDs
      isRandomOrder
      isSortedByComplexity
      testUnitID
      schoolID
      thresholdScaledBottom
      thresholdScaledTop
      thresholdScoreBottom
      thresholdScoreTop
      thresholdStanineBottom
      thresholdStanineTop
      shortName
      folderID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      subTestType {
        id
        subTestTypeName
        description
        typeID
        createdAt
        updatedAt
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
      learningAreas {
        items {
          id
          testID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          createdAt
          updatedAt
        }
        nextToken
      }
      testUploads {
        items {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      dataType
      typeID
      subTestTypeID
      testName
      testYearLevelId
      year
      nationalMean
      creatorUserID
      sharedByUserID
      sharedWithUserIDs
      isRandomOrder
      isSortedByComplexity
      testUnitID
      schoolID
      thresholdScaledBottom
      thresholdScaledTop
      thresholdScoreBottom
      thresholdScoreTop
      thresholdStanineBottom
      thresholdStanineTop
      shortName
      folderID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      subTestType {
        id
        subTestTypeName
        description
        typeID
        createdAt
        updatedAt
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
      learningAreas {
        items {
          id
          testID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          createdAt
          updatedAt
        }
        nextToken
      }
      testUploads {
        items {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTestLearningArea = /* GraphQL */ `
  mutation CreateTestLearningArea(
    $input: CreateTestLearningAreaInput!
    $condition: ModelTestLearningAreaConditionInput
  ) {
    createTestLearningArea(input: $input, condition: $condition) {
      id
      testID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const updateTestLearningArea = /* GraphQL */ `
  mutation UpdateTestLearningArea(
    $input: UpdateTestLearningAreaInput!
    $condition: ModelTestLearningAreaConditionInput
  ) {
    updateTestLearningArea(input: $input, condition: $condition) {
      id
      testID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const deleteTestLearningArea = /* GraphQL */ `
  mutation DeleteTestLearningArea(
    $input: DeleteTestLearningAreaInput!
    $condition: ModelTestLearningAreaConditionInput
  ) {
    deleteTestLearningArea(input: $input, condition: $condition) {
      id
      testID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const createTestQuestion = /* GraphQL */ `
  mutation CreateTestQuestion(
    $input: CreateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    createTestQuestion(input: $input, condition: $condition) {
      id
      testID
      questionNo
      itemId
      maxScore
      nationalMean
      expectedMean
      correctAnswer
      acCodeID
      difficulty
      answerType
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const updateTestQuestion = /* GraphQL */ `
  mutation UpdateTestQuestion(
    $input: UpdateTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    updateTestQuestion(input: $input, condition: $condition) {
      id
      testID
      questionNo
      itemId
      maxScore
      nationalMean
      expectedMean
      correctAnswer
      acCodeID
      difficulty
      answerType
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const deleteTestQuestion = /* GraphQL */ `
  mutation DeleteTestQuestion(
    $input: DeleteTestQuestionInput!
    $condition: ModelTestQuestionConditionInput
  ) {
    deleteTestQuestion(input: $input, condition: $condition) {
      id
      testID
      questionNo
      itemId
      maxScore
      nationalMean
      expectedMean
      correctAnswer
      acCodeID
      difficulty
      answerType
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const createTestUpload = /* GraphQL */ `
  mutation CreateTestUpload(
    $input: CreateTestUploadInput!
    $condition: ModelTestUploadConditionInput
  ) {
    createTestUpload(input: $input, condition: $condition) {
      id
      testDate
      testID
      typeID
      yearLevelID
      schoolID
      schoolYear
      resultFile {
        bucket
        region
        key
      }
      creatorUserID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testResults {
        items {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          studentID
          schoolID
          classRoomID
          isComplete
          testID
          assessmentName
          testResultID
          isStarted
          currentYearLevelID
          schoolYear
          score
          outOf
          questionCount
          assignedDate
          completedDate
          dueDate
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTestUpload = /* GraphQL */ `
  mutation UpdateTestUpload(
    $input: UpdateTestUploadInput!
    $condition: ModelTestUploadConditionInput
  ) {
    updateTestUpload(input: $input, condition: $condition) {
      id
      testDate
      testID
      typeID
      yearLevelID
      schoolID
      schoolYear
      resultFile {
        bucket
        region
        key
      }
      creatorUserID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testResults {
        items {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          percentage
          nationalBand
          minStandard
          proficiency
          level
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          studentID
          schoolID
          classRoomID
          isComplete
          testID
          assessmentName
          testResultID
          isStarted
          currentYearLevelID
          schoolYear
          score
          outOf
          questionCount
          assignedDate
          completedDate
          dueDate
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTestUpload = /* GraphQL */ `
  mutation DeleteTestUpload(
    $input: DeleteTestUploadInput!
    $condition: ModelTestUploadConditionInput
  ) {
    deleteTestUpload(input: $input, condition: $condition) {
      id
      testDate
      testID
      typeID
      yearLevelID
      schoolID
      schoolYear
      resultFile {
        bucket
        region
        key
      }
      creatorUserID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testResults {
        items {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          percentage
          nationalBand
          minStandard
          proficiency
          level
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          studentID
          schoolID
          classRoomID
          isComplete
          testID
          assessmentName
          testResultID
          isStarted
          currentYearLevelID
          schoolYear
          score
          outOf
          questionCount
          assignedDate
          completedDate
          dueDate
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTestResult = /* GraphQL */ `
  mutation CreateTestResult(
    $input: CreateTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    createTestResult(input: $input, condition: $condition) {
      id
      testID
      testDate
      completedDate
      studentID
      typeID
      schoolYear
      yearLevelID
      schoolID
      score
      scale
      stanine
      percentile
      percentage
      nationalBand
      minStandard
      proficiency
      level
      testUploadID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      learningAreas {
        items {
          id
          testResultID
          studentID
          learningAreaID
          typeID
          schoolYear
          yearLevelID
          schoolID
          testDate
          createdAt
          updatedAt
        }
        nextToken
      }
      resultAnswers {
        items {
          id
          testResultID
          testQuestionID
          studentAnswer
          studentAnswerIds
          proficiency
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTestResult = /* GraphQL */ `
  mutation UpdateTestResult(
    $input: UpdateTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    updateTestResult(input: $input, condition: $condition) {
      id
      testID
      testDate
      completedDate
      studentID
      typeID
      schoolYear
      yearLevelID
      schoolID
      score
      scale
      stanine
      percentile
      percentage
      nationalBand
      minStandard
      proficiency
      level
      testUploadID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      learningAreas {
        items {
          id
          testResultID
          studentID
          learningAreaID
          typeID
          schoolYear
          yearLevelID
          schoolID
          testDate
          createdAt
          updatedAt
        }
        nextToken
      }
      resultAnswers {
        items {
          id
          testResultID
          testQuestionID
          studentAnswer
          studentAnswerIds
          proficiency
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTestResult = /* GraphQL */ `
  mutation DeleteTestResult(
    $input: DeleteTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    deleteTestResult(input: $input, condition: $condition) {
      id
      testID
      testDate
      completedDate
      studentID
      typeID
      schoolYear
      yearLevelID
      schoolID
      score
      scale
      stanine
      percentile
      percentage
      nationalBand
      minStandard
      proficiency
      level
      testUploadID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      learningAreas {
        items {
          id
          testResultID
          studentID
          learningAreaID
          typeID
          schoolYear
          yearLevelID
          schoolID
          testDate
          createdAt
          updatedAt
        }
        nextToken
      }
      resultAnswers {
        items {
          id
          testResultID
          testQuestionID
          studentAnswer
          studentAnswerIds
          proficiency
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTestResultLearningArea = /* GraphQL */ `
  mutation CreateTestResultLearningArea(
    $input: CreateTestResultLearningAreaInput!
    $condition: ModelTestResultLearningAreaConditionInput
  ) {
    createTestResultLearningArea(input: $input, condition: $condition) {
      id
      testResultID
      studentID
      learningAreaID
      typeID
      schoolYear
      yearLevelID
      schoolID
      testDate
      createdAt
      updatedAt
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        percentage
        nationalBand
        minStandard
        proficiency
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const updateTestResultLearningArea = /* GraphQL */ `
  mutation UpdateTestResultLearningArea(
    $input: UpdateTestResultLearningAreaInput!
    $condition: ModelTestResultLearningAreaConditionInput
  ) {
    updateTestResultLearningArea(input: $input, condition: $condition) {
      id
      testResultID
      studentID
      learningAreaID
      typeID
      schoolYear
      yearLevelID
      schoolID
      testDate
      createdAt
      updatedAt
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        percentage
        nationalBand
        proficiency
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const deleteTestResultLearningArea = /* GraphQL */ `
  mutation DeleteTestResultLearningArea(
    $input: DeleteTestResultLearningAreaInput!
    $condition: ModelTestResultLearningAreaConditionInput
  ) {
    deleteTestResultLearningArea(input: $input, condition: $condition) {
      id
      testResultID
      studentID
      learningAreaID
      typeID
      schoolYear
      yearLevelID
      schoolID
      testDate
      createdAt
      updatedAt
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        percentage
        nationalBand
        minStandard
        proficiency
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const createTestResultAnswersGA = /* GraphQL */ `
  mutation CreateTestResultAnswersGA(
    $input: CreateTestResultAnswersGAInput!
    $condition: ModelTestResultAnswersGAConditionInput
  ) {
    createTestResultAnswersGA(input: $input, condition: $condition) {
      testResultAnswerID
      testUploadID
      testResultID
      studentID
      acCode
      proficiency
      createdAt
      updatedAt
    }
  }
`;
export const updateTestResultAnswersGA = /* GraphQL */ `
  mutation UpdateTestResultAnswersGA(
    $input: UpdateTestResultAnswersGAInput!
    $condition: ModelTestResultAnswersGAConditionInput
  ) {
    updateTestResultAnswersGA(input: $input, condition: $condition) {
      testResultAnswerID
      testUploadID
      testResultID
      studentID
      acCode
      proficiency
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestResultAnswersGA = /* GraphQL */ `
  mutation DeleteTestResultAnswersGA(
    $input: DeleteTestResultAnswersGAInput!
    $condition: ModelTestResultAnswersGAConditionInput
  ) {
    deleteTestResultAnswersGA(input: $input, condition: $condition) {
      testResultAnswerID
      testUploadID
      testResultID
      studentID
      acCode
      proficiency
      createdAt
      updatedAt
    }
  }
`;
export const createTestResultAnswers = /* GraphQL */ `
  mutation CreateTestResultAnswers(
    $input: CreateTestResultAnswersInput!
    $condition: ModelTestResultAnswersConditionInput
  ) {
    createTestResultAnswers(input: $input, condition: $condition) {
      id
      testResultID
      testQuestionID
      studentAnswer
      studentAnswerIds
      proficiency
      createdAt
      updatedAt
      testQuestion {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const updateTestResultAnswers = /* GraphQL */ `
  mutation UpdateTestResultAnswers(
    $input: UpdateTestResultAnswersInput!
    $condition: ModelTestResultAnswersConditionInput
  ) {
    updateTestResultAnswers(input: $input, condition: $condition) {
      id
      testResultID
      testQuestionID
      studentAnswer
      studentAnswerIds
      proficiency
      createdAt
      updatedAt
      testQuestion {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const deleteTestResultAnswers = /* GraphQL */ `
  mutation DeleteTestResultAnswers(
    $input: DeleteTestResultAnswersInput!
    $condition: ModelTestResultAnswersConditionInput
  ) {
    deleteTestResultAnswers(input: $input, condition: $condition) {
      id
      testResultID
      testQuestionID
      studentAnswer
      studentAnswerIds
      proficiency
      createdAt
      updatedAt
      testQuestion {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const deleteTestResultAnswersMinimalReturn = /* GraphQL */ `
  mutation DeleteTestResultAnswers(
    $input: DeleteTestResultAnswersInput!
    $condition: ModelTestResultAnswersConditionInput
  ) {
    deleteTestResultAnswers(input: $input, condition: $condition) {
      id
      testResultID
      testQuestionID
      studentAnswer
      studentAnswerIds
      proficiency
      createdAt
      updatedAt
    }
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      id
      studentID
      schoolID
      classRoomID
      isComplete
      testID
      assessmentName
      testResultID
      isStarted
      currentYearLevelID
      schoolYear
      score
      outOf
      questionCount
      assignedDate
      completedDate
      dueDate
      testUploadID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      studentID
      schoolID
      classRoomID
      isComplete
      testID
      assessmentName
      testResultID
      isStarted
      currentYearLevelID
      schoolYear
      score
      outOf
      questionCount
      assignedDate
      completedDate
      dueDate
      testUploadID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment(
    $input: DeleteAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    deleteAssessment(input: $input, condition: $condition) {
      id
      studentID
      schoolID
      classRoomID
      isComplete
      testID
      assessmentName
      testResultID
      isStarted
      currentYearLevelID
      schoolYear
      score
      outOf
      questionCount
      assignedDate
      completedDate
      dueDate
      testUploadID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
export const createHelpGuide = /* GraphQL */ `
  mutation CreateHelpGuide(
    $input: CreateHelpGuideInput!
    $condition: ModelHelpGuideConditionInput
  ) {
    createHelpGuide(input: $input, condition: $condition) {
      id
      title
      description
      link
      status
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateHelpGuide = /* GraphQL */ `
  mutation UpdateHelpGuide(
    $input: UpdateHelpGuideInput!
    $condition: ModelHelpGuideConditionInput
  ) {
    updateHelpGuide(input: $input, condition: $condition) {
      id
      title
      description
      link
      status
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteHelpGuide = /* GraphQL */ `
  mutation DeleteHelpGuide(
    $input: DeleteHelpGuideInput!
    $condition: ModelHelpGuideConditionInput
  ) {
    deleteHelpGuide(input: $input, condition: $condition) {
      id
      title
      description
      link
      status
      order
      createdAt
      updatedAt
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      schoolID
      teacherID
      acCode
      isModified
      pagesJSON
      presentUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      originalLessonId
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      lessonPlans {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      schoolID
      teacherID
      acCode
      isModified
      pagesJSON
      presentUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      originalLessonId
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      lessonPlans {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      schoolID
      teacherID
      acCode
      isModified
      pagesJSON
      presentUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      originalLessonId
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      lessonPlans {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTeacherLessonPlan = /* GraphQL */ `
  mutation CreateTeacherLessonPlan(
    $input: CreateTeacherLessonPlanInput!
    $condition: ModelTeacherLessonPlanConditionInput
  ) {
    createTeacherLessonPlan(input: $input, condition: $condition) {
      id
      lessonPlanName
      lessonID
      studentID
      schoolID
      teacherID
      classRoomID
      acCodeID
      currentYearLevelID
      schoolYear
      editedPlanUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      lessons {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTeacherLessonPlan = /* GraphQL */ `
  mutation UpdateTeacherLessonPlan(
    $input: UpdateTeacherLessonPlanInput!
    $condition: ModelTeacherLessonPlanConditionInput
  ) {
    updateTeacherLessonPlan(input: $input, condition: $condition) {
      id
      lessonPlanName
      lessonID
      studentID
      schoolID
      teacherID
      classRoomID
      acCodeID
      currentYearLevelID
      schoolYear
      editedPlanUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      lessons {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTeacherLessonPlan = /* GraphQL */ `
  mutation DeleteTeacherLessonPlan(
    $input: DeleteTeacherLessonPlanInput!
    $condition: ModelTeacherLessonPlanConditionInput
  ) {
    deleteTeacherLessonPlan(input: $input, condition: $condition) {
      id
      lessonPlanName
      lessonID
      studentID
      schoolID
      teacherID
      classRoomID
      acCodeID
      currentYearLevelID
      schoolYear
      editedPlanUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      lessons {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createLessonTeacherLessonPlan = /* GraphQL */ `
  mutation CreateLessonTeacherLessonPlan(
    $input: CreateLessonTeacherLessonPlanInput!
    $condition: ModelLessonTeacherLessonPlanConditionInput
  ) {
    createLessonTeacherLessonPlan(input: $input, condition: $condition) {
      id
      lessonID
      lessonPlanID
      createdAt
      updatedAt
      lesson {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      lessonPlan {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
    }
  }
`;
export const updateLessonTeacherLessonPlan = /* GraphQL */ `
  mutation UpdateLessonTeacherLessonPlan(
    $input: UpdateLessonTeacherLessonPlanInput!
    $condition: ModelLessonTeacherLessonPlanConditionInput
  ) {
    updateLessonTeacherLessonPlan(input: $input, condition: $condition) {
      id
      lessonID
      lessonPlanID
      createdAt
      updatedAt
      lesson {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      lessonPlan {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
    }
  }
`;
export const deleteLessonTeacherLessonPlan = /* GraphQL */ `
  mutation DeleteLessonTeacherLessonPlan(
    $input: DeleteLessonTeacherLessonPlanInput!
    $condition: ModelLessonTeacherLessonPlanConditionInput
  ) {
    deleteLessonTeacherLessonPlan(input: $input, condition: $condition) {
      id
      lessonID
      lessonPlanID
      createdAt
      updatedAt
      lesson {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      lessonPlan {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
    }
  }
`;
export const createTestAssignment = /* GraphQL */ `
  mutation CreateTestAssignment(
    $input: CreateTestAssignmentInput!
    $condition: ModelTestAssignmentConditionInput
  ) {
    createTestAssignment(input: $input, condition: $condition) {
      id
      testDate
      schoolYear
      status
      userId
      testID
      testUploadID
      schoolID
      yearLevelID
      classroomID
      typeID
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
export const updateTestAssignment = /* GraphQL */ `
  mutation UpdateTestAssignment(
    $input: UpdateTestAssignmentInput!
    $condition: ModelTestAssignmentConditionInput
  ) {
    updateTestAssignment(input: $input, condition: $condition) {
      id
      testDate
      schoolYear
      status
      userId
      testID
      testUploadID
      schoolID
      yearLevelID
      classroomID
      typeID
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
export const deleteTestAssignment = /* GraphQL */ `
  mutation DeleteTestAssignment(
    $input: DeleteTestAssignmentInput!
    $condition: ModelTestAssignmentConditionInput
  ) {
    deleteTestAssignment(input: $input, condition: $condition) {
      id
      testDate
      schoolYear
      status
      userId
      testID
      testUploadID
      schoolID
      yearLevelID
      classroomID
      typeID
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
