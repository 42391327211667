import React, { useEffect } from "react";
import { CCol, CContainer, CRow, CImg } from "@coreui/react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import NewPasswordComponent from "../../../components/user/NewPasswordComponent";
import LoginComponentForSchool from "components/user/login/LoginComponentForSchool";

const LoginPageForSchoolStudent = () => {
  const error = useSelector((state) => state.user.error);
  const currentUser = useSelector((state) => state.user.currentUser);
  const completeNewPassword = useSelector(
    (state) => state.user.completeNewPassword
  );
  const history = useHistory();
  
  let { schoolID, username } = useParams();

  useEffect(() => {
    if (currentUser && !completeNewPassword) {
      history.replace("/");
    }
  }, [currentUser, history, completeNewPassword]);

  return (
    <div className="c-wrapper" id="login-page">
      <div className="c-body flex-row align-items-center">
        <CContainer className="login-container bg-gray">
          <CRow className="justify-content-center login-page-row">
            <CCol sm="12" md="12" lg="6" className="login-col">
              <CImg
                className="login-logo"
                width={400}
                height={150}
                alt="EdCompanion"
                src={"/Elastik_ID_Blue.png"}
              />

              {!currentUser && (
                <LoginComponentForSchool error={error} schoolID={schoolID} username={username}/>
              )}
              {currentUser && completeNewPassword && <NewPasswordComponent />}
            </CCol>

            <CCol sm="12" md="12" lg="6" className={"signin-background-uk"}>
              <CImg
                className="login-img"
                width={400}
                height={400}
                alt="Elastik"
                src={"/Elastik_Interface_Character_01-06.png"}
              />
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  );
};

export default LoginPageForSchoolStudent;
