import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import uiReducer from "./app/ui.reducer";
import lessonReducer from "./lessons/lessons.reducer";
import assessmentsReducer from "./assessments/assessments.reducer";
import wamReducer from "./wam/wam.reducer";

const rootPersistConfig = {
  key: "bproot",
  storage,
  blacklist: ["user", "app", "assessments"],
};

const userPersistConfig = {
  key: "user",
  storage,
  blacklist: ["currentUser", "users", "error", "completeNewPassword"],
};

const appPersistConfig = {
  key: "app",
  storage,
  blacklist: [
    "addToPlanHandler",
    "gridColumns",
    "gapDashboardFilter",
    "gapStudents",
    "loading",
    "loadingMessage",
    "printButtonHandler",
    "proficiencyFilter",
    "showFilterButton",
    "showFilterPanel",
    "showColumnChooser",
    "showAddToLessonPlan",
    "showPrintButton",
    "showStartButton",
    "showSaveButton",
    "startButtonHandler",
    "saveButtonHandler",
  ],
};

const lessonsPersistConfig = {
  key: "lessons",
  storage,
  blacklist: [""],
};
const wamPersistConfig = {
  key: "wam",
  storage,
  blacklist: [""],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  app: persistReducer(appPersistConfig, uiReducer),
  lessons: persistReducer(lessonsPersistConfig, lessonReducer),
  assessments: assessmentsReducer,
  wam: persistReducer(wamPersistConfig, wamReducer),
});

export default persistReducer(rootPersistConfig, rootReducer);
