import { Analytics } from "aws-amplify";
import awsExports from "./aws-exports";

/*
    Amplify Analytics (Pinpoint) doesn't currently play nicely with our Cognito config.
    Create Pinpoint apps as custom resources in the amplify env and add their configs to this file.
*/

// we use the user pool ID key to determine which Pinpoint application to send events to,
// because we can't rely on NODE_ENV and AWS region to tell us which amplify environment we're using
// (e.g. if we're connecting to the ukprod amplify env from a developer's machine).

const pinpointConfig = {
  // format: "user_pool_id": { aws_mobile_analytics_app_id: "...", aws_mobile_analytics_app_region: "..."}
  "ap-southeast-2_bjwWhJw4s": {
    // develop
    aws_mobile_analytics_app_id: "55be1510a02c4fa899574f9c64a3826e",
    aws_mobile_analytics_app_region: "ap-southeast-2", // redundant but makes config code cleaner to have it here
  },
  "ap-southeast-2_1whXi1H8I": {
    // prod
    aws_mobile_analytics_app_id: "9f7cd438ab234ade8d910729a8055f7d",
    aws_mobile_analytics_app_region: "ap-southeast-2",
  },
  "eu-west-2_HZ9FLYda4": {
    // ukdevelop
    aws_mobile_analytics_app_id: "b5b2cb90fafa4fec9e120ee06b25f25b",
    aws_mobile_analytics_app_region: "eu-west-2",
  },
  "eu-west-2_h04CFRMg4": {
    // ukprod
    aws_mobile_analytics_app_id: "8fc050c6b1c842fc99e1121f5d634dc5",
    aws_mobile_analytics_app_region: "eu-west-2",
  },
};

export const getPinpointConfigForThisEnv = () => {
  if (process.env.NODE_ENV === "development") {
    console.warn(
      "Pinpoint Config: NODE_ENV === 'development'; pinpoint events not being tracked."
    );
    return {}; // empty pinpoint config; we're not going to record events.
  }
  // else
  const userPoolID = awsExports?.aws_user_pools_id;
  const pinpointConfigForThisUserPool = pinpointConfig?.[userPoolID];
  if (!pinpointConfigForThisUserPool) {
    console.warn(
      `Pinpoint Config not found in pinpointConfig.js for user pool ID: ${userPoolID}; events won't be tracked.`
    );
    return {};
  }
  return pinpointConfigForThisUserPool;
};

/**
 * start tracking analytics events and sending them to pinpoint, unless the provided
 * detectedConfig is inconsistent with the AWS user pool settings (in which case we're
 * probably on a dev machine connected to a prod environment, for example).
 * @param {Object} detectedConfig
 * @param {string} detectedConfig.aws_mobile_analytics_app_id
 * @param {string} detectedConfig.aws_mobile_analytics_app_region
 */
export const initialisePinpoint = (detectedConfig) => {
  // detectedConfig can be an empty object if we're in a local development env
  if (detectedConfig && Object.keys(detectedConfig).length) {
    // try to find the expected user pool id for this detected pinpoint config, and check
    // that it matches the user pool id in aws-exports.js
    Analytics.autoTrack("session", {
      // REQUIRED, turn on/off the auto tracking
      enable: true,
      // OPTIONAL, the attributes of the event, you can either pass an object or a function
      // which allows you to define dynamic attributes
      // attributes: {
      //   attr: "attr",
      // },
      // when using function
      // attributes: () => {
      //    const attr = somewhere();
      //    return {
      //        myAttr: attr
      //    }
      // },
      // OPTIONAL, the service provider, by default is the Amazon Pinpoint
      provider: "AWSPinpoint",
    });

    Analytics.autoTrack("pageView", {
      // REQUIRED, turn on/off the auto tracking
      enable: true,
      // OPTIONAL, the event name, by default is 'pageView'
      eventName: "pageView",
      // OPTIONAL, the attributes of the event, you can either pass an object or a function
      // which allows you to define dynamic attributes
      // attributes: {
      //   attr: "attr",
      // },
      // when using function
      // attributes: () => {
      //    const attr = somewhere();
      //    return {
      //        myAttr: attr
      //    }
      // },
      // OPTIONAL, by default is 'multiPageApp'
      // you need to change it to 'SPA' if your app is a single-page app like React
      type: "SPA",
      // OPTIONAL, the service provider, by default is the Amazon Pinpoint
      provider: "AWSPinpoint",
      // OPTIONAL, to get the current page url
      getUrl: () => {
        // the default function
        return window.location.origin + window.location.pathname;
      },
    });
  }
};
