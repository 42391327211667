import dayjs from "dayjs";
import _ from "lodash";
import REGIONS from "./Regions";
import awsExports from "../aws-exports";
import { PhoneNumberFormats } from "./constants";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { getSystemParameter, getYearLevelsByCode } from "../graphql/queries";
import { fetchSystemParameter } from "views/wam/api";
import React from "react";
import { custom } from "devextreme/ui/dialog";

import ROLES from "./UserTypes";

export const downloadFileFromS3 = async (key, fileName) => {
  try {
    const result = await Storage.get(key, { download: true });

    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(result.Body);
    // Create a link element
    const link = document.createElement("a");
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = fileName;
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  } catch (error) {
    console.log(`Error when downloading the file from s3 ${error}`);
  }
};

/**
 * import the PhoneNumberFormat typedef
 * @typedef {import('./constants').PhoneNumberFormat} PhoneNumberFormat
 */

// just returns the graphql query string to determine if any students exist for the school in the given year
const isStudentsInSchoolYearQuery = (schoolId, year) => {
  return `
    query GetOneStudentForCurrentYear {
      getSchoolStudentsByYear(schoolID: "${schoolId}", schoolYear: {eq: ${year}}, limit: 1) {
        items {
          firstName
          lastName
        }
      }
    }
  `;
};

// returns true if a student exists for the school in the given year
const isStudentsInGivenSchoolYear = async (schoolID, year) => {
  const result = await API.graphql(
    graphqlOperation(isStudentsInSchoolYearQuery(schoolID, year))
  );
  return Boolean(result?.data?.getSchoolStudentsByYear?.items?.length);
};

/**
 * Checks if school current year has been rolled over
 * @param  {String} schoolID
 * @return {Boolean}
 */
export const isSchoolCurrentYearRolledOver = async (schoolID) => {
  return await isStudentsInGivenSchoolYear(schoolID, getSchoolYear());
};

/**
 * Function to be called when fetching data in useEffects
 * Sometimes the component is destroyed when promises are resolved
 * and in some promises we are setting the destroyed component state which causes React warning/error
 * that you cannot set state on a destroyed component
 *
 * This wrapper is used to handle that edge case
 * For more information:
 * https://dev.to/praveenkumarrr/cancellable-promises-in-react-and-why-is-it-required-5ghf
 *
 * @param {*} promise
 * @returns
 */
export const cancellablePromise = (promise) => {
  const isCancelled = { value: false };

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((response) => {
        return isCancelled.value ? reject(isCancelled) : resolve(response);
      })
      .catch((error) => {
        reject(isCancelled.value ? isCancelled : error);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true;
    },
  };
};

/** this method takes a word and format it to propper. */
export const formatToPropper = (str) => {
  return str && str !== "" ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

/**
 * It returns the offset of a HTML element
 * @param {*} element
 * @returns {left, top}
 */
export function getElementOffset(el) {
  if (!el) return null;
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}

/**
 * Function that swaps indexes of two element in an array
 * @param {*} array
 * @param {*} from
 * @param {*} to
 * @returns {number}
 */
export const swapIndexes = (array, from, to) => {
  const b = array[from];
  array[from] = array[to];
  array[to] = b;

  return array;
};

/**
 * Maps system parameters from the database
 * @param {*} systemParameters
 * @returns object of mapped system parameters
 */
export function mapSystemParameters(systemParameters) {
  const parameters = {};

  for (const parameter of systemParameters) {
    parameters[parameter.key] = Number(parameter.paramData)
      ? Number(parameter.paramData)
      : parameter.paramData;
  }

  return parameters;
}

/**
 * Reformats str mm/dd/yyyy and returns dd/mm/yyyy
 * @param  {String} str str expects to be 21/12/2022.
 * @return {String} formatted dd/mm/yyyy.
 *
 */
export function formattingSlashDateDMY(str) {
  let d = str.slice(3, 5);
  let m = str.slice(0, 2);
  let y = str.slice(6, 10);

  return d + "-" + m + "-" + y;
}

/**
 * Returns school end year date matching from paramter table end date
 * @param  {String} currentStateCode  expects stateCode as WA.
 * @param  {} userParams  expects user parameter fields including WASchoolCurrentYearEndDate, VICSchoolCurrentYearEndDate.
 * @return {String} formatted dd/mm/yyyy.
 *
 */
export function getSchoolCurrentYearEndDate(currentStateCode, userParams) {
  let endDate = dayjs().format("20-12-YYYY").toString();

  if (
    userParams.UKSchoolCurrentYearEndDate &&
    userParams.UKSchoolCurrentYearEndDate !== "undefined" &&
    typeof userParams.UKSchoolCurrentYearEndDate !== "undefined"
  ) {
    endDate = userParams.UKSchoolCurrentYearEndDate;
  } else if (currentStateCode.toUpperCase() === "WA") {
    endDate = userParams.WASchoolCurrentYearEndDate;
  } else if (currentStateCode.toUpperCase() === "VIC") {
    endDate = userParams.VICSchoolCurrentYearEndDate;
  }

  // Validating end date
  if (!endDate || typeof endDate === "undefined") {
    endDate = dayjs().format("20-12-YYYY").toString();
  }

  return formattingSlashDateDMY(endDate);
}

export function getSchoolYear() {
  const { aws_project_region: region } = awsExports;
  if (region !== REGIONS.Australia && new Date().getMonth() >= 7) {
    return new Date().getFullYear() + 1;
  } else {
    return new Date().getFullYear();
  }
}

/**
 * like getSchoolYear(), but it queries the database to see if there are any students in the school year for the school.
 * If there are none, return the year prior to the getSchoolYear() result.
 */
export async function getSchoolYearWithStudentCheck(schoolID) {
  let returnYear = getSchoolYear();

  // run a graphql query to see if there are students in the current year
  const schoolHasStudentsInReturnYear = await isStudentsInGivenSchoolYear(
    schoolID,
    returnYear
  );
  // if there were no students in the current year, return the previous year.
  if (!schoolHasStudentsInReturnYear) {
    returnYear = returnYear - 1;
  }
  return returnYear;
}

// return the start date of the given school year, as a dd/mm/yyyy string.
export function getStartDateStringOfSchoolYear(schoolYear) {
  const { aws_project_region: region } = awsExports;
  if (region !== REGIONS.Australia) {
    return `01/08/${schoolYear}`;
  } else {
    return `01/01/${schoolYear}`;
  }
}

export function getCurrentRegion() {
  const { aws_project_region: region } = awsExports;
  if (region === REGIONS.Australia) {
    return REGIONS.Australia;
  } else if (region === REGIONS.London) {
    return REGIONS.London;
  }
  return "NO_REGION_DETECTED";
}

/**
 * Add css style attributes to shadow-root element
 * @param  {String} selector  expects css selector element e.g '#image'
 * @param  {String} attributesContent  expects css attributes e.g img { max-width: 100%; }
 */
export async function addShadowRootStyleAttributes(
  selector,
  attributesContent
) {
  const elementLoaded = await waitForElementToGetLoad(selector);

  if (elementLoaded) {
    let element = document.querySelector(selector);
    element.shadowRoot.innerHTML = `
      <style> ${attributesContent} </style>
      `;
  }
}

/**
 * Waits for element to be loaded in the DOM
 * @param  {String} selector  expects css selector element e.g '#image'
 */
export async function waitForElementToGetLoad(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

/**
 * Returns removing last character .
 * @param  {String} str expects string
 * @return {String}
 *
 */
export function refineActivityTitle(str) {
  if (str) {
    return str.substring(str.length - 1) === "." ? str.replace(/.$/, "") : str;
  }
  return null;
}

/**
 * Returns current environment country calling code .
 * @return {String}
 */
export function getCountryCallingCode() {
  const { aws_project_region: region } = awsExports;
  return PhoneNumberFormats[region].countryCallingCode;
}

/**
 * Returns current environment phone format details.
 * @return {PhoneNumberFormat} phoneNumberFormat
 */
export function getCountryPhoneFormatDetails() {
  const { aws_project_region: region } = awsExports;
  return PhoneNumberFormats[region];
}

// This method return a value of a parameter received.
export const getSystemParameterValue = async (systemParameterId) => {
  const input = {
    key: systemParameterId,
  };
  const response = await API.graphql(
    graphqlOperation(getSystemParameter, input)
  );

  if (response?.data && response.data.getSystemParameter) {
    return response.data.getSystemParameter.paramData;
  } else {
    return "";
  }
};

/**
 * Returns cloze answer input box size class
 * @param  {number} value expects number
 * @return {string}
 */
export const ClozeAnswerBoxSize = (value) => {
  let className = "cloze-input-1";
  if (value === 1) {
    className = "cloze-input-1";
  } else if (value === 2) {
    className = "cloze-input-2";
  } else if (value >= 3) {
    className = "cloze-input-3";
  }

  return className;
};

/**
 * This method defines whether the current user is an administrator or not (role === Admin or systemAdmin)
 */
export const isAdministrator = (userType) => {
  if (!userType || userType === "") return false;
  return [ROLES.Admin, ROLES.SystemAdmin].includes(userType);
};

/**
 * Checks param ignoreWriting or ignorePremium based on domain & returns true/false
 * @return {Boolean}
 */
export const isDomainIgnoreLicenceRequirement = async (paramData) => {
  let url = window.location.origin + window.location.pathname;
  const ignoreLicenceRequirement = await fetchSystemParameter(paramData);
  return url.lastIndexOf(ignoreLicenceRequirement) !== -1;
};

export function isValidClozeQuestionAnswer(actualAnswer, studentAnswer) {
  const possibleAnswers = actualAnswer?.text?.split(";").map((s) => _.trim(s));

  if (actualAnswer.length < 1) return false;

  let correct = false;

  if (!possibleAnswers || !Array.isArray(possibleAnswers)) return false;

  // Check if the actual answers is 2 answers separated with ;
  for (let i = 0; i < possibleAnswers.length; i++) {
    if (
      possibleAnswers[i]?.toString()?.toLowerCase() ===
      studentAnswer.value?.toLowerCase()
    ) {
      correct = true;
    }
  }

  return correct;
}

/**
 * Checks if string is valid JSON
 * @param  {String} str  expects string
 * @return {Boolean}
 */
export function isJSON(str) {
  try {
    return JSON.parse(str) && Boolean(str);
  } catch (e) {
    return false;
  }
}

/**
 *
 * Generate random unique string
 *  @param  {length} int  expects integer
 * @return {String}
 */

export function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

/**
 * Removes html tags from string
 * @param  {String} str expects string
 * @return {String}
 */
export function removeTags(str) {
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

/**
 * Capitalize string
 * @param  {String} word expects string
 * @return {String}
 */
export function capitalize(word) {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return word;
}

/**
 * Returns school year list from 2015 until current year
 * @return {Array}
 */
export function getSchoolYearList() {
  const years = _.range(2015, new Date().getFullYear() + 2);
  years.sort((a, b) => {
    return b - a;
  });

  return years;
}

/**
 * Returns selected year from dropdown based on current env and region
 * @param  {String} yearSelected expects string
 * @param  {String} region expects string
 * @return {String}
 */
export function getDisplayExprSchoolYear(yearSelected, region) {
  if (region !== REGIONS.Australia) {
    return `${yearSelected - 1}/${
      yearSelected ? yearSelected.toString().slice(-2) : ""
    }`;
  } else {
    return `${yearSelected}`;
  }
}

/**
 * Returns lists of YearLevel data
 * @return {YearLevel}
 */
export async function getYearLevelItems() {
  try {
    const input = { type: "YL", sortDirection: "ASC" };
    let result = await API.graphql(
      graphqlOperation(getYearLevelsByCode, input)
    );

    return result?.data?.getYearLevelsByCode?.items;
  } catch (error) {
    console.log("Unable to fetch the year levels data", error);
  }
}

/**
 * Returns a function that can be used as a custom render for the dropdown items, adding a data-cy attribute
 * @param {string} dataCyAttrBase - the base of the data-cy attribute string
 * @param {string} dropdownItemPropNameToAppend - the name of the property on the dropdown item to append to the data-cy attribute
 * @param {string} dataItemTextPropName - the name of the property on the dropdown item to use as the text for the dropdown item
 * @returns {function} - a function that can be used as a custom render for the dropdown items
 */
export const getCustomDropdownItemRendererFn = (
  dataCyAttrBase,
  dropdownItemPropNameToAppend,
  dataItemTextPropName = "text"
) => {
  return (item) => {
    const appendVal = item[dropdownItemPropNameToAppend];
    const text = item[dataItemTextPropName];
    const appendValKebabCase = _.kebabCase(appendVal);

    return (
      <span data-cy={`${dataCyAttrBase}-${appendValKebabCase}`}>{text}</span>
    );
  };
};

/**
 * Returns a function that can be used as a custom onCellPrepared for a devextreme data grid, adding a data-cy attribute
 * to each cell in the grid
 * @param {string} dataCyAttrBase - the base of the data-cy attribute string
 * @returns {function} - a function that can be used as a custom onCellPrepared for the data grid items
 */
export const getCustomOnCellPreparedFn = (dataCyAttrBase) => {
  return (e) => {
    if (e.rowType === "header") {
      // Add data-cy to a select all radio button on the datagrid components.
      if (e.column?.type === "selection") {
        e.cellElement.setAttribute(
          "data-cy",
          `${dataCyAttrBase}-header-selection`
        );
      }
    }

    if (e.rowType === "data") {
      // add data-cy to elements that are actions inside the row, for instance a radio button.
      if (e.column?.type === "selection") {
        e.cellElement.setAttribute(
          "data-cy",
          `${dataCyAttrBase}-selection-${e.rowIndex}`
        );
      } else {
        e.cellElement.setAttribute(
          "data-cy",
          `${dataCyAttrBase}-value-${_.kebabCase(e.column.caption)}-${
            e.rowIndex
          }`
        );
      }
    }
  };
};

/**
 * Returns a function that can be used as a custom onEditorPreparing for a devextreme data grid, adding a data-cy attribute
 * to the filter inputs in the grid
 * @param {string} dataCyAttrBase - the base of the data-cy attribute string
 * @returns {function} - a function that can be used as a custom onEditorPreparing for the data grid items
 */
export const getCustomOnEditorPreparingFn = (dataCyAttrBase) => {
  return (e) => {
    // add a 'data-cy' attribute to the filter rows
    e.editorOptions.inputAttr["data-cy"] = `${dataCyAttrBase}-${_.kebabCase(
      e.caption
    )}`;
  };
};

/**
 * A devExtreme confirm dialog with data-cy attributes on the buttons. Use this instead of the devExtreme confirm dialog.
 */
export const confirm = async (messageHtml, title, dataCyAttrBase) => {
  return custom({
    title: title || "Confirm",
    messageHtml: messageHtml,
    buttons: [
      {
        text: "Yes",
        onClick: (e) => {
          return true;
        },
        elementAttr: {
          "data-cy": `${dataCyAttrBase}-confirm-yes`,
        },
      },
      {
        text: "No",
        onClick: (e) => {
          return false;
        },
        elementAttr: {
          "data-cy": `${dataCyAttrBase}-confirm-no`,
        },
      },
    ],
  }).show();
};

/**
 * @type {boolean} - true if the current hostname is a variant of localhost; used (for example) to prevent logging to
 * LogRocket
 */
export const isLocalhost =
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(
    window.location.hostname
  );

/**
 * @type {boolean} - true if the current hostname refers to a dev environment; used (for example) to prevent logging to
 * LogRocket
 */
export const isDevHostname = /^dev\./.test(window.location.hostname);

// hacky function to submit an HTML form via an iframe (we can't use AJAX because of salesforce CORS)
export function salesforceWebToLeadFormSubmit(fields) {
  const SUBMISSION_URL = process.env.REACT_APP_MESSAGE_US_WIDGET_SUBMISSION_URL;

  const customHiddenIframeName = "SF_IFRAME";
  if (!document.getElementById(customHiddenIframeName)) {
    const theiFrame = document.createElement("iframe");
    theiFrame.id = customHiddenIframeName;
    theiFrame.name = customHiddenIframeName;
    theiFrame.src = "about:blank";
    theiFrame.style.display = "none";
    document.body.appendChild(theiFrame);
  }
  const form = document.createElement("form");
  form.method = "POST";
  form.action = SUBMISSION_URL;
  form.setAttribute("target", customHiddenIframeName);
  for (const fieldName in fields) {
    const theInput = document.createElement("input");
    theInput.name = fieldName;
    theInput.value = fields[fieldName];
    theInput.setAttribute("type", "hidden");
    form.appendChild(theInput);
  }
  document.body.appendChild(form);
  form.submit();
}
