import UserActions from "./../user/user.actiontypes";
import UIActions from "./ui.actiontypes";
import notify from "devextreme/ui/notify";
import _ from "lodash";

const initialState = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  loading: false,
  loadingMessage: "",
  showFilterButton: false,
  showFilterPanel: false,
  showColumnChooser: false,
  showPrintButton: false,
  printButtonHandler: null,
  showAddToLessonPlan: false,
  addToPlanHandler: null,
  showEditButton: false,
  editHandler: null,
  showStartButton: false,
  showSaveButton: false,
  startButtonHandler: null,
  saveButtonHandler: null,
  gapAnalysisFilter: {},
  gapFilterType: null,
  gapFilterValue: null,
  gapIsNewTestCreated: false,
  ISTPerformanceType: null,
  gapLearningAreas: [],
  gapTestTypes: [],
  gapTests: [],
  marksbookTests: [],
  marksbookTestKeys: [],
  gapSchools: [],
  gapData: [],
  testUnits: [],
  gapAnalysisTitle: "Gap Analysis",
  gapChartType: "Bubble",
  proficiencyFilter: {
    filterType: "Classroom",
    classroom: "Class 5A",
    learningArea: ["Mathematics"],
  },
  selectedSchool: null,
  selectedStudent: null,
  selectedClassroom: null,
  bpAssessmentTestTypeId: null,
  schoolViewTab: 0,
  testUploads: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "set":
      return { ...state, ...payload };

    case UIActions.SET_LOADING_TRUE:
    case UserActions.EMAIL_SIGN_IN_START:
      return { ...state, loading: true, loadingMessage: payload.message };

    case UserActions.CHECK_USER_SESSION:
      return { ...state, loading: true, loadingMessage: payload.message };

    case UIActions.SET_LOADING_FALSE:
    case UserActions.SIGN_IN_FAILURE:
    case UserActions.SIGN_OUT_FAILURE:
    case UserActions.SIGN_UP_FAILURE:
    case UserActions.SET_COMPLETE_PASSWORD:
    case UserActions.SET_COGNITO_CHALLENGE:
    case UserActions.UPDATE_USER_FAILURE:
      return { ...state, loading: false, loadingMessage: "" };

    case UserActions.COGNITO_CHALLENGE_SUCCESS:
    case UserActions.SIGN_IN_SUCCESS:
      return { ...state, loading: false, loadingMessage: "" };

    case UIActions.PROCESSING_FAILURE:
      if (payload) {
        notify({
          message: payload,
          type: "error",
          displayTime: 25000,
          position: "top",
        });
      }
      return { ...state, loading: false, loadingMessage: "" };

    case UIActions.PROCESSING_SUCCESS:
      if (payload) {
        notify({
          message: payload,
          type: "success",
          displayTime: 1000,
          position: "top",
          elementAttr: {
            "data-cy": "global-notify-reducer-selector",
          },
        });
      }
      return { ...state, loading: false, loadingMessage: "" };

    case UIActions.SET_SHOW_FILTER_BUTTON:
      return { ...state, showFilterButton: payload };

    case UIActions.SET_SHOW_FILTER_PANEL:
      return { ...state, showFilterPanel: payload };

    case UIActions.SET_SHOW_COLUMN_CHOOSER:
      return { ...state, showColumnChooser: payload };

    case UIActions.SET_GRID_COLUMNS:
      return state;

    case UIActions.SET_SHOW_PRINT_BUTTON:
      return {
        ...state,
        showPrintButton: payload.val,
        printButtonHandler: payload.handler,
      };

    case UIActions.SET_SHOW_START_BUTTON:
      return {
        ...state,
        showStartButton: payload.val,
        startButtonHandler: payload.handler,
      };
    case UIActions.SET_SHOW_SAVE_BUTTON:
      return {
        ...state,
        showSaveButton: payload.val,
        saveButtonHandler: payload.handler,
      };
    case UIActions.SET_EDIT_LESSON:
      return {
        ...state,
        showEditButton: payload.val,
        editHandler: payload.handler,
      };
    case UIActions.SET_ADD_TO_LESSONPLAN:
      return {
        ...state,
        showAddToLessonPlan: payload.val,
        addToPlanHandler: payload.handler,
      };

    case UIActions.RESET_HEADER_BUTTONS:
      return {
        ...state,
        showFilterButton: false,
        printButtonHandler: null,
        showPrintButton: false,
        showAddToLessonPlan: false,
        showEditButton: false,
        editHandler: null,
        addToPlanHandler: null,
        showStartButton: false,
        showSaveButton: false,
        startButtonHandler: null,
        saveButtonHandler: null,
      };

    case UIActions.SET_GAPANALYSIS_FILTER:
      const newFilter = _.isEmpty(payload)
        ? payload
        : { ...state.gapAnalysisFilter, ...payload };
      return {
        ...state,
        gapAnalysisFilter: newFilter,
      };

    case UIActions.SET_USER_GAP_VALUES:
      return {
        ...state,
        gapFilterType: payload.filterType,
        gapFilterValue: payload.filterValue,
        gapLearningAreas: payload.learningAreas,
        gapTestTypes: payload.testTypes,
        gapTestKeys: payload.testKeys,
        gapTests: payload.tests,
        gapAnalysisTitle: payload.gapTitle,
        gapSchools: payload.networkSchools ? payload.networkSchools : [],
        gapData: [],
      };

    case UIActions.SET_PROFICIENCY_FILTER:
      return { ...state, proficiencyFilter: payload };

    case UIActions.SET_GAP_CHART_TYPE:
      return { ...state, gapChartType: payload };

    case UIActions.SET_SELECTED_SCHOOL:
      return {
        ...state,
        selectedSchool: payload,
        gapFilterType: null,
        gapFilterValue: null,
        gapTestKeys: [],
        marksbookTestKeys: [],
        gapTests: [],
        gapSchools: [],
        gapData: [],
        gapAnalysisTitle: "Gap Analysis",
      };

    case UIActions.SET_GAP_LEARNINGAREAS:
      return { ...state, gapLearningAreas: payload };

    case UIActions.SET_GAP_TESTTYPES:
      return { ...state, gapTestTypes: payload };

    case UIActions.SET_GAP_SELECTEDCLASSROOM:
      return { ...state, selectedClassroom: payload };

    case UIActions.SET_GAP_ISNEWTESTCREATED:
      return { ...state, gapIsNewTestCreated: payload };

    case UIActions.SET_SUB_TESTTYPES:
      return { ...state, subTestTypes: payload };

    case UIActions.SET_GAP_TESTS:
      return { ...state, gapTests: payload };

    case UIActions.SET_MARKSBOOK_TESTS:
      return { ...state, marksbookTests: payload };

    case UIActions.SET_GAP_DATA:
      return { ...state, gapData: payload };

    case UIActions.CLEAR_GAP_SETTINGS:
      return {
        ...state,
        gapTests: [],
        marksbookTests: [],
        gapTestKeys: [],
        marksbookTestKeys: [],
        gapFilterValue: null,
        gapFilterType: null,
        gapData: [],
        gapAnalysisTitle: "Gap Analysis",
      };

    case UIActions.SET_GAP_ANALYSIS_TITLE:
      return { ...state, gapAnalysisTitle: payload };

    case UIActions.SET_GAP_TESTKEYS:
      return { ...state, gapTestKeys: payload };

    case UIActions.SET_MARKSBOOK_TEST_KEYS:
      return { ...state, marksbookTestKeys: payload };

    case UIActions.SET_GAP_FILTER_TYPE:
      return {
        ...state,
        gapFilterType: payload,
        gapFilterValue: null,
        gapTestKeys: [],
        marksbookTestKeys: [],
        gapTests: [],
        marksbookTests: [],
        gapData: [],
        gapSchools: [],
      };

    case UIActions.SET_GAP_FILTER_VALUE:
      return {
        ...state,
        gapFilterValue: payload,
        gapTestKeys: [],
        marksbookTestKeys: [],
        gapTests: [],
        marksbookTests: [],
        gapData: [],
      };

    case UIActions.SET_YEAR_LEVEL_OF_CLASSROOM_FILTER_VALUE:
      return {
        ...state,
        yearLevelOfClassroomComponent: payload,
      };

    case UIActions.SET_WAS_A_SPLIT_CLASSROOM_SELECTED:
      return {
        ...state,
        wasASplitClassroomSelected: payload,
      };

    case UIActions.SET_GAP_SCHOOLS:
      return { ...state, gapSchools: payload };

    case UIActions.SET_STUDENT_GAP_VALUES:
      return {
        ...state,
        gapFilterType: "Student",
        gapFilterValue: { studentID: payload.studentID },
        gapTests: payload.tests,
        gapTestKeys: payload.tests.map((t) => t.id),
        gapAnalysisTitle: payload.title,
      };

    case UIActions.SET_CLASS_GAP_VALUES:
      return {
        ...state,
        gapFilterType: payload.classType,
        gapFilterValue: payload.classroomID,
        gapTests: payload.tests,
        gapTestKeys: payload.tests.map((t) => t.id),
        gapAnalysisTitle: payload.title,
      };

    case UIActions.SET_SCHOOL_VIEW_TAB_INDEX:
      return { ...state, schoolViewTab: payload };

    case UIActions.SET_SELECTED_STUDENT:
      return { ...state, selectedStudent: payload };

    case UIActions.SET_ASSESSMENT_TEST_TYPE:
      return { ...state, bpAssessmentTestTypeId: payload };

    case UIActions.SET_STUDENT_ASSESSMENT:
      return { ...state, assessment: payload };

    case UIActions.SET_TEST_TYPES:
      return { ...state, testTypes: payload };

    case UIActions.SET_IST_PERFORMANCE_TYPE:
      return { ...state, ISTPerformanceType: payload };

    case UIActions.SET_LEARNING_AREAS:
      return { ...state, learningAreas: payload };

    case UIActions.SET_NETWORK_SCHOOLS:
      return { ...state, networkSchools: payload };

    case UIActions.SET_FILERTYPE:
      return { ...state, filterType: payload };

    case UIActions.SET_FILTER_VALUE:
      return { ...state, filterValue: payload };

    // reducers for IST.
    case UIActions.SET_TESTS_UPLOADS:
      return { ...state, testUploads: payload };

    case UIActions.SET_TEST_YEAR:
      return { ...state, testYear: payload };

    case UIActions.SET_YEAR_LEVEL:
      return { ...state, yearLevel: payload };

    case UIActions.SET_GENDER:
      return { ...state, gender: payload };

    case UIActions.SET_COMPARE_TO:
      return { ...state, compareTo: payload };

    case UIActions.SET_SHOW:
      return { ...state, show: payload };

    case UIActions.SET_MINIMUM_STANDARDS:
      return { ...state, minimumStandards: payload };

    case UIActions.SET_SHOW_PRESENT_ONLY:
      return { ...state, showPresentOnly: payload };
    case UIActions.SET_REGION:
      return {
        ...state,
        region: payload,
      };
    case UIActions.SET_TEST_UNITS:
      return {
        ...state,
        testUnits: payload,
      };
    case UIActions.SET_REFRESH_TEST_GRID:
      return {
        ...state,
        refreshTestGrid: payload,
      };

    default:
      return state;
  }
};
