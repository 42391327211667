import { API, graphqlOperation } from "aws-amplify";
import { listTestUnits } from "../../../graphql/bpqueries";

export function fetchTestUnits() {
  return API.graphql(graphqlOperation(listTestUnits))
    .then((response) => {
      return response.data.listTestUnits.items;
    })
    .catch((error) => {
      console.error("Error fetching test units: ", error);
    });
}
