import Actions from "./lessons.actionstypes";
import notify from "devextreme/ui/notify";

const initialState = {
  currentLessonPlan: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.SET_CURRENT_SLIDE:
      return { ...state, currentSlide: payload };

    case Actions.SET_CURRENT_LEARNING_PLAN:
      return { ...state, currentLessonPlan: payload };

    case Actions.SET_LESSON_CODE:
      return { ...state, currentLessonCode: payload };

    case Actions.ADD_LESSON_TO_PLAN:
      const codes = state.currentLessonPlan.codes;
      
      const planCode = codes.find((c) =>
        c.acCode.acCode === payload);

      if (planCode) {
        notify({
          message: `${payload} lesson already exists in Lesson Plan`,
          type: "warning",
          displayTime: 1000,
          position: "top",
        });
        return state;
      } else {
        codes.push({ acCode: payload });
        notify({
          message: `${payload} lesson has been added to Lesson Plan`,
          type: "success",
          displayTime: 1000,
          position: "top",
        });
        return {
          ...state,
          currentLessonPlan: { codes, ...state.currentLessonPlan },
        };
      }

    default:
      return state;
  }
};
