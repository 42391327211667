import Actions from "./assessments.actionTypes";
import notify from "devextreme/ui/notify";
const initialState = {
  assessmentCodes: [],
  assessment: null,
  selectedStudents: [],
  premadeTests: [],
  classrooms: [],
  bpAssessmentTests: [],
  libraryTests: [],
  premiumTests: [],
  classActivities: [],
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case Actions.SET_ASSESSMENT_CODES:
      return { ...state, assessmentCodes: payload };

    case Actions.SET_STUDENT_ASSESSMENT:
      return { ...state, assessment: payload };

    case Actions.SET_SELECTED_STUDENTS:
      return { ...state, selectedStudents: payload };

    case Actions.SET_PREMADE_TESTS:
      return { ...state, premadeTests: payload };

    case Actions.SET_BP_ASSESSMENT_TESTS:
      return { ...state, bpAssessmentTests: payload };

    case Actions.SET_LIBRARY_TESTS:
      return { ...state, libraryTests: payload };

    case Actions.SET_PREMIUM_TESTS:
      return { ...state, premiumTests: payload };

    case Actions.SET_CLASSROOM_DATA:
      return { ...state, classrooms: payload };

    case Actions.SET_CLASS_ACTIVITIES:
      return { ...state, classActivities: payload };

    case Actions.SAVE_ASSESSMENT:
      const codes = state.assessmentCodes.codes;
      const planCode = false; //odes.find((c) => c.AcCode.acCode === payload.acCode);

      if (planCode) {
        notify({
          message: `${payload.acCode} lesson already exists in the Assessment`,
          type: "warning",
          displayTime: 1000,
          position: "top",
        });
        return state;
      } else {
        return {
          ...state,
          currentLessonPlan: { codes, ...state.currentLessonPlan },
        };
      }
    default:
      return state;
  }
};
