import React, { useState, useRef } from "react";
import { CFade, CAlert } from "@coreui/react";
import _ from "lodash";
import { userForgotPasswordStart } from "../../../redux/user/user.actions";
import { Popup } from "devextreme-react/popup";
import { useDispatch } from "react-redux";
import ForgotPassword from "./ForgotPassword";
import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  Label,
  GroupItem,
  EmailRule,
} from "devextreme-react/form";

const EmailConfirmationPopUp = ({ toggleShowPopUp}) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const formData = useRef({});

  const GetANewPassordOptions = {
    text: "Get a New Password",
    type: "success",
    useSubmitBehavior: true,
    width: "100%",
  };

  const cancelOptions = {
    text: "Cancel",
    type: "default",
    width: "100%",
    onClick: () => {
      toggleShowPopUp();
      setShowChangePassword(false);
    },
  };

  // const validateEmail = async (email) => {
  //   const code = "000000";
  //   await Auth.confirmSignUp(email, code, {
  //     // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
  //     forceAliasCreation: false,
  //   })
  //     .then((data) => {
  //       return true;
  //     })
  //     .catch((err) => {
  //       switch (err.code) {
  //         case "UserNotFoundException":
  //           showError("That email is not registered in our platform");
  //           return false;
  //         default:
  //           console.log("error when trying to find that user email", err);
  //           return true;
  //       }
  //     });
  // };

  const getANewPassword = async (e) => {
    e.preventDefault();
    const { email } = formData.current;

    // if (await validateEmail(email)) {
    dispatch(
      userForgotPasswordStart(
        _.chain(email).trim().toLower().deburr().value(),
        setError,
        setShowChangePassword
      )
    );
  };

  const toggleShowChangePassword = () => {
    setShowChangePassword((state) => !state);
    toggleShowPopUp();
  };

  if (showChangePassword) {
    return (
      <CFade>
        <ForgotPassword
          toggleShowChangePassword={toggleShowChangePassword}
          email={_.chain(formData.current.email)
            .trim()
            .toLower()
            .deburr()
            .value()}
        />
      </CFade>
    );
  } else {
    return (
      <CFade>
        <Popup
          showTitle
          title="Recover your password"
          width={400}
          height={300}
          visible
          showCloseButton={false}
        >
          <CAlert color="warning" style={error ? {} : { display: "none" }}>
            {error}
          </CAlert>
          <form width="100%" action="submit" onSubmit={getANewPassword}>
            <Form
              formData={formData.current}
              showColonAfterLabel={false}
              showValidationSummary
              width="100%"
              height="100%"
            >
              <SimpleItem
                dataField="email"
                editorType="dxTextBox"
                cssClass="brand-text-uk"
              >
                <Label location="top" />
                <RequiredRule message="Email is required" />
                <EmailRule message="Email is invalid" />
              </SimpleItem>
              <GroupItem
                itemType={"group"}
                colSpan={"1"}
                colCount={"2"}
                style={{ justifyContent: "center" }}
              >
                <ButtonItem
                  stylingMode="contained"
                  buttonOptions={GetANewPassordOptions}
                  cssClass="brand-singIn-button-uk"
                />
                <ButtonItem buttonOptions={cancelOptions} onClick />
              </GroupItem>
            </Form>
          </form>
        </Popup>
      </CFade>
    );
  }
};

export default EmailConfirmationPopUp;
