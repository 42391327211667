import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { CCard, CCardBody, CAlert, CFade } from "@coreui/react";
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  RequiredRule,
  Label,
} from "devextreme-react/form";
import _ from "lodash";
import { emailSignInStart } from "../../../redux/user/user.actions";
import EmailConfirmationPopUp from "./EmailConfirmationPopUp";

const LoginComponentForSchool = ({ error, schoolID, username }) => {
  const dispatch = useDispatch();
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const formData = useRef({ username });

  const signinButtonOptions = {
    text: "Sign In",
    type: "success",
    useSubmitBehavior: true,
    width: "100%",
  };

  const passwordOptions = {
    mode: "password",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, username } = formData.current;
    let email = getUserEmail(username, schoolID);
    dispatch(
      emailSignInStart({
        email: _.chain(email).trim().toLower().deburr().value(),
        password: password,
      })
    );
  };

  // it builds the user email.
  const getUserEmail = (username, schoolID) => {
    if ( username?.includes("@")) {
      return username;
    }
    return `${_.chain(username).trim().toLower().deburr().value()}@${schoolID}`;
  };

  const toggleShowPopUp = () => {
    setShowEmailPopup((state) => !state);
  };

  return (
    <CFade>
      {showEmailPopup && (
        <CFade>
          <EmailConfirmationPopUp toggleShowPopUp={toggleShowPopUp} />
        </CFade>
      )}
      {!showEmailPopup && (
        <CFade>
          <CCard className="not-opaque login-card-uk">
            {/* <CCardTitle>Student Login for @{schoolID}</CCardTitle> */}
            <CCardBody>
              <h6 className="mb-4 brand-text-uk">
                Enter username & password to sign in
              </h6>
              {error && error !== "not authenticated" && (
                <CAlert color="warning">{error}</CAlert>
              )}
              <form action="submit" onSubmit={handleSubmit}>
                <Form
                  formData={formData.current}
                  showColonAfterLabel={false}
                  showValidationSummary
                  validationGroup="userData"
                >
                  <GroupItem>
                    <SimpleItem
                      dataField="username"
                      editorType="dxTextBox"
                      validationGroup="userData"
                      cssClass="brand-text-uk"
                      helpText={`@${schoolID}`}
                    >
                      <Label location="top" />
                      <RequiredRule message="Username is required" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="password"
                      editorType="dxTextBox"
                      editorOptions={passwordOptions}
                      validationGroup="userData"
                      cssClass={"brand-text-uk"}
                    >
                      <Label location="top" />
                      <RequiredRule message="Password is required" />
                    </SimpleItem>
                  </GroupItem>
                  <ButtonItem
                    buttonOptions={signinButtonOptions}
                    cssClass="brand-singIn-button-uk"
                  />
                </Form>
              </form>
            </CCardBody>
          </CCard>
        </CFade>
      )}
    </CFade>
  );
};

export default LoginComponentForSchool;
