import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { CAlert, CCard, CCardBody } from "@coreui/react";
import Form, {
  GroupItem,
  SimpleItem,
  Label,
  RequiredRule,
  StringLengthRule,
  PatternRule,
  CompareRule,
} from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { userForgotPasswordSubmit } from "../../../redux/user/user.actions";
import PasswordValidation from "../../system/PasswordValidation";

const ForgotPassword = ({ toggleShowChangePassword, email }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const formData = useRef({ code: "", newPassword: "", confPassword: "" });

  const passwordOptions = {
    mode: "password",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.current.code === "") {
      setError("Code is required");
      return;
    }

    if (formData.current.newPassword !== formData.current.confPassword) {
      setError("Both passwords must match");
      return;
    }
    const { code, newPassword } = formData.current;
    dispatch(
      userForgotPasswordSubmit(email, code.toString(), newPassword, setError)
    );
  };

  const handleCancel = () => {
    toggleShowChangePassword();
  };

  const passwordComparison = () => {
    return formData.current.newPassword;
  };

  function handleChangePassword(e) {
    if (e && e.dataField === "newPassword") {
      setNewPassword(e.value);
    }
  }

  return (
    <CCard className="not-opaque login-card">
      <CCardBody style={{ display: "grid" }}>
        <div>
          <h6 className="mb-4 brand-text">
            Enter verification code & new password to sign in
          </h6>
          {error && <CAlert color="warning">{error}</CAlert>}
          <form action="submit" onSubmit={handleSubmit}>
            <Form
              formData={formData.current}
              showValidationSummary
              validationGroup="forgotPasswordData"
              onFieldDataChanged={handleChangePassword}
            >
              <GroupItem>
                <SimpleItem dataField="code">
                  <Label location="top" />
                  <RequiredRule message="The validation code is required" />
                </SimpleItem>
                <SimpleItem
                  dataField="newPassword"
                  editorType="dxTextBox"
                  editorOptions={passwordOptions}
                  autoComplete="new-password"
                >
                  <Label text="New Password" location="top" />
                  <RequiredRule message="The new password field is required" />
                  <StringLengthRule
                    min={8}
                    message="The new password must be minimum 8 characters"
                  />
                  <PatternRule
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])"
                    message="The password must contain Uppercase & Lowercase characters & at least 1 number"
                  />
                </SimpleItem>
                <SimpleItem
                  dataField="confPassword"
                  editorType="dxTextBox"
                  editorOptions={passwordOptions}
                  autoComplete="new-password"
                >
                  <Label text="Confirm Password" location="top" />
                  <RequiredRule message="The confirm password field is required" />
                  <CompareRule
                    message="Passwords do not match"
                    comparisonTarget={passwordComparison}
                  />
                </SimpleItem>
              </GroupItem>
            </Form>
            <div>
              <Button
                text="Cancel"
                type="normal"
                stylingMode="contained"
                className="mt-2 mb-2 float-right"
                onClick={handleCancel}
              />
              <Button
                text="Submit"
                type="success"
                stylingMode="contained"
                useSubmitBehavior
                className="m-2 float-right"
                validationGroup="forgotPasswordData"
              />
            </div>
          </form>
        </div>
        <div>
          <PasswordValidation password={newPassword} />
        </div>
      </CCardBody>
    </CCard>
  );
};

export default ForgotPassword;
