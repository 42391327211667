import UIActions from "./ui.actiontypes";

export const setLoadingTrue = (message) => ({
  type: UIActions.SET_LOADING_TRUE,
  payload: { message },
});

export const setLoadingFalse = () => ({
  type: UIActions.SET_LOADING_FALSE,
});

export const setRefreshTestGrid = (isTrue) => ({
  type: UIActions.SET_REFRESH_TEST_GRID,
  payload: { isTrue },
});

export const processingFailureAction = (message) => ({
  type: UIActions.PROCESSING_FAILURE,
  payload: message,
});

export const processingSuccessAction = (message) => ({
  type: UIActions.PROCESSING_SUCCESS,
  payload: message,
});

export const showFilterButtonAction = (val) => ({
  type: UIActions.SET_SHOW_FILTER_BUTTON,
  payload: val,
});

export const showFilterPanelAction = (val) => ({
  type: UIActions.SET_SHOW_FILTER_PANEL,
  payload: val,
});

export const showColumnChooserAction = (val) => ({
  type: UIActions.SET_SHOW_COLUMN_CHOOSER,
  payload: val,
});

export const setGridColumnsAction = (key, val) => ({
  type: UIActions.SET_GRID_COLUMNS,
  payload: { key, val },
});

export const setPrintButtonAction = (val, handler) => ({
  type: UIActions.SET_SHOW_PRINT_BUTTON,
  payload: { val, handler },
});

export const setEditButtonAction = (val, handler) => ({
  type: UIActions.SET_EDIT_LESSON,
  payload: { val, handler },
});

export const setGapAnalysisFilter = (val) => ({
  type: UIActions.SET_GAPANALYSIS_FILTER,
  payload: val,
});

export const setAddToLessonPlanButton = (val, handler) => ({
  type: UIActions.SET_ADD_TO_LESSONPLAN,
  payload: { val, handler },
});

export const setProficiencyFilter = (val) => ({
  type: UIActions.SET_PROFICIENCY_FILTER,
  payload: val,
});

export const resetHeaderButtonAction = () => ({
  type: UIActions.RESET_HEADER_BUTTONS,
});

export const setGapChartTypeAction = (val) => ({
  type: UIActions.SET_GAP_CHART_TYPE,
  payload: val,
});

export const markNotificationAsReadAction = (item) => ({
  type: UIActions.MARK_NOTIFICATION_READ,
  payload: item,
});

export const setStartButtonAction = (val, handler) => ({
  type: UIActions.SET_SHOW_START_BUTTON,
  payload: { val, handler },
});
export const setSaveButtonAction = (val, handler) => ({
  type: UIActions.SET_SHOW_SAVE_BUTTON,
  payload: { val, handler },
});
export const setSelectedSchoolAction = (school) => ({
  type: UIActions.SET_SELECTED_SCHOOL,
  payload: school,
});

export const setGapLearningAreasAction = (areas) => ({
  type: UIActions.SET_GAP_LEARNINGAREAS,
  payload: areas,
});

export const setSubTestTypesAction = (subtesttypes) => ({
  type: UIActions.SET_SUB_TESTTYPES,
  payload: subtesttypes,
});

export const setGapTestTypesAction = (types) => ({
  type: UIActions.SET_GAP_TESTTYPES,
  payload: types,
});

export const setGapFilterSelectedClassroom = (classroom) => ({
  type: UIActions.SET_GAP_SELECTEDCLASSROOM,
  payload: classroom,
});

export const setGapIsNewTestCreatedAction = (isTestCreated) => ({
  type: UIActions.SET_GAP_ISNEWTESTCREATED,
  payload: isTestCreated,
});

export const setGapTestsAction = (tests) => ({
  type: UIActions.SET_GAP_TESTS,
  payload: tests,
});

export const setMarksbookTestsAction = (tests) => ({
  type: UIActions.SET_MARKSBOOK_TESTS,
  payload: tests,
});

export const setGapDataAction = (data) => ({
  type: UIActions.SET_GAP_DATA,
  payload: data,
});

export const clearGapSettingsAction = () => ({
  type: UIActions.CLEAR_GAP_SETTINGS,
});

export const setGapAnalysisTitle = (title) => ({
  type: UIActions.SET_GAP_ANALYSIS_TITLE,
  payload: title,
});

export const setGapTestKeysAction = (keys) => ({
  type: UIActions.SET_GAP_TESTKEYS,
  payload: keys,
});

export const setMarksbookTestKeysAction = (keys) => ({
  type: UIActions.SET_MARKSBOOK_TEST_KEYS,
  payload: keys,
});

export const setGapFilterTypeAction = (val) => ({
  type: UIActions.SET_GAP_FILTER_TYPE,
  payload: val,
});

export const setGapSchoolsAction = (val) => ({
  type: UIActions.SET_GAP_SCHOOLS,
  payload: val,
});

export const setGapFilterValueAction = (val) => ({
  type: UIActions.SET_GAP_FILTER_VALUE,
  payload: val,
});

export const setClassroomYearLevelComponent = (val) => ({
  type: UIActions.SET_YEAR_LEVEL_OF_CLASSROOM_FILTER_VALUE,
  payload: val,
});

export const setWasASplitClassroomSelected = (val) => ({
  type: UIActions.SET_WAS_A_SPLIT_CLASSROOM_SELECTED,
  payload: val,
});

export const saveUserGapFilterAction = (val) => ({
  type: UIActions.SAVE_USER_GAPFILTER_SETTINGS,
  payload: val,
});

export const setUserGapValuesAction = (val) => ({
  type: UIActions.SET_USER_GAP_VALUES,
  payload: val,
});

export const runGapAnalysisAction = () => ({
  type: UIActions.RUN_GAP_ANALYSIS,
});

export const runGapAnalysisMarksbookAction = (val) => ({
  type: UIActions.RUN_GAP_ANALYSIS_MARKSBOOK,
  payload: {
    students: val.students,
    history: val.history,
  },
});

export const runGetMarksDataAction = () => ({
  type: UIActions.RUN_GET_MARKS_DATA,
});

export const runStudentGapAnalysisAction = (student) => ({
  type: UIActions.RUN_STUDENT_GAP_ANALYSIS,
  payload: { student },
});

export const runClassGapAnalysisAction = (classroom) => ({
  type: UIActions.RUN_CLASS_GAP_ANALYSIS,
  payload: classroom,
});

export const setStudentGapValuesAction = (studentID, tests, title) => ({
  type: UIActions.SET_STUDENT_GAP_VALUES,
  payload: { studentID, tests, title },
});

export const setClassroomGapValuesAction = (
  classroomID,
  classType,
  tests,
  title
) => ({
  type: UIActions.SET_CLASS_GAP_VALUES,
  payload: { classroomID, classType, tests, title },
});

export const setSchoolViewTabIndexAction = (index) => ({
  type: UIActions.SET_SCHOOL_VIEW_TAB_INDEX,
  payload: index,
});
export const runStudentProgressMapAction = (student) => ({
  type: UIActions.SET_STUDENT_PROGRESS_MAP_VALUES,
  payload: student,
});

export const setSelectedStudent = (student) => ({
  type: UIActions.SET_SELECTED_STUDENT,
  payload: { student },
});

export const setBpAssessmentTestTypeId = (testTypeId) => ({
  type: UIActions.SET_ASSESSMENT_TEST_TYPE,
  payload: { testTypeId },
});

// actions methods for IST.

export const setFilterTypeAction = (val) => ({
  type: UIActions.SET_FILERTYPE,
  payload: val,
});

export const setFilterValueAction = (val) => ({
  type: UIActions.SET_FILTER_VALUE,
  payload: val,
});

export const setLearningAreasAction = (val) => ({
  type: UIActions.SET_LEARNING_AREAS,
  payload: val,
});

export const setTestTypesAction = (val) => ({
  type: UIActions.SET_TEST_TYPES,
  payload: val,
});

export const setTestUploadsAction = (val) => ({
  type: UIActions.SET_TESTS_UPLOADS,
  payload: val,
});

export const setNetworkSchoolsAction = (val) => ({
  type: UIActions.SET_NETWORK_SCHOOLS,
  payload: val,
});

export const setTestYear = (val) => ({
  type: UIActions.SET_TEST_YEAR,
  payload: val,
});

export const setYearLevel = (val) => ({
  type: UIActions.SET_YEAR_LEVEL,
  payload: val,
});

export const setGender = (val) => ({
  type: UIActions.SET_GENDER,
  payload: val,
});

export const setCompareTo = (val) => ({
  type: UIActions.SET_COMPARE_TO,
  payload: val,
});

export const setShow = (val) => ({
  type: UIActions.SET_SHOW,
  payload: val,
});

export const setMinimumStandards = (val) => ({
  type: UIActions.SET_MINIMUM_STANDARDS,
  payload: val,
});

export const setShowPresentOnly = (val) => ({
  type: UIActions.SET_SHOW_PRESENT_ONLY,
  payload: val,
});
export const setRegion = (region) => ({
  type: UIActions.SET_REGION,
  payload: region,
});

export const setISTPerformanceType = (ISTPerformanceType) => ({
  type: UIActions.SET_IST_PERFORMANCE_TYPE,
  payload: ISTPerformanceType,
});

export const setTestUnitsAction = (testUnits) => ({
  type: UIActions.SET_TEST_UNITS,
  payload: testUnits,
});
