import { API, graphqlOperation } from "aws-amplify";

/**
 * This method execute an appsync query received as parameter and then returns the data.
 * This is safe of next token issue and should be used everywhere when calling appsync queries or performing queries to a big table.
 */
export async function fetchAllNextTokenData(queryName, query, input) {
  let nextToken = null;
  let data = [];

  do {
    input.nextToken = nextToken;
    let searchResults = await API.graphql(graphqlOperation(query, input));

    data = [...data, ...searchResults.data[queryName].items];

    nextToken = searchResults.data[queryName].nextToken;
  } while (nextToken != null);

  return data;
}

/**
 * This method will execute any mutation receive as a parameter with its own input.
 */
export async function mutate(mutationName, mutation, input) {
  try {
    const response = await API.graphql(graphqlOperation(mutation, { input }));
    return response.data[mutationName];
  } catch (error) {
    throw error;
  }
}
