import React from "react";
import { TheContent, TheSidebar, TheHeader } from "./index";
import { MenuUrls } from "../utils/constants";
import { useHistory } from "react-router-dom";

const TheLayout = () => {
  const history = useHistory();
  const tabUrlChanged =
    history.location.pathname === MenuUrls.PAM_NEWREQUEST ||
    history.location.pathname === MenuUrls.PAM_SCHOOLS ||
    history.location.pathname === MenuUrls.PAM_STUDENT_LICENCE_MANAGE;

  return (
    <div className="c-app c-default-layout">
      <TheSidebar tabUrlChanged={tabUrlChanged} />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
