export default {
  SET_LOADING_TRUE: "SET_LOADING_TRUE",
  SET_LOADING_FALSE: "SET_LOADING_FALSE",
  SET_REFRESH_TEST_GRID: "SET_REFRESH_TEST_GRID",
  PROCESSING_FAILURE: "PROCESSING_FAILURE",
  PROCESSING_SUCCESS: "PROCESSING_SUCCESS",
  CLEAR_GAP_SETTINGS: "CLEAR_GAP_SETTINGS",
  SET_SELECTED_SCHOOL: "SET_SELECTED_SCHOOL",
  SET_GAPANALYSIS_FILTER: "SET_GAPANALYSIS_FILTER",
  SET_GAP_LEARNINGAREAS: "SET_GAP_LEARNINGAREAS",
  SET_GAP_TESTTYPES: "SET_GAP_TESTTYPES",
  SET_GAP_SELECTEDCLASSROOM: "SET_GAP_SELECTEDCLASSROOM",
  SET_GAP_ISNEWTESTCREATED: "SET_GAP_ISNEWTESTCREATED",
  SET_SUB_TESTTYPES: "SET_SUB_TESTTYPES",
  SET_GAP_TESTKEYS: "SET_GAP_TESTKEYS",
  SET_MARKSBOOK_TEST_KEYS: "SET_MARKSBOOK_TEST_KEYS",
  SET_GAP_TESTS: "SET_GAP_TESTS",
  SET_MARKSBOOK_TESTS: "SET_MARKSBOOK_TESTS",
  SET_GAP_DATA: "SET_GAP_DATA",
  SET_GAP_ANALYSIS_TITLE: "SET_GAP_ANALYSIS_TITLE",
  SET_GAP_FILTER_TYPE: "SET_GAP_FILTER_TYPE",
  SET_GAP_FILTER_VALUE: "SET_GAP_FILTER_VALUE",
  SET_GAP_SCHOOLS: "SET_GAP_SCHOOLS",
  SET_USER_GAP_VALUES: "SET_USER_GAP_VALUES",
  SET_STUDENT_GAP_VALUES: "SET_STUDENT_GAP_VALUES",
  SET_CLASS_GAP_VALUES: "SET_CLASS_GAP_VALUES",
  SAVE_USER_GAPFILTER_SETTINGS: "SAVE_USER_GAPFILTER_SETTINGS",
  RUN_GAP_ANALYSIS: "RUN_GAP_ANALYSIS",
  RUN_GAP_ANALYSIS_MARKSBOOK: "RUN_GAP_ANALYSIS_MARKSBOOK",
  RUN_GET_MARKS_DATA: "RUN_GET_MARKS_DATA",
  RUN_STUDENT_GAP_ANALYSIS: "RUN_STUDENT_GAP_ANALYSIS",
  RUN_CLASS_GAP_ANALYSIS: "RUN_CLASS_GAP_ANALYSIS",
  MARK_NOTIFICATION_READ: "MARK_NOTIFICATION_READ",
  SET_SHOW_FILTER_BUTTON: "SET_SHOW_FILTER_BUTTON",
  SET_SHOW_FILTER_PANEL: "SET_SHOW_FILTER_PANEL",
  SET_SHOW_COLUMN_CHOOSER: "SET_SHOW_COLUMN_CHOOSER",
  SET_GRID_COLUMNS: "SET_GRID_COLUMNS",
  SET_SHOW_PRINT_BUTTON: "SET_SHOW_PRINT_BUTTON",
  SET_GAP_CHART_TYPE: "SET_GAP_CHART_TYPE",
  SET_ADD_TO_LESSONPLAN: "SET_ADD_TO_LESSONPLAN",
  SET_EDIT_LESSON: "SET_EDIT_LESSON",
  SET_PROFICIENCY_FILTER: "SET_PROFICIENCY_FILTER",
  RESET_HEADER_BUTTONS: "RESET_HEADER_BUTTONS",
  SET_SHOW_START_BUTTON: "SET_SHOW_START_BUTTON",
  SET_SHOW_SAVE_BUTTON: "SET_SHOW_SAVE_BUTON",
  SET_SCHOOL_VIEW_TAB_INDEX: "SET_SCHOOL_VIEW_TAB_INDEX",
  SET_STUDENT_PROGRESS_MAP_VALUES: "SET_STUDENT_PROGRESS_MAP_VALUES",
  SET_SELECTED_STUDENT: "SET_SELECTED_STUDENT",
  SET_ASSESSMENT_TEST_TYPE: "SET_ASSESSMENT_TEST_TYPE",
  SET_TEST_UNITS: "SET_TEST_UNITS",

  // Actions constants for IST.
  SET_WAS_A_SPLIT_CLASSROOM_SELECTED: "SET_WAS_A_SPLIT_CLASSROOM_SELECTED",
  SET_YEAR_LEVEL_OF_CLASSROOM_FILTER_VALUE:
    "SET_YEAR_LEVEL_OF_CLASSROOM_FILTER_VALUE",
  SET_FILERTYPE: "SET_FILTERTYPE",
  SET_LEARNING_AREAS: "SET_LEARNING_AREAS",
  SET_TEST_TYPES: "SET_TEST_TYPES",
  SET_TESTS_UPLOADS: "SET_TESTS_UPLOADS",
  SET_FILTER_VALUE: "SET_FILTER_VALUE",
  SET_NETWORK_SCHOOLS: "SET_NETWORK_SCHOOLS",
  SET_TEST_YEAR: "SET_TEST_YEAR",
  SET_YEAR_LEVEL: "SET_YEAR_LEVEL",
  SET_GENDER: "SET_GENDER",
  SET_COMPARE_TO: "SET_COMPARE_TO",
  SET_SHOW: "SET_SHOW",
  SET_MINIMUM_STANDARDS: "SET_MINIMUM_STANDARDS",
  SET_SHOW_PRESENT_ONLY: "SET_SHOW_PRESENT_ONLY",
  SET_REGION: "SET_REGION",
  // IST
  SET_IST_PERFORMANCE_TYPE: "SET_IST_PERFORMANCE_TYPE",
};
