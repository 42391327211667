import React, { useCallback, useEffect, useState } from "react";
import { CCol, CImg, CRow } from "@coreui/react";
import localforage from "localforage";
import { Storage } from "aws-amplify";

const SchoolLogoName = ({ school }) => {
  const [logoImage, setLogoImage] = useState(null);

  const getSchoolLogo = useCallback(async () => {
    if ( school?.logo) {
      try {
        const val = await localforage.getItem(school.logo.key);

        if (!val) {
          try {
            const s3Url = await Storage.get(school.logo.key, {
              download: true,
            });

            if (s3Url) {
              const image = await localforage.setItem(
                school.logo.key,
                s3Url.Body
              );
              const blob = new Blob([image]);
              const imageURI = window.URL.createObjectURL(blob);
              setLogoImage(imageURI);
            }
          } catch (error) {
            console.log("s3 logo error", error);
          }
        } else {
          const blob = new Blob([val]);
          const imageURI = window.URL.createObjectURL(blob);
          setLogoImage(imageURI);
        }
      } catch (error) {
        console.log("logo error", error);
      }
    } else {
      setLogoImage(null);
    }
  }, [school]);

  useEffect(() => {
    getSchoolLogo();
  }, [getSchoolLogo]);

  return (
    <CRow className="mb-0 align-items-center schoolNameAndLogoContainer">
      {school && (
        <>
          <CCol className="mr-2 mt-1 mb-1">
            {logoImage && (
              <CImg
                src={logoImage}
                className="school-logo"
                alt={school.schoolName}
              />
            )}
          </CCol>
          <CRow>
            <CCol>
              <h6 className="mt-2 mb-0 schoolName">{school.schoolName}</h6>
              <p className="motto">
                <em>{school.motto}</em>
              </p>
            </CCol>
          </CRow>
        </>
      )}
    </CRow>
  );
};

export default SchoolLogoName;
