import React, { useEffect, useState, useCallback } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { signOutStart } from "../redux/user/user.actions";
import { useHistory } from "react-router-dom";
import { Storage } from "aws-amplify";
import localforage from "localforage";

const TheHeaderDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector((state) => state.user.userProfile);
  const [profileImage, setProfileImage] = useState("");
  const schoolID = userProfile?.school?.schoolName.split(" ").join("").toLowerCase();

  const getProfileImage = useCallback(async () => {
    if (userProfile.avatar !== null) {
      try {
        const val = await localforage.getItem(userProfile.avatar.key);

        if (!val) {
          try {
            const s3Url = await Storage.get(userProfile.avatar.key, {
              download: true,
            });

            if (s3Url) {
              const image = await localforage.setItem(
                userProfile.avatar.key,
                s3Url.Body
              );
              const blob = new Blob([image]);
              const imageURI = window.URL.createObjectURL(blob);

              setProfileImage(imageURI);
            }
          } catch (error) {
            console.log("S3 error", error);
          }
        } else {
          const blob = new Blob([val]);
          const imageURI = window.URL.createObjectURL(blob);
          setProfileImage(imageURI);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [userProfile.avatar]);

  useEffect(() => {
    getProfileImage();
  }, [getProfileImage]);

  const handleSignOut = () => {
    dispatch(signOutStart(schoolID, userProfile.userType));
  };

  const handleShowProfile = () => {
    history.push(`/profile/${userProfile.email}`);
  };

  return (
    <CDropdown inNav className="c-header-nav-items mr-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          {profileImage ? (
            <CImg src={profileImage} className="c-avatar-img" alt="User" />
          ) : (
            <CIcon name="cil-User" height={25} />
          )}
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">

        {userProfile.userType !== "Student" && (
          <CDropdownItem onClick={handleShowProfile}>
            <CIcon name="cil-user" className="mfe-2" />
            Profile
          </CDropdownItem>
        )}
        {userProfile.userType !== "Student" && <CDropdownItem divider />}
        <CDropdownItem onClick={handleSignOut}  data-cy="header-dropdown-signout">
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Sign Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
