export const getWAMDataBySchoolByModuleType = /* GraphQL */ `
  query getWAMDataBySchoolByModuleType(
    $schoolID: ID
    $moduleType: ModelStringKeyConditionInput
  ) {
    getWAMDataBySchoolByModuleType(
      schoolID: $schoolID
      moduleType: $moduleType
    ) {
      items {
        id
        schoolID
        currentStatus
        currentLicencesAvailable
        totalLicences
        startDate
        endDate
        moduleType
        School {
          id
          overrideWAMAccess
          overridePAMAccess
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const getWAMDataBySchool = /* GraphQL */ `
  query getWAMDataBySchool($schoolID: ID) {
    getWAMDataBySchool(schoolID: $schoolID) {
      items {
        id
        schoolID
        currentStatus
        currentLicencesAvailable
        totalLicences
        startDate
        endDate
        createdAt
        updatedAt
      }
    }
  }
`;

export const listWAMLicenceRequestHistorys = /* GraphQL */ `
  query listWAMLicenceRequestHistorys(
    $filter: ModelWAMLicenceRequestHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWAMLicenceRequestHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        numberOfLicencesAssigned
        transactionType
        moduleType
        requesterEmail
        schoolYear
        schoolID
        remarks
        isATrial
        wasReversed
        createdAt
        updatedAt
        Requester {
          userId
          firstName
          lastName
          email
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
        }
      }
      nextToken
    }
  }
`;

export const listWAMStudentLicenceHistorys = /* GraphQL */ `
  query listWAMStudentLicenceHistorys(
    $filter: ModelWAMStudentLicenceHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWAMStudentLicenceHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        allocatorEmail
        schoolStudentID
        transactionType
        schoolYear
        createdAt
        updatedAt
        Allocator {
          userId
          firstName
          lastName
          email
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
        }
        SchoolStudent {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          assignedDateToWAM
          userId
          student {
            id
            firstName
            middleName
            lastName
            gender
            birthDate
            currentYear {
              id
              yearCode
              description
              type
            }
            classrooms {
              items {
                id
                classroomID
                studentID
                createdAt
                updatedAt
                classroom {
                  id
                  className
                  classType
                  schoolYear
                  schoolID
                }
              }
              nextToken
            }
          }
          yearLevel {
            id
            yearCode
            description
            type
          }
        }
      }
      nextToken
    }
  }
`;

export const listSchoolWAMs = /* GraphQL */ `
  query listSchoolWAMs(
    $filter: ModelSchoolWAMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolWAMs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolID
        currentLicencesAvailable
        currentStatus
        totalLicences
        startDate
        endDate
        moduleType
        createdAt
        updatedAt

        # CANNOT PUT SCHOOL here coz it throws nullable error unless we change in schema
        # School {
        #   id
        #   dummy
        #   schoolName
        # }
      }
      nextToken
    }
  }
`;
