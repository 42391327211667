import React, { lazy, useEffect, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Router } from "react-router-dom";
import { Auth } from "aws-amplify";
import { LoadPanel } from "devextreme-react/load-panel";

import "./scss/style.scss";
import "devextreme/dist/css/dx.common.css";
import "./css/dx.generic.BP-brand-colours-v20-2-5.css";
import awsExports from "./aws-exports";

import history from "../src/containers/history";
import { checkUserSession } from "./redux/user/user.actions";
// Containers
import TheLayout from "./containers/TheLayout";

// Deployment Types
import { setRegion } from "./redux/app/ui.actions";
import LoginPageForSchoolStudent from "views/pages/users/LoginPageForSchoolStudent";

// Feature flags
import loadFeatureFlagsForUserAndSchool from "./utils/FeatureFlagUtils";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";

import "intro.js/introjs.css";

const suspenseLoading = (
  <LoadPanel
    closeOnOutsideClick={false}
    message="Loading..."
    shadingColor="rgba(0,0,0,0.4)"
    shading
    showIndicator
    visible
    width={400}
  />
);
// Pages
const LoginPage = lazy(() => import("./views/pages/users/LoginPage"));
const Page404 = lazy(() => import("./views/pages/page404/Page404"));
const EdcompanionRedirectToElastik = lazy(() =>
  import("./views/pages/EdcompanionRedirectToElastik")
);

const gb = new GrowthBook({}); // Initialize GrowthBook

const App = () => {
  const loading = useSelector((state) => state.app.loading);
  const loadingMessage = useSelector((state) => state.app.loadingMessage);
  const selectedSchool = useSelector((state) => state.app.selectedSchool);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const userProfile = useSelector((state) => state.user.userProfile);
  const { aws_project_region: region } = awsExports;

  useEffect(() => {
    dispatch(setRegion(region)); // Set region as per the aws-exports

    // Document Title and Favicon
    const link = document.querySelector("link[rel~='icon']");
    document.title = "Elastik";
    link.href = "./elastik_YouTube_Profile-08.png";
  });

  useEffect(() => {
    dispatch(checkUserSession("Signing in..."));
  }, [dispatch]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      if (window.location.pathname.includes("schoolLogin")) {
        const schoolID = window.location.pathname.split("/")[2];
        history.push(`/schoolLogin/${schoolID}`);
      } else if (window.location.pathname.includes("studentLogin")) {
        const schoolID = window.location.pathname.split("/")[2];
        const username = window.location.pathname.split("/")[3];
        history.push(`/studentLogin/${schoolID}/${username}`);
      } else {
        history.push("/login");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load and set feature flags for the application
  useEffect(() => {
    let active = true;
    loadFeatureFlags(); // definition just below
    return () => {
      active = false;
    }; // cleanup function

    async function loadFeatureFlags() {
      if (!userProfile?.userId) {
        return;
      } // short-circuit if we're not logged in
      const flags = await loadFeatureFlagsForUserAndSchool(userProfile, selectedSchool);
      if (!active) {
        return;
      } // short-circuit if we're no longer active e.g. due to rerender
      gb.setFeatures(flags);
      gb.setAttributes(userProfile.userId, {
        userId: userProfile.userId,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        userGroup: userProfile.userGroup,
        userType: userProfile.userType,
        userSchoolID: userProfile.userSchoolID,
        schoolName: userProfile.school.schoolName,
        school: userProfile.school,
      });
    }
  }, [selectedSchool, userProfile]);

  const shouldRedirectToElastik = () => {
    if (/(^|\.)edcompanion\.com\.au$/.test(window.location.hostname)) {
      return true;
    }
    return false;
  };

  // TODO: remove redirection once edcompanion is decommissioned.
  return shouldRedirectToElastik() ? (
    // redirect
    <Suspense fallback={suspenseLoading}>
      <EdcompanionRedirectToElastik />
    </Suspense>
  ) : (
    <GrowthBookProvider growthbook={gb}>
      <Router history={history}>
        <LoadPanel
          elementAttr={{
            id: "global-load-panel",
            "data-cy": "global-load-panel",
          }}
          closeOnOutsideClick={false}
          message={loadingMessage}
          shadingColor="rgba(0,0,0,0.4)"
          shading
          showIndicator
          visible={loading}
          width={400}
        />
        <Suspense fallback={suspenseLoading}>
          <Switch>
            <Route
              path="/login"
              name="Sign In Page"
              render={(props) => <LoginPage {...props} />}
            />
            <Route
              path="/schoolLogin/:schoolID"
              name="Sign In For School"
              render={(props) => <LoginPageForSchoolStudent {...props} />}
            />
            <Route
              path="/studentLogin/:schoolID/:username"
              name="Sign In For School"
              render={(props) => <LoginPageForSchoolStudent {...props} />}
            />
            <Route
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) =>
                currentUser ? <TheLayout {...props} /> : <></>
              }
            />
          </Switch>
        </Suspense>
      </Router>
    </GrowthBookProvider>
  );
};

export default App;
