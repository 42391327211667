/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGapAnalysisTests = /* GraphQL */ `
  query GetGapAnalysisTests(
    $studentID: String
    $classroomID: String
    $schoolID: String
    $yearLevelID: String
    $networkSchools: [String]
    $learningAreas: [String]
    $testTypes: [String]
    $startYear: Int
    $endYear: Int
    $testDate: String
  ) {
    getGapAnalysisTests(
      studentID: $studentID
      classroomID: $classroomID
      schoolID: $schoolID
      yearLevelID: $yearLevelID
      networkSchools: $networkSchools
      learningAreas: $learningAreas
      testTypes: $testTypes
      startYear: $startYear
      endYear: $endYear
      testDate: $testDate
    ) {
      id
      testID
      testName
      testDate
      learningAreaID
      testKey
    }
  }
`;
export const getGapAnalysisData = /* GraphQL */ `
  query GetGapAnalysisData(
    $studentID: String
    $classroomID: String
    $schoolID: String
    $yearLevelID: String
    $networkSchools: [String]
    $tests: [String]
    $learningAreas: [String]
  ) {
    getGapAnalysisData(
      studentID: $studentID
      classroomID: $classroomID
      schoolID: $schoolID
      yearLevelID: $yearLevelID
      networkSchools: $networkSchools
      tests: $tests
      learningAreas: $learningAreas
    ) {
      learningAreaID
      areaName
      colour
      numStudents
      summary {
        year
        totalCodes
        incorrectCodes
        studentCount
      }
    }
  }
`;
export const getRubric = /* GraphQL */ `
  query GetRubric($id: ID!) {
    getRubric(id: $id) {
      id
      taskType
      rubricName
      description
      maxScore
      progressComments {
        items {
          id
          rubricId
          score
          taskType
          progressComment
          createdAt
          updatedAt
        }
        nextToken
      }
      benchmarks {
        items {
          id
          taskType
          promptID
          yearLevelID
          rubricID
          benchmark
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRubrics = /* GraphQL */ `
  query ListRubrics(
    $filter: ModelRubricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRubrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRubricProgressComments = /* GraphQL */ `
  query GetRubricProgressComments($id: ID!) {
    getRubricProgressComments(id: $id) {
      id
      rubricId
      rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      score
      taskType
      progressComment
      createdAt
      updatedAt
    }
  }
`;
export const listRubricProgressCommentss = /* GraphQL */ `
  query ListRubricProgressCommentss(
    $filter: ModelRubricProgressCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRubricProgressCommentss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rubricId
        rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        score
        taskType
        progressComment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrompt = /* GraphQL */ `
  query GetPrompt($id: ID!) {
    getPrompt(id: $id) {
      id
      taskType
      promptName
      text
      stimulus
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPrompts = /* GraphQL */ `
  query ListPrompts(
    $filter: ModelPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      classroomID
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        learningStage
        createdAt
        updatedAt
      }
      AssignedStudents
      SelectedRubrics {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      schoolID
      schoolYear
      creatorEmail
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classroomID
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          createdAt
          updatedAt
        }
        AssignedStudents
        SelectedRubrics {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        schoolID
        schoolYear
        creatorEmail
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRubricBenchmark = /* GraphQL */ `
  query GetRubricBenchmark($id: ID!) {
    getRubricBenchmark(id: $id) {
      id
      taskType
      promptID
      prompt {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      yearLevelID
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      benchmark
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listRubricBenchmarks = /* GraphQL */ `
  query ListRubricBenchmarks(
    $filter: ModelRubricBenchmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRubricBenchmarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          createdAt
          updatedAt
        }
        yearLevelID
        rubricID
        Rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        benchmark
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolWAM = /* GraphQL */ `
  query GetSchoolWAM($id: ID!) {
    getSchoolWAM(id: $id) {
      id
      schoolID
      currentLicencesAvailable
      currentStatus
      moduleType
      totalLicences
      startDate
      endDate
      createdAt
      updatedAt
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const listSchoolWAMs = /* GraphQL */ `
  query ListSchoolWAMs(
    $filter: ModelSchoolWAMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolWAMs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolID
        currentLicencesAvailable
        currentStatus
        moduleType
        totalLicences
        startDate
        endDate
        createdAt
        updatedAt
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWAMLicenceRequestHistory = /* GraphQL */ `
  query GetWAMLicenceRequestHistory($id: ID!) {
    getWAMLicenceRequestHistory(id: $id) {
      id
      schoolID
      startDate
      endDate
      numberOfLicencesAssigned
      transactionType
      requesterEmail
      schoolYear
      moduleType
      remarks
      isATrial
      wasReversed
      createdAt
      updatedAt
      Requester {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const listWAMLicenceRequestHistorys = /* GraphQL */ `
  query ListWAMLicenceRequestHistorys(
    $filter: ModelWAMLicenceRequestHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWAMLicenceRequestHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        startDate
        endDate
        numberOfLicencesAssigned
        transactionType
        requesterEmail
        schoolYear
        moduleType
        remarks
        isATrial
        wasReversed
        createdAt
        updatedAt
        Requester {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWAMStudentLicenceHistory = /* GraphQL */ `
  query GetWAMStudentLicenceHistory($id: ID!) {
    getWAMStudentLicenceHistory(id: $id) {
      id
      schoolID
      allocatorEmail
      schoolStudentID
      transactionType
      moduleType
      schoolYear
      createdAt
      updatedAt
      Allocator {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      School {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      SchoolStudent {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listWAMStudentLicenceHistorys = /* GraphQL */ `
  query ListWAMStudentLicenceHistorys(
    $filter: ModelWAMStudentLicenceHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWAMStudentLicenceHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        allocatorEmail
        schoolStudentID
        transactionType
        moduleType
        schoolYear
        createdAt
        updatedAt
        Allocator {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        SchoolStudent {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRubricVideo = /* GraphQL */ `
  query GetRubricVideo($id: ID!) {
    getRubricVideo(id: $id) {
      id
      rubricID
      Rubric {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      yearLevelID
      videoLink
      createdAt
      updatedAt
      YearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listRubricVideos = /* GraphQL */ `
  query ListRubricVideos(
    $filter: ModelRubricVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRubricVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rubricID
        Rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        yearLevelID
        videoLink
        createdAt
        updatedAt
        YearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBooklet = /* GraphQL */ `
  query GetBooklet($id: ID!) {
    getBooklet(id: $id) {
      id
      name
      bookletContent
      areQuestionsRandomised
      createdAt
      updatedAt
      bookletQuestions {
        items {
          id
          acCodeID
          yearLevelID
          learningAreaID
          strandID
          substrandID
          question
          questionType
          imageRow
          answerSelectionType
          answerTitle
          messageForCorrectAnswer
          messageForIncorrectAnswer
          explanation
          points
          difficulty
          hasHTMLContent
          isPremium
          bookletID
          orderInBooklet
          isClozeQuestion
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBooklets = /* GraphQL */ `
  query ListBooklets(
    $filter: ModelBookletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooklets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        bookletContent
        areQuestionsRandomised
        createdAt
        updatedAt
        bookletQuestions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRubricByTaskType = /* GraphQL */ `
  query GetRubricByTaskType(
    $taskType: TaskType
    $sortDirection: ModelSortDirection
    $filter: ModelRubricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRubricByTaskType(
      taskType: $taskType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        rubricName
        description
        maxScore
        progressComments {
          nextToken
        }
        benchmarks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRubricProgressCommentsByTaskType = /* GraphQL */ `
  query GetRubricProgressCommentsByTaskType(
    $taskType: TaskType
    $sortDirection: ModelSortDirection
    $filter: ModelRubricProgressCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRubricProgressCommentsByTaskType(
      taskType: $taskType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rubricId
        rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        score
        taskType
        progressComment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgressCommentsByRubric = /* GraphQL */ `
  query GetProgressCommentsByRubric(
    $rubricId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRubricProgressCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProgressCommentsByRubric(
      rubricId: $rubricId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rubricId
        rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        score
        taskType
        progressComment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPromptByTaskType = /* GraphQL */ `
  query GetPromptByTaskType(
    $taskType: TaskType
    $sortDirection: ModelSortDirection
    $filter: ModelPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPromptByTaskType(
      taskType: $taskType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        promptName
        text
        stimulus
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivitiesByClassroomId = /* GraphQL */ `
  query GetActivitiesByClassroomId(
    $classroomID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getActivitiesByClassroomId(
      classroomID: $classroomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          learningStage
          createdAt
          updatedAt
        }
        AssignedStudents
        SelectedRubrics {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        schoolID
        schoolYear
        creatorEmail
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getActivitiesByPrompt = /* GraphQL */ `
  query GetActivitiesByPrompt(
    $promptID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getActivitiesByPrompt(
      promptID: $promptID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          learningStage
          createdAt
          updatedAt
        }
        AssignedStudents
        SelectedRubrics {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        schoolID
        schoolYear
        creatorEmail
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getActivitiesBySchoolByYear = /* GraphQL */ `
  query GetActivitiesBySchoolByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getActivitiesBySchoolByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          learningStage
          createdAt
          updatedAt
        }
        AssignedStudents
        SelectedRubrics {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        schoolID
        schoolYear
        creatorEmail
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRubricBenchmarkByTaskType = /* GraphQL */ `
  query GetRubricBenchmarkByTaskType(
    $taskType: TaskType
    $sortDirection: ModelSortDirection
    $filter: ModelRubricBenchmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRubricBenchmarkByTaskType(
      taskType: $taskType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          learningStage
          createdAt
          updatedAt
        }
        yearLevelID
        rubricID
        Rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        benchmark
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRubricBenchmarkByRubric = /* GraphQL */ `
  query GetRubricBenchmarkByRubric(
    $rubricID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRubricBenchmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRubricBenchmarkByRubric(
      rubricID: $rubricID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          learningStage
          createdAt
          updatedAt
        }
        yearLevelID
        rubricID
        Rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        benchmark
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRubricBenchmarkByPromptAndTaskType = /* GraphQL */ `
  query GetRubricBenchmarkByPromptAndTaskType(
    $promptID: ID
    $taskType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRubricBenchmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRubricBenchmarkByPromptAndTaskType(
      promptID: $promptID
      taskType: $taskType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        promptID
        prompt {
          id
          taskType
          promptName
          text
          stimulus
          learningStage
          createdAt
          updatedAt
        }
        yearLevelID
        rubricID
        Rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        benchmark
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWAMDataBySchool = /* GraphQL */ `
  query GetWAMDataBySchool(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolWAMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWAMDataBySchool(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        currentLicencesAvailable
        currentStatus
        moduleType
        totalLicences
        startDate
        endDate
        createdAt
        updatedAt
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWAMDataBySchoolByModuleType = /* GraphQL */ `
  query GetWAMDataBySchoolByModuleType(
    $schoolID: ID
    $moduleType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolWAMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWAMDataBySchoolByModuleType(
      schoolID: $schoolID
      moduleType: $moduleType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        currentLicencesAvailable
        currentStatus
        moduleType
        totalLicences
        startDate
        endDate
        createdAt
        updatedAt
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolLicenceHistoryBySchoolByYear = /* GraphQL */ `
  query GetSchoolLicenceHistoryBySchoolByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWAMLicenceRequestHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolLicenceHistoryBySchoolByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        startDate
        endDate
        numberOfLicencesAssigned
        transactionType
        requesterEmail
        schoolYear
        moduleType
        remarks
        isATrial
        wasReversed
        createdAt
        updatedAt
        Requester {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolLicenceHistoryByTransactionType = /* GraphQL */ `
  query GetSchoolLicenceHistoryByTransactionType(
    $transactionType: WAMSchoolLicenceTransactionType
    $sortDirection: ModelSortDirection
    $filter: ModelWAMLicenceRequestHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolLicenceHistoryByTransactionType(
      transactionType: $transactionType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        startDate
        endDate
        numberOfLicencesAssigned
        transactionType
        requesterEmail
        schoolYear
        moduleType
        remarks
        isATrial
        wasReversed
        createdAt
        updatedAt
        Requester {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolLicenceHistoryBySchoolByYearByRequester = /* GraphQL */ `
  query GetSchoolLicenceHistoryBySchoolByYearByRequester(
    $schoolID: ID
    $schoolYearRequesterEmail: ModelWAMLicenceRequestHistoryBySchoolAndSchoolYearAndRequesterCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWAMLicenceRequestHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolLicenceHistoryBySchoolByYearByRequester(
      schoolID: $schoolID
      schoolYearRequesterEmail: $schoolYearRequesterEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        startDate
        endDate
        numberOfLicencesAssigned
        transactionType
        requesterEmail
        schoolYear
        moduleType
        remarks
        isATrial
        wasReversed
        createdAt
        updatedAt
        Requester {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolLicenceHistoryBySchoolByModuleType = /* GraphQL */ `
  query GetSchoolLicenceHistoryBySchoolByModuleType(
    $schoolID: ID
    $moduleType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWAMLicenceRequestHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolLicenceHistoryBySchoolByModuleType(
      schoolID: $schoolID
      moduleType: $moduleType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        startDate
        endDate
        numberOfLicencesAssigned
        transactionType
        requesterEmail
        schoolYear
        moduleType
        remarks
        isATrial
        wasReversed
        createdAt
        updatedAt
        Requester {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentLicenceHistoryBySchoolByYear = /* GraphQL */ `
  query GetStudentLicenceHistoryBySchoolByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWAMStudentLicenceHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentLicenceHistoryBySchoolByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        allocatorEmail
        schoolStudentID
        transactionType
        moduleType
        schoolYear
        createdAt
        updatedAt
        Allocator {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        SchoolStudent {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentLicenceHistoryBySchoolByYearByTransactionType = /* GraphQL */ `
  query GetStudentLicenceHistoryBySchoolByYearByTransactionType(
    $schoolID: ID
    $schoolYearTransactionType: ModelWAMStudentLicenceHistoryBySchoolAndSchoolYearAndTransactionTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWAMStudentLicenceHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentLicenceHistoryBySchoolByYearByTransactionType(
      schoolID: $schoolID
      schoolYearTransactionType: $schoolYearTransactionType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        allocatorEmail
        schoolStudentID
        transactionType
        moduleType
        schoolYear
        createdAt
        updatedAt
        Allocator {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        SchoolStudent {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentLicenceHistoryBySchoolByYearByAllocator = /* GraphQL */ `
  query GetStudentLicenceHistoryBySchoolByYearByAllocator(
    $schoolID: ID
    $schoolYearAllocatorEmail: ModelWAMStudentLicenceHistoryBySchoolAndSchoolYearAndAllocatorCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWAMStudentLicenceHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentLicenceHistoryBySchoolByYearByAllocator(
      schoolID: $schoolID
      schoolYearAllocatorEmail: $schoolYearAllocatorEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        allocatorEmail
        schoolStudentID
        transactionType
        moduleType
        schoolYear
        createdAt
        updatedAt
        Allocator {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        SchoolStudent {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentLicenceHistoryBySchoolByYearBySchoolStudent = /* GraphQL */ `
  query GetStudentLicenceHistoryBySchoolByYearBySchoolStudent(
    $schoolID: ID
    $schoolYearSchoolStudentID: ModelWAMStudentLicenceHistoryBySchoolAndSchoolYearAndSchoolStudentCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWAMStudentLicenceHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentLicenceHistoryBySchoolByYearBySchoolStudent(
      schoolID: $schoolID
      schoolYearSchoolStudentID: $schoolYearSchoolStudentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        allocatorEmail
        schoolStudentID
        transactionType
        moduleType
        schoolYear
        createdAt
        updatedAt
        Allocator {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        School {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        SchoolStudent {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRubricYearLevel = /* GraphQL */ `
  query GetRubricYearLevel(
    $rubricID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRubricVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRubricYearLevel(
      rubricID: $rubricID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rubricID
        Rubric {
          id
          taskType
          rubricName
          description
          maxScore
          createdAt
          updatedAt
        }
        yearLevelID
        videoLink
        createdAt
        updatedAt
        YearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentEALDProgressMapLevelResponse = /* GraphQL */ `
  query GetStudentEALDProgressMapLevelResponse($id: ID!) {
    getStudentEALDProgressMapLevelResponse(id: $id) {
      id
      studentProgressMapId
      LevelId
      achievement
      comment
      createdAt
      updatedAt
      studentProgressMapItemResponse {
        items {
          id
          studentProgressMapId
          studentProgressMapLevelResponseId
          itemId
          achievement
          createdAt
          updatedAt
        }
        nextToken
      }
      studentProgressMap {
        id
        studentId
        isLocked
        isCarryOver
        previousMapId
        nextMapId
        stage
        currentYearLevelID
        schoolYear
        createdAt
        updatedAt
        studentProgressMapLevelResponses {
          nextToken
        }
        currentYearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listStudentEALDProgressMapLevelResponses = /* GraphQL */ `
  query ListStudentEALDProgressMapLevelResponses(
    $filter: ModelStudentEALDProgressMapLevelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentEALDProgressMapLevelResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentProgressMapId
        LevelId
        achievement
        comment
        createdAt
        updatedAt
        studentProgressMapItemResponse {
          nextToken
        }
        studentProgressMap {
          id
          studentId
          isLocked
          isCarryOver
          previousMapId
          nextMapId
          stage
          currentYearLevelID
          schoolYear
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentProgressMapLevelByProgressMapIdAndLevelId = /* GraphQL */ `
  query GetStudentProgressMapLevelByProgressMapIdAndLevelId(
    $studentProgressMapId: ID
    $LevelId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentEALDProgressMapLevelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentProgressMapLevelByProgressMapIdAndLevelId(
      studentProgressMapId: $studentProgressMapId
      LevelId: $LevelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentProgressMapId
        LevelId
        achievement
        comment
        createdAt
        updatedAt
        studentProgressMapItemResponse {
          nextToken
        }
        studentProgressMap {
          id
          studentId
          isLocked
          isCarryOver
          previousMapId
          nextMapId
          stage
          currentYearLevelID
          schoolYear
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentEALDProgressMapItemResponse = /* GraphQL */ `
  query GetStudentEALDProgressMapItemResponse($id: ID!) {
    getStudentEALDProgressMapItemResponse(id: $id) {
      id
      studentProgressMapId
      studentProgressMapLevelResponseId
      itemId
      achievement
      createdAt
      updatedAt
      studentProgressMapLevelResponse {
        id
        studentProgressMapId
        LevelId
        achievement
        comment
        createdAt
        updatedAt
        studentProgressMapItemResponse {
          nextToken
        }
        studentProgressMap {
          id
          studentId
          isLocked
          isCarryOver
          previousMapId
          nextMapId
          stage
          currentYearLevelID
          schoolYear
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listStudentEALDProgressMapItemResponses = /* GraphQL */ `
  query ListStudentEALDProgressMapItemResponses(
    $filter: ModelStudentEALDProgressMapItemResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentEALDProgressMapItemResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentProgressMapId
        studentProgressMapLevelResponseId
        itemId
        achievement
        createdAt
        updatedAt
        studentProgressMapLevelResponse {
          id
          studentProgressMapId
          LevelId
          achievement
          comment
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentProgressItemsByLevel = /* GraphQL */ `
  query GetStudentProgressItemsByLevel(
    $studentProgressMapLevelResponseId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentEALDProgressMapItemResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentProgressItemsByLevel(
      studentProgressMapLevelResponseId: $studentProgressMapLevelResponseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentProgressMapId
        studentProgressMapLevelResponseId
        itemId
        achievement
        createdAt
        updatedAt
        studentProgressMapLevelResponse {
          id
          studentProgressMapId
          LevelId
          achievement
          comment
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentEALDProgressMap = /* GraphQL */ `
  query GetStudentEALDProgressMap($id: ID!) {
    getStudentEALDProgressMap(id: $id) {
      id
      studentId
      isLocked
      isCarryOver
      previousMapId
      nextMapId
      stage
      currentYearLevelID
      schoolYear
      createdAt
      updatedAt
      studentProgressMapLevelResponses {
        items {
          id
          studentProgressMapId
          LevelId
          achievement
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      currentYearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listStudentEALDProgressMaps = /* GraphQL */ `
  query ListStudentEALDProgressMaps(
    $filter: ModelStudentEALDProgressMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentEALDProgressMaps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        isLocked
        isCarryOver
        previousMapId
        nextMapId
        stage
        currentYearLevelID
        schoolYear
        createdAt
        updatedAt
        studentProgressMapLevelResponses {
          nextToken
        }
        currentYearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentProgressMapBySchoolYearAndStudentId = /* GraphQL */ `
  query GetStudentProgressMapBySchoolYearAndStudentId(
    $studentId: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentEALDProgressMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentProgressMapBySchoolYearAndStudentId(
      studentId: $studentId
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        isLocked
        isCarryOver
        previousMapId
        nextMapId
        stage
        currentYearLevelID
        schoolYear
        createdAt
        updatedAt
        studentProgressMapLevelResponses {
          nextToken
        }
        currentYearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($email: String!) {
    getUser(email: $email) {
      userId
      firstName
      lastName
      wondeID
      MISID
      email
      mobileNumber
      userGroup
      userType
      enabled
      avatar {
        bucket
        region
        key
      }
      userSchoolID
      lastSignIn
      dbType
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUsersBySchool = /* GraphQL */ `
  query GetUsersBySchool(
    $userSchoolID: ID
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersBySchool(
      userSchoolID: $userSchoolID
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUsersByEmail = /* GraphQL */ `
  query GetUsersByEmail(
    $dbType: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByEmail(
      dbType: $dbType
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUsersByFirstName = /* GraphQL */ `
  query GetUsersByFirstName(
    $dbType: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByFirstName(
      dbType: $dbType
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUsersByLastName = /* GraphQL */ `
  query GetUsersByLastName(
    $dbType: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByLastName(
      dbType: $dbType
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserByUserId = /* GraphQL */ `
  query GetUserByUserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTeachersBySchool = /* GraphQL */ `
  query GetTeachersBySchool(
    $userSchoolID: ID
    $userType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeachersBySchool(
      userSchoolID: $userSchoolID
      userType: $userType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserByWondeID = /* GraphQL */ `
  query GetUserByWondeID(
    $wondeID: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByWondeID(
      wondeID: $wondeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserSettingsData = /* GraphQL */ `
  query GetUserSettingsData($id: ID!) {
    getUserSettingsData(id: $id) {
      id
      email
      settingsKey
      settingsData
      createdAt
      updatedAt
    }
  }
`;
export const listUserSettingsDatas = /* GraphQL */ `
  query ListUserSettingsDatas(
    $filter: ModelUserSettingsDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSettingsDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        settingsKey
        settingsData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserSettingsDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserSettings(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        settingsKey
        settingsData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSettingsByKey = /* GraphQL */ `
  query GetUserSettingsByKey(
    $email: String
    $settingsKey: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSettingsDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserSettingsByKey(
      email: $email
      settingsKey: $settingsKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        settingsKey
        settingsData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystemParameter = /* GraphQL */ `
  query GetSystemParameter($key: String!) {
    getSystemParameter(key: $key) {
      id
      key
      paramData
      createdAt
      updatedAt
    }
  }
`;
export const listSystemParameters = /* GraphQL */ `
  query ListSystemParameters(
    $key: String
    $filter: ModelSystemParameterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemParameters(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        key
        paramData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLearningArea = /* GraphQL */ `
  query GetLearningArea($id: ID!) {
    getLearningArea(id: $id) {
      id
      areaName
      colour
      createdAt
      updatedAt
    }
  }
`;
export const listLearningAreas = /* GraphQL */ `
  query ListLearningAreas(
    $filter: ModelLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const learningAreaByName = /* GraphQL */ `
  query LearningAreaByName(
    $areaName: String
    $sortDirection: ModelSortDirection
    $filter: ModelLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    learningAreaByName(
      areaName: $areaName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAcStrand = /* GraphQL */ `
  query GetAcStrand($id: ID!) {
    getAcStrand(id: $id) {
      id
      strandName
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      substrands {
        items {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAcStrands = /* GraphQL */ `
  query ListAcStrands(
    $filter: ModelAcStrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcStrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const strandByName = /* GraphQL */ `
  query StrandByName(
    $strandName: String
    $sortDirection: ModelSortDirection
    $filter: ModelAcStrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    strandByName(
      strandName: $strandName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const strandByLearningArea = /* GraphQL */ `
  query StrandByLearningArea(
    $learningAreaID: ID
    $strandName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcStrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    strandByLearningArea(
      learningAreaID: $learningAreaID
      strandName: $strandName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAcSubstrand = /* GraphQL */ `
  query GetAcSubstrand($id: ID!) {
    getAcSubstrand(id: $id) {
      id
      substrandName
      strandID
      image {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
    }
  }
`;
export const listAcSubstrands = /* GraphQL */ `
  query ListAcSubstrands(
    $filter: ModelAcSubstrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcSubstrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const substrandByName = /* GraphQL */ `
  query SubstrandByName(
    $substrandName: String
    $sortDirection: ModelSortDirection
    $filter: ModelAcSubstrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    substrandByName(
      substrandName: $substrandName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const substrandByStrand = /* GraphQL */ `
  query SubstrandByStrand(
    $strandID: ID
    $substrandName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcSubstrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    substrandByStrand(
      strandID: $strandID
      substrandName: $substrandName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getYearLevel = /* GraphQL */ `
  query GetYearLevel($id: ID!) {
    getYearLevel(id: $id) {
      id
      yearCode
      numericYearCode
      description
      type
      createdAt
      updatedAt
    }
  }
`;
export const listYearLevels = /* GraphQL */ `
  query ListYearLevels(
    $filter: ModelYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYearLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getYearLevelsByCode = /* GraphQL */ `
  query GetYearLevelsByCode(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getYearLevelsByCode(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getYearCode = /* GraphQL */ `
  query GetYearCode(
    $type: String
    $yearCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getYearCode(
      type: $type
      yearCode: $yearCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getYearByDescription = /* GraphQL */ `
  query GetYearByDescription(
    $type: String
    $description: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getYearByDescription(
      type: $type
      description: $description
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAcCode = /* GraphQL */ `
  query GetAcCode($id: ID!) {
    getAcCode(id: $id) {
      id
      acCode
      strandID
      substrandID
      yearLevelID
      learningAreaID
      curriculumEntry
      skill
      criteria
      elaboration
      type
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      substrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAcCodes = /* GraphQL */ `
  query ListAcCodes(
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const acCodeByCode = /* GraphQL */ `
  query AcCodeByCode(
    $acCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acCodeByCode(
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAcCodeSorted = /* GraphQL */ `
  query GetAcCodeSorted(
    $type: String
    $acCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAcCodeSorted(
      type: $type
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const acCodeByStrand = /* GraphQL */ `
  query AcCodeByStrand(
    $strandID: ID
    $acCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acCodeByStrand(
      strandID: $strandID
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const acCodeBySubstrand = /* GraphQL */ `
  query AcCodeBySubstrand(
    $substrandID: ID
    $acCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acCodeBySubstrand(
      substrandID: $substrandID
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const acCodeByYearLevel = /* GraphQL */ `
  query AcCodeByYearLevel(
    $yearLevelID: ID
    $acCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acCodeByYearLevel(
      yearLevelID: $yearLevelID
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const acCodeByCurriculumEntry = /* GraphQL */ `
  query AcCodeByCurriculumEntry(
    $curriculumEntry: String
    $acCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acCodeByCurriculumEntry(
      curriculumEntry: $curriculumEntry
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAcCodeByLearningArea = /* GraphQL */ `
  query GetAcCodeByLearningArea(
    $learningAreaID: ID
    $acCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAcCodeByLearningArea(
      learningAreaID: $learningAreaID
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAcCodeByLearningAreaByYear = /* GraphQL */ `
  query GetAcCodeByLearningAreaByYear(
    $learningAreaID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAcCodeByLearningAreaByYear(
      learningAreaID: $learningAreaID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolNetwork = /* GraphQL */ `
  query GetSchoolNetwork($id: ID!) {
    getSchoolNetwork(id: $id) {
      id
      networkName
      createdAt
      updatedAt
      schools {
        items {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSchoolNetworks = /* GraphQL */ `
  query ListSchoolNetworks(
    $filter: ModelSchoolNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolNetworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        networkName
        createdAt
        updatedAt
        schools {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getNetworkByName = /* GraphQL */ `
  query GetNetworkByName(
    $networkName: String
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNetworkByName(
      networkName: $networkName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        networkName
        createdAt
        updatedAt
        schools {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      dummy
      schoolName
      motto
      studentLoginEnabled
      ealdProgress
      wondeID
      MISID
      networkID
      countryID
      stateID
      logo {
        bucket
        region
        key
      }
      overrideWAMAccess
      overridePAMAccess
      createdAt
      updatedAt
      schoolNetwork {
        id
        networkName
        createdAt
        updatedAt
        schools {
          nextToken
        }
      }
      attributeCategories {
        items {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolIcseas {
        items {
          id
          schoolID
          schoolYear
          icsea
          createdAt
          updatedAt
        }
        nextToken
      }
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
      state {
        id
        name
        stateCode
        countryID
        createdAt
        updatedAt
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
      }
      students {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolByName = /* GraphQL */ `
  query GetSchoolByName(
    $schoolName: String
    $countryID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolByName(
      schoolName: $schoolName
      countryID: $countryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolsByNetwork = /* GraphQL */ `
  query GetSchoolsByNetwork(
    $networkID: ID
    $schoolName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolsByNetwork(
      networkID: $networkID
      schoolName: $schoolName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolsSorted = /* GraphQL */ `
  query GetSchoolsSorted(
    $dummy: String
    $schoolName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolsSorted(
      dummy: $dummy
      schoolName: $schoolName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolByWondeID = /* GraphQL */ `
  query GetSchoolByWondeID(
    $wondeID: String
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolByWondeID(
      wondeID: $wondeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolAttributeCategory = /* GraphQL */ `
  query GetSchoolAttributeCategory($id: ID!) {
    getSchoolAttributeCategory(id: $id) {
      id
      schoolID
      categoryName
      createdAt
      updatedAt
      attributes {
        items {
          id
          schoolID
          categoryID
          attributeName
          attributeType
          defaultValue
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSchoolAttributeCategorys = /* GraphQL */ `
  query ListSchoolAttributeCategorys(
    $filter: ModelSchoolAttributeCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolAttributeCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryName
        createdAt
        updatedAt
        attributes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAttributeCategoriesBySchool = /* GraphQL */ `
  query GetAttributeCategoriesBySchool(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolAttributeCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAttributeCategoriesBySchool(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryName
        createdAt
        updatedAt
        attributes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAttributeCategoryBySchoolByName = /* GraphQL */ `
  query GetAttributeCategoryBySchoolByName(
    $schoolID: ID
    $categoryName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolAttributeCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAttributeCategoryBySchoolByName(
      schoolID: $schoolID
      categoryName: $categoryName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryName
        createdAt
        updatedAt
        attributes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentAttribute = /* GraphQL */ `
  query GetSchoolStudentAttribute($id: ID!) {
    getSchoolStudentAttribute(id: $id) {
      id
      schoolID
      categoryID
      attributeName
      attributeType
      defaultValue
      createdAt
      updatedAt
      category {
        id
        schoolID
        categoryName
        createdAt
        updatedAt
        attributes {
          nextToken
        }
      }
    }
  }
`;
export const listSchoolStudentAttributes = /* GraphQL */ `
  query ListSchoolStudentAttributes(
    $filter: ModelSchoolStudentAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolStudentAttributes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentAttributesBySchool = /* GraphQL */ `
  query GetStudentAttributesBySchool(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentAttributesBySchool(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentAttributesBySchoolCategory = /* GraphQL */ `
  query GetStudentAttributesBySchoolCategory(
    $categoryID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentAttributesBySchoolCategory(
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentAttributeByCategoryByName = /* GraphQL */ `
  query GetStudentAttributeByCategoryByName(
    $categoryID: ID
    $attributeName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentAttributeByCategoryByName(
      categoryID: $categoryID
      attributeName: $attributeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolIcsea = /* GraphQL */ `
  query GetSchoolIcsea($id: ID!) {
    getSchoolIcsea(id: $id) {
      id
      schoolID
      schoolYear
      icsea
      createdAt
      updatedAt
    }
  }
`;
export const listSchoolIcseas = /* GraphQL */ `
  query ListSchoolIcseas(
    $filter: ModelSchoolIcseaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolIcseas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolID
        schoolYear
        icsea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIcseaBySchool = /* GraphQL */ `
  query GetIcseaBySchool(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolIcseaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIcseaBySchool(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        schoolYear
        icsea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      name
      countryCode
      createdAt
      updatedAt
      states {
        items {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCountrys = /* GraphQL */ `
  query ListCountrys(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      id
      name
      stateCode
      countryID
      createdAt
      updatedAt
      country {
        id
        name
        countryCode
        createdAt
        updatedAt
        states {
          nextToken
        }
      }
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        stateCode
        countryID
        createdAt
        updatedAt
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassroom = /* GraphQL */ `
  query GetClassroom($id: ID!) {
    getClassroom(id: $id) {
      id
      classType
      focusGroupType
      className
      schoolYear
      schoolID
      wondeID
      MISID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      yearLevels {
        items {
          id
          classroomID
          schoolID
          yearLevelID
          createdAt
          updatedAt
        }
        nextToken
      }
      teachers {
        items {
          id
          classroomID
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      learningAreas {
        items {
          id
          classroomID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      students {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listClassrooms = /* GraphQL */ `
  query ListClassrooms(
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassrooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClassByYear = /* GraphQL */ `
  query GetClassByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClassesByTypeByYear = /* GraphQL */ `
  query GetClassesByTypeByYear(
    $schoolID: ID
    $classTypeSchoolYear: ModelClassroomByTypeBySchoolYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassesByTypeByYear(
      schoolID: $schoolID
      classTypeSchoolYear: $classTypeSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClassByNameByYear = /* GraphQL */ `
  query GetClassByNameByYear(
    $schoolID: ID
    $schoolYearClassName: ModelClassroomByClassNameBySchoolYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassByNameByYear(
      schoolID: $schoolID
      schoolYearClassName: $schoolYearClassName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClassroomByWondeID = /* GraphQL */ `
  query GetClassroomByWondeID(
    $wondeID: String
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassroomByWondeID(
      wondeID: $wondeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClassroomYearLevel = /* GraphQL */ `
  query GetClassroomYearLevel($id: ID!) {
    getClassroomYearLevel(id: $id) {
      id
      classroomID
      schoolID
      yearLevelID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const listClassroomYearLevels = /* GraphQL */ `
  query ListClassroomYearLevels(
    $filter: ModelClassroomYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassroomYearLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        schoolID
        yearLevelID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassroomYearLevels = /* GraphQL */ `
  query GetClassroomYearLevels(
    $classroomID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassroomYearLevels(
      classroomID: $classroomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        schoolID
        yearLevelID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolClassroomsByYearLevel = /* GraphQL */ `
  query GetSchoolClassroomsByYearLevel(
    $schoolID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolClassroomsByYearLevel(
      schoolID: $schoolID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        schoolID
        yearLevelID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassroomYearLevelByID = /* GraphQL */ `
  query GetClassroomYearLevelByID(
    $classroomID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassroomYearLevelByID(
      classroomID: $classroomID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        schoolID
        yearLevelID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassroomTeacher = /* GraphQL */ `
  query GetClassroomTeacher($id: ID!) {
    getClassroomTeacher(id: $id) {
      id
      classroomID
      email
      createdAt
      updatedAt
      user {
        userId
        firstName
        lastName
        wondeID
        MISID
        email
        mobileNumber
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const listClassroomTeachers = /* GraphQL */ `
  query ListClassroomTeachers(
    $filter: ModelClassroomTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassroomTeachers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        email
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassTeachers = /* GraphQL */ `
  query GetClassTeachers(
    $classroomID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassTeachers(
      classroomID: $classroomID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        email
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTeacherClassrooms = /* GraphQL */ `
  query GetTeacherClassrooms(
    $email: String
    $classroomID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeacherClassrooms(
      email: $email
      classroomID: $classroomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        email
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassroomsByTeacherEmail = /* GraphQL */ `
  query GetClassroomsByTeacherEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassroomsByTeacherEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        email
        createdAt
        updatedAt
        user {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassroomLearningArea = /* GraphQL */ `
  query GetClassroomLearningArea($id: ID!) {
    getClassroomLearningArea(id: $id) {
      id
      classroomID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
    }
  }
`;
export const listClassroomLearningAreas = /* GraphQL */ `
  query ListClassroomLearningAreas(
    $filter: ModelClassroomLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassroomLearningAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassroomStudent = /* GraphQL */ `
  query GetClassroomStudent($id: ID!) {
    getClassroomStudent(id: $id) {
      id
      classroomID
      studentID
      createdAt
      updatedAt
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const listClassroomStudents = /* GraphQL */ `
  query ListClassroomStudents(
    $filter: ModelClassroomStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassroomStudents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        studentID
        createdAt
        updatedAt
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentsByClassroom = /* GraphQL */ `
  query GetStudentsByClassroom(
    $classroomID: ID
    $studentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentsByClassroom(
      classroomID: $classroomID
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        studentID
        createdAt
        updatedAt
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudent = /* GraphQL */ `
  query GetSchoolStudent($id: ID!) {
    getSchoolStudent(id: $id) {
      id
      schoolID
      studentID
      schoolYear
      yearLevelID
      firstName
      lastName
      userId
      hasAccessToWAM
      assignedDateToWAM
      hasAccessToPremiumAssessment
      assignedDateToPremiumAssessment
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const listSchoolStudents = /* GraphQL */ `
  query ListSchoolStudents(
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentBySchool = /* GraphQL */ `
  query GetStudentBySchool(
    $schoolID: ID
    $schoolYearStudentID: ModelSchoolStudentBySchoolCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentBySchool(
      schoolID: $schoolID
      schoolYearStudentID: $schoolYearStudentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentsByYear = /* GraphQL */ `
  query GetSchoolStudentsByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentsByYearAndYearLevel = /* GraphQL */ `
  query GetSchoolStudentsByYearAndYearLevel(
    $schoolID: ID
    $schoolYearYearLevelID: ModelSchoolStudentBySchoolYearAnYearLevelCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndYearLevel(
      schoolID: $schoolID
      schoolYearYearLevelID: $schoolYearYearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentsByYearAndLastName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndLastName(
    $schoolID: ID
    $schoolYearLastName: ModelSchoolStudentBySchoolYearAndLastNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndLastName(
      schoolID: $schoolID
      schoolYearLastName: $schoolYearLastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentsByYearAndFirstName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndFirstName(
    $schoolID: ID
    $schoolYearFirstName: ModelSchoolStudentBySchoolYearAndFirstNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndFirstName(
      schoolID: $schoolID
      schoolYearFirstName: $schoolYearFirstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentsByYearAndLevelAndFirstName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndLevelAndFirstName(
    $schoolID: ID
    $schoolYearYearLevelIDFirstName: ModelSchoolStudentBySchoolYearAndLevelAndFirstNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndLevelAndFirstName(
      schoolID: $schoolID
      schoolYearYearLevelIDFirstName: $schoolYearYearLevelIDFirstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentsByYearAndLevelAndLastName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndLevelAndLastName(
    $schoolID: ID
    $schoolYearYearLevelIDLastName: ModelSchoolStudentBySchoolYearAndLevelAndlastNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndLevelAndLastName(
      schoolID: $schoolID
      schoolYearYearLevelIDLastName: $schoolYearYearLevelIDLastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentSchools = /* GraphQL */ `
  query GetStudentSchools(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentSchools(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolStudentByUserId = /* GraphQL */ `
  query GetSchoolStudentByUserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      firstName
      middleName
      lastName
      wondeID
      MISID
      gender
      birthDate
      photo {
        bucket
        region
        key
      }
      yearLevelID
      createdAt
      updatedAt
      currentYear {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      classrooms {
        items {
          id
          classroomID
          studentID
          createdAt
          updatedAt
        }
        nextToken
      }
      schoolYears {
        items {
          id
          schoolID
          studentID
          schoolYear
          yearLevelID
          firstName
          lastName
          userId
          hasAccessToWAM
          assignedDateToWAM
          hasAccessToPremiumAssessment
          assignedDateToPremiumAssessment
          createdAt
          updatedAt
        }
        nextToken
      }
      studentData {
        items {
          id
          studentID
          schoolYear
          attributeID
          value
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStudentByNameByBirthDate = /* GraphQL */ `
  query GetStudentByNameByBirthDate(
    $firstName: String
    $lastNameBirthDate: ModelStudentByStudentNamesBirthDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentByNameByBirthDate(
      firstName: $firstName
      lastNameBirthDate: $lastNameBirthDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStudentByWondeID = /* GraphQL */ `
  query GetStudentByWondeID(
    $wondeID: String
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentByWondeID(
      wondeID: $wondeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStudentData = /* GraphQL */ `
  query GetStudentData($id: ID!) {
    getStudentData(id: $id) {
      id
      studentID
      schoolYear
      attributeID
      value
      createdAt
      updatedAt
      attribute {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const listStudentDatas = /* GraphQL */ `
  query ListStudentDatas(
    $filter: ModelStudentDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentID
        schoolYear
        attributeID
        value
        createdAt
        updatedAt
        attribute {
          id
          schoolID
          categoryID
          attributeName
          attributeType
          defaultValue
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentDataByYear = /* GraphQL */ `
  query GetStudentDataByYear(
    $studentID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentDataByYear(
      studentID: $studentID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolYear
        attributeID
        value
        createdAt
        updatedAt
        attribute {
          id
          schoolID
          categoryID
          attributeName
          attributeType
          defaultValue
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentDataByAttributeByYear = /* GraphQL */ `
  query GetStudentDataByAttributeByYear(
    $studentID: ID
    $attributeIDSchoolYear: ModelStudentDataByAttributeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentDataByAttributeByYear(
      studentID: $studentID
      attributeIDSchoolYear: $attributeIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolYear
        attributeID
        value
        createdAt
        updatedAt
        attribute {
          id
          schoolID
          categoryID
          attributeName
          attributeType
          defaultValue
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sysType
        message
        recipient
        sender
        read
        readDate
        createdAt
        expiryTime
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsByRecipient = /* GraphQL */ `
  query GetNotificationsByRecipient(
    $recipient: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByRecipient(
      recipient: $recipient
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sysType
        message
        recipient
        sender
        read
        readDate
        createdAt
        expiryTime
        updatedAt
        notificationPayload
      }
      nextToken
    }
  }
`;
export const listNotificationsByDate = /* GraphQL */ `
  query ListNotificationsByDate(
    $sysType: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByDate(
      sysType: $sysType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sysType
        message
        recipient
        sender
        read
        readDate
        createdAt
        expiryTime
        updatedAt
        notificationPayload
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      acCodeID
      yearLevelID
      learningAreaID
      strandID
      substrandID
      question
      questionType
      image {
        bucket
        region
        key
      }
      imageRow
      answerSelectionType
      answerTitle
      messageForCorrectAnswer
      messageForIncorrectAnswer
      explanation
      points
      difficulty
      hasHTMLContent
      isPremium
      bookletID
      booklet {
        id
        name
        bookletContent
        areQuestionsRandomised
        createdAt
        updatedAt
        bookletQuestions {
          nextToken
        }
      }
      orderInBooklet
      isClozeQuestion
      isArchived
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      subStrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      answers {
        items {
          id
          questionID
          text
          correct
          inputFieldWidth
          weight
          score
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        acCodeID
        yearLevelID
        learningAreaID
        strandID
        substrandID
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        hasHTMLContent
        isPremium
        bookletID
        booklet {
          id
          name
          bookletContent
          areQuestionsRandomised
          createdAt
          updatedAt
        }
        orderInBooklet
        isClozeQuestion
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        subStrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionsByAcCode = /* GraphQL */ `
  query GetQuestionsByAcCode(
    $acCodeID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByAcCode(
      acCodeID: $acCodeID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCodeID
        yearLevelID
        learningAreaID
        strandID
        substrandID
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        hasHTMLContent
        isPremium
        bookletID
        booklet {
          id
          name
          bookletContent
          areQuestionsRandomised
          createdAt
          updatedAt
        }
        orderInBooklet
        isClozeQuestion
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        subStrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionsByLearningArea = /* GraphQL */ `
  query GetQuestionsByLearningArea(
    $learningAreaID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByLearningArea(
      learningAreaID: $learningAreaID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCodeID
        yearLevelID
        learningAreaID
        strandID
        substrandID
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        hasHTMLContent
        isPremium
        bookletID
        booklet {
          id
          name
          bookletContent
          areQuestionsRandomised
          createdAt
          updatedAt
        }
        orderInBooklet
        isClozeQuestion
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        subStrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionsByYearLevel = /* GraphQL */ `
  query GetQuestionsByYearLevel(
    $yearLevelID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByYearLevel(
      yearLevelID: $yearLevelID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCodeID
        yearLevelID
        learningAreaID
        strandID
        substrandID
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        hasHTMLContent
        isPremium
        bookletID
        booklet {
          id
          name
          bookletContent
          areQuestionsRandomised
          createdAt
          updatedAt
        }
        orderInBooklet
        isClozeQuestion
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        subStrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionsByStrand = /* GraphQL */ `
  query GetQuestionsByStrand(
    $strandID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByStrand(
      strandID: $strandID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCodeID
        yearLevelID
        learningAreaID
        strandID
        substrandID
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        hasHTMLContent
        isPremium
        bookletID
        booklet {
          id
          name
          bookletContent
          areQuestionsRandomised
          createdAt
          updatedAt
        }
        orderInBooklet
        isClozeQuestion
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        subStrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionsBySubtrand = /* GraphQL */ `
  query GetQuestionsBySubtrand(
    $substrandID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsBySubtrand(
      substrandID: $substrandID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCodeID
        yearLevelID
        learningAreaID
        strandID
        substrandID
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        hasHTMLContent
        isPremium
        bookletID
        booklet {
          id
          name
          bookletContent
          areQuestionsRandomised
          createdAt
          updatedAt
        }
        orderInBooklet
        isClozeQuestion
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        subStrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionsByBooklet = /* GraphQL */ `
  query GetQuestionsByBooklet(
    $bookletID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByBooklet(
      bookletID: $bookletID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCodeID
        yearLevelID
        learningAreaID
        strandID
        substrandID
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        hasHTMLContent
        isPremium
        bookletID
        booklet {
          id
          name
          bookletContent
          areQuestionsRandomised
          createdAt
          updatedAt
        }
        orderInBooklet
        isClozeQuestion
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        subStrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        answers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      questionID
      text
      image {
        bucket
        region
        key
      }
      correct
      inputFieldWidth
      weight
      score
      createdAt
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        text
        image {
          bucket
          region
          key
        }
        correct
        inputFieldWidth
        weight
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubTestType = /* GraphQL */ `
  query GetSubTestType($id: ID!) {
    getSubTestType(id: $id) {
      id
      subTestTypeName
      description
      typeID
      createdAt
      updatedAt
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSubTestTypes = /* GraphQL */ `
  query ListSubTestTypes(
    $filter: ModelSubTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubTestTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subTestTypeName
        description
        typeID
        createdAt
        updatedAt
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSubTestTypeByTestType = /* GraphQL */ `
  query GetSubTestTypeByTestType(
    $typeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubTestTypeByTestType(
      typeID: $typeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subTestTypeName
        description
        typeID
        createdAt
        updatedAt
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSubTestTypeByName = /* GraphQL */ `
  query GetSubTestTypeByName(
    $subTestTypeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelSubTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubTestTypeByName(
      subTestTypeName: $subTestTypeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subTestTypeName
        description
        typeID
        createdAt
        updatedAt
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestType = /* GraphQL */ `
  query GetTestType($id: ID!) {
    getTestType(id: $id) {
      id
      typeName
      description
      testUnitID
      createdAt
      updatedAt
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTestTypes = /* GraphQL */ `
  query ListTestTypes(
    $filter: ModelTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestTypeByName = /* GraphQL */ `
  query GetTestTypeByName(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestTypeByName(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestUnit = /* GraphQL */ `
  query GetTestUnit($id: ID!) {
    getTestUnit(id: $id) {
      id
      unitName
      createdAt
      updatedAt
    }
  }
`;
export const listTestUnits = /* GraphQL */ `
  query ListTestUnits(
    $filter: ModelTestUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        unitName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTestUnitByName = /* GraphQL */ `
  query GetTestUnitByName(
    $unitName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUnitByName(
      unitName: $unitName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      dataType
      typeID
      subTestTypeID
      testName
      testYearLevelId
      year
      nationalMean
      creatorUserID
      sharedByUserID
      sharedWithUserIDs
      isRandomOrder
      isSortedByComplexity
      testUnitID
      schoolID
      thresholdScaledBottom
      thresholdScaledTop
      thresholdScoreBottom
      thresholdScoreTop
      thresholdStanineBottom
      thresholdStanineTop
      shortName
      folderID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      subTestType {
        id
        subTestTypeName
        description
        typeID
        createdAt
        updatedAt
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
      learningAreas {
        items {
          id
          testID
          learningAreaID
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          createdAt
          updatedAt
        }
        nextToken
      }
      testUploads {
        items {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestsByType = /* GraphQL */ `
  query GetTestsByType(
    $typeID: ID
    $testName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByType(
      typeID: $typeID
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestsBySubTestType = /* GraphQL */ `
  query GetTestsBySubTestType(
    $subTestTypeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsBySubTestType(
      subTestTypeID: $subTestTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestByName = /* GraphQL */ `
  query GetTestByName(
    $testName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestByName(
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchTestsByName = /* GraphQL */ `
  query SearchTestsByName(
    $dataType: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchTestsByName(
      dataType: $dataType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestsByYear = /* GraphQL */ `
  query GetTestsByYear(
    $year: Int
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByYear(
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestsByYearLevel = /* GraphQL */ `
  query GetTestsByYearLevel(
    $testYearLevelId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByYearLevel(
      testYearLevelId: $testYearLevelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestsByTypeByYearLevel = /* GraphQL */ `
  query GetTestsByTypeByYearLevel(
    $typeID: ID
    $testYearLevelId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByTypeByYearLevel(
      typeID: $typeID
      testYearLevelId: $testYearLevelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestsSortedByName = /* GraphQL */ `
  query GetTestsSortedByName(
    $dataType: String
    $testName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsSortedByName(
      dataType: $dataType
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestsByFolderID = /* GraphQL */ `
  query GetTestsByFolderID(
    $folderID: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByFolderID(
      folderID: $folderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestLearningArea = /* GraphQL */ `
  query GetTestLearningArea($id: ID!) {
    getTestLearningArea(id: $id) {
      id
      testID
      learningAreaID
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const listTestLearningAreas = /* GraphQL */ `
  query ListTestLearningAreas(
    $filter: ModelTestLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestLearningAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getLearningAreasByTest = /* GraphQL */ `
  query GetLearningAreasByTest(
    $testID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLearningAreasByTest(
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestsByLearingArea = /* GraphQL */ `
  query GetTestsByLearingArea(
    $learningAreaID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByLearingArea(
      learningAreaID: $learningAreaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestQuestion = /* GraphQL */ `
  query GetTestQuestion($id: ID!) {
    getTestQuestion(id: $id) {
      id
      testID
      questionNo
      itemId
      maxScore
      nationalMean
      expectedMean
      correctAnswer
      acCodeID
      difficulty
      answerType
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
    }
  }
`;
export const listTestQuestions = /* GraphQL */ `
  query ListTestQuestions(
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getQuestionsByTest = /* GraphQL */ `
  query GetQuestionsByTest(
    $testID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByTest(
      testID: $testID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getQuestionNoByTest = /* GraphQL */ `
  query GetQuestionNoByTest(
    $testID: ID
    $questionNo: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionNoByTest(
      testID: $testID
      questionNo: $questionNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getQuestionByTestItemId = /* GraphQL */ `
  query GetQuestionByTestItemId(
    $testID: ID
    $itemId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionByTestItemId(
      testID: $testID
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestQuestionByItemId = /* GraphQL */ `
  query GetTestQuestionByItemId(
    $itemId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestQuestionByItemId(
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestUpload = /* GraphQL */ `
  query GetTestUpload($id: ID!) {
    getTestUpload(id: $id) {
      id
      testDate
      testID
      typeID
      yearLevelID
      schoolID
      schoolYear
      resultFile {
        bucket
        region
        key
      }
      creatorUserID
      createdAt
      updatedAt
      creatorUser {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testResults {
        items {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          proficiency
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          studentID
          schoolID
          classRoomID
          isComplete
          testID
          assessmentName
          testResultID
          isStarted
          currentYearLevelID
          schoolYear
          score
          outOf
          questionCount
          assignedDate
          completedDate
          dueDate
          testUploadID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTestUploads = /* GraphQL */ `
  query ListTestUploads(
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadByTest = /* GraphQL */ `
  query GetTestUploadByTest(
    $testID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadByTest(
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadsBySchoolByYear = /* GraphQL */ `
  query GetTestUploadsBySchoolByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadsBySchoolByYearLevel = /* GraphQL */ `
  query GetTestUploadsBySchoolByYearLevel(
    $schoolID: ID
    $schoolYearYearLevelID: ModelTestUploadBySchoolYearLevelCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYearLevel(
      schoolID: $schoolID
      schoolYearYearLevelID: $schoolYearYearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadsByYear = /* GraphQL */ `
  query GetTestUploadsByYear(
    $schoolYear: Int
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsByYear(
      schoolYear: $schoolYear
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadsByTypeByYear = /* GraphQL */ `
  query GetTestUploadsByTypeByYear(
    $typeID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsByTypeByYear(
      typeID: $typeID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadsBySchoolByYearByType = /* GraphQL */ `
  query GetTestUploadsBySchoolByYearByType(
    $schoolID: ID
    $schoolYearTypeID: ModelTestUploadBySchoolByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYearByType(
      schoolID: $schoolID
      schoolYearTypeID: $schoolYearTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadsBySchoolByYearByYearLevelByType = /* GraphQL */ `
  query GetTestUploadsBySchoolByYearByYearLevelByType(
    $schoolID: ID
    $schoolYearYearLevelIDTypeID: ModelTestUploadBySchoolByTypeByYearLevelByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYearByYearLevelByType(
      schoolID: $schoolID
      schoolYearYearLevelIDTypeID: $schoolYearYearLevelIDTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestUploadByTestByDate = /* GraphQL */ `
  query GetTestUploadByTestByDate(
    $schoolID: ID
    $schoolYearTestIDTestDate: ModelTestUploadExistUploadCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadByTestByDate(
      schoolID: $schoolID
      schoolYearTestIDTestDate: $schoolYearTestIDTestDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestResult = /* GraphQL */ `
  query GetTestResult($id: ID!) {
    getTestResult(id: $id) {
      id
      testID
      testDate
      completedDate
      studentID
      typeID
      schoolYear
      yearLevelID
      schoolID
      score
      scale
      stanine
      percentile
      proficiency
      percentage
      nationalBand
      proficiency
      minStandard
      level
      testUploadID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
      learningAreas {
        items {
          id
          testResultID
          studentID
          learningAreaID
          typeID
          schoolYear
          yearLevelID
          schoolID
          testDate
          createdAt
          updatedAt
        }
        nextToken
      }
      resultAnswers {
        items {
          id
          testResultID
          testQuestionID
          studentAnswer
          studentAnswerIds
          proficiency
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTestResults = /* GraphQL */ `
  query ListTestResults(
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        proficiency
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByStudent = /* GraphQL */ `
  query GetTestResultsByStudent(
    $studentID: ID
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByStudent(
      studentID: $studentID
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByTest = /* GraphQL */ `
  query GetTestResultsByTest(
    $testID: ID
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByTest(
      testID: $testID
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        proficiency
        nationalBand
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByTestUpload = /* GraphQL */ `
  query GetTestResultsByTestUpload(
    $testUploadID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByTestUpload(
      testUploadID: $testUploadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        proficiency
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByTestByStudentByTestDate = /* GraphQL */ `
  query GetTestResultsByTestByStudentByTestDate(
    $testID: ID
    $studentIDTestDate: ModelTestResultByTestByStudentByTestDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByTestByStudentByTestDate(
      testID: $testID
      studentIDTestDate: $studentIDTestDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        proficiency
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestResultLearningArea = /* GraphQL */ `
  query GetTestResultLearningArea($id: ID!) {
    getTestResultLearningArea(id: $id) {
      id
      testResultID
      studentID
      learningAreaID
      typeID
      schoolYear
      yearLevelID
      schoolID
      testDate
      createdAt
      updatedAt
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        proficiency
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const listTestResultLearningAreas = /* GraphQL */ `
  query ListTestResultLearningAreas(
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestResultLearningAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        createdAt
        updatedAt
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          proficiency
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsLearningAreaByTestResult = /* GraphQL */ `
  query GetTestResultsLearningAreaByTestResult(
    $testResultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsLearningAreaByTestResult(
      testResultID: $testResultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        createdAt
        updatedAt
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          proficiency
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByStudentByAreaByTypeByYear = /* GraphQL */ `
  query GetTestResultsByStudentByAreaByTypeByYear(
    $studentID: ID
    $learningAreaIDTypeIDSchoolYear: ModelTestResultLearningAreaByStudentByAreaByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByStudentByAreaByTypeByYear(
      studentID: $studentID
      learningAreaIDTypeIDSchoolYear: $learningAreaIDTypeIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        createdAt
        updatedAt
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          proficiency
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByYearLevelByAreaByTypeByYear = /* GraphQL */ `
  query GetTestResultsByYearLevelByAreaByTypeByYear(
    $yearLevelID: ID
    $learningAreaIDTypeIDSchoolYear: ModelTestResultLearningAreaByYearLevelByAreaByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByYearLevelByAreaByTypeByYear(
      yearLevelID: $yearLevelID
      learningAreaIDTypeIDSchoolYear: $learningAreaIDTypeIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        createdAt
        updatedAt
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          proficiency
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByStudentByAreaByYear = /* GraphQL */ `
  query GetTestResultsByStudentByAreaByYear(
    $studentID: ID
    $learningAreaIDSchoolYear: ModelTestResultLearningAreaByStudentByAreaByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByStudentByAreaByYear(
      studentID: $studentID
      learningAreaIDSchoolYear: $learningAreaIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        createdAt
        updatedAt
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          proficiency
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsByYearLevelByAreaByYear = /* GraphQL */ `
  query GetTestResultsByYearLevelByAreaByYear(
    $yearLevelID: ID
    $learningAreaIDSchoolYear: ModelTestResultLearningAreaByYearLevelByAreaByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByYearLevelByAreaByYear(
      yearLevelID: $yearLevelID
      learningAreaIDSchoolYear: $learningAreaIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        createdAt
        updatedAt
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          proficiency
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsBySchoolByYearLevelByAreaByYear = /* GraphQL */ `
  query GetTestResultsBySchoolByYearLevelByAreaByYear(
    $schoolID: ID
    $yearLevelIDLearningAreaIDSchoolYear: ModelTestResultLearningAreaBySchoolByYearLevelByAreaByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsBySchoolByYearLevelByAreaByYear(
      schoolID: $schoolID
      yearLevelIDLearningAreaIDSchoolYear: $yearLevelIDLearningAreaIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        createdAt
        updatedAt
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          proficiency
          nationalBand
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultAnswersGA = /* GraphQL */ `
  query GetTestResultAnswersGA($testResultAnswerID: ID!) {
    getTestResultAnswersGA(testResultAnswerID: $testResultAnswerID) {
      testResultAnswerID
      testUploadID
      testResultID
      studentID
      acCode
      proficiency
      createdAt
      updatedAt
    }
  }
`;
export const listTestResultAnswersGAs = /* GraphQL */ `
  query ListTestResultAnswersGAs(
    $testResultAnswerID: ID
    $filter: ModelTestResultAnswersGAFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTestResultAnswersGAs(
      testResultAnswerID: $testResultAnswerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        testResultAnswerID
        testUploadID
        testResultID
        studentID
        acCode
        proficiency
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTestResultsAnswersGAByTestResult = /* GraphQL */ `
  query GetTestResultsAnswersGAByTestResult(
    $testResultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultAnswersGAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsAnswersGAByTestResult(
      testResultID: $testResultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        testResultAnswerID
        testUploadID
        testResultID
        studentID
        acCode
        proficiency
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTestResultsAnswersGAByTestUpload = /* GraphQL */ `
  query GetTestResultsAnswersGAByTestUpload(
    $testUploadID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultAnswersGAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsAnswersGAByTestUpload(
      testUploadID: $testUploadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        testResultAnswerID
        testUploadID
        testResultID
        studentID
        acCode
        proficiency
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTestResultAnswers = /* GraphQL */ `
  query GetTestResultAnswers($id: ID!) {
    getTestResultAnswers(id: $id) {
      id
      testResultID
      testQuestionID
      studentAnswer
      studentAnswerIds
      proficiency
      createdAt
      updatedAt
      testQuestion {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
      }
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        percentage
        nationalBand
        proficiency
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        resultAnswers {
          nextToken
        }
      }
    }
  }
`;
export const listTestResultAnswerss = /* GraphQL */ `
  query ListTestResultAnswerss(
    $filter: ModelTestResultAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestResultAnswerss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        testQuestionID
        studentAnswer
        studentAnswerIds
        proficiency
        createdAt
        updatedAt
        testQuestion {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          createdAt
          updatedAt
        }
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsAnswersByTestResult = /* GraphQL */ `
  query GetTestResultsAnswersByTestResult(
    $testResultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsAnswersByTestResult(
      testResultID: $testResultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        testQuestionID
        studentAnswer
        studentAnswerIds
        proficiency
        createdAt
        updatedAt
        testQuestion {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          createdAt
          updatedAt
        }
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          minStandard
          proficiency
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestResultsAnswersByTestQuestion = /* GraphQL */ `
  query GetTestResultsAnswersByTestQuestion(
    $testQuestionID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsAnswersByTestQuestion(
      testQuestionID: $testQuestionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        testQuestionID
        studentAnswer
        studentAnswerIds
        proficiency
        createdAt
        updatedAt
        testQuestion {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          createdAt
          updatedAt
        }
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          percentage
          nationalBand
          minStandard
          level
          testUploadID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      studentID
      schoolID
      classRoomID
      isComplete
      testID
      assessmentName
      testResultID
      isStarted
      currentYearLevelID
      schoolYear
      score
      outOf
      questionCount
      assignedDate
      completedDate
      dueDate
      testUploadID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentID
        schoolID
        classRoomID
        isComplete
        testID
        assessmentName
        testResultID
        isStarted
        currentYearLevelID
        schoolYear
        score
        outOf
        questionCount
        assignedDate
        completedDate
        dueDate
        testUploadID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStudentAssessment = /* GraphQL */ `
  query GetStudentAssessment(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentAssessment(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolID
        classRoomID
        isComplete
        testID
        assessmentName
        testResultID
        isStarted
        currentYearLevelID
        schoolYear
        score
        outOf
        questionCount
        assignedDate
        completedDate
        dueDate
        testUploadID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClassRoomAssessments = /* GraphQL */ `
  query GetClassRoomAssessments(
    $classRoomID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassRoomAssessments(
      classRoomID: $classRoomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolID
        classRoomID
        isComplete
        testID
        assessmentName
        testResultID
        isStarted
        currentYearLevelID
        schoolYear
        score
        outOf
        questionCount
        assignedDate
        completedDate
        dueDate
        testUploadID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSchoolAssessments = /* GraphQL */ `
  query GetSchoolAssessments(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolAssessments(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolID
        classRoomID
        isComplete
        testID
        assessmentName
        testResultID
        isStarted
        currentYearLevelID
        schoolYear
        score
        outOf
        questionCount
        assignedDate
        completedDate
        dueDate
        testUploadID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAssessmentsByTestUpload = /* GraphQL */ `
  query GetAssessmentsByTestUpload(
    $testUploadID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAssessmentsByTestUpload(
      testUploadID: $testUploadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolID
        classRoomID
        isComplete
        testID
        assessmentName
        testResultID
        isStarted
        currentYearLevelID
        schoolYear
        score
        outOf
        questionCount
        assignedDate
        completedDate
        dueDate
        testUploadID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHelpGuide = /* GraphQL */ `
  query GetHelpGuide($id: ID!) {
    getHelpGuide(id: $id) {
      id
      title
      description
      link
      status
      order
      createdAt
      updatedAt
    }
  }
`;
export const listHelpGuides = /* GraphQL */ `
  query ListHelpGuides(
    $filter: ModelHelpGuideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpGuides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        title
        description
        resourceLink
        link
        status
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHelpGuidesByTitle = /* GraphQL */ `
  query GetHelpGuidesByTitle(
    $title: String
    $sortDirection: ModelSortDirection
    $filter: ModelHelpGuideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHelpGuidesByTitle(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        link
        status
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      schoolID
      teacherID
      acCode
      isModified
      pagesJSON
      presentUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      originalLessonId
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      lessonPlans {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLessonByAcCode = /* GraphQL */ `
  query GetLessonByAcCode(
    $acCode: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLessonByAcCode(
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolLessons = /* GraphQL */ `
  query GetSchoolLessons(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolLessons(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTeacherLessons = /* GraphQL */ `
  query GetTeacherLessons(
    $teacherID: String
    $sortDirection: ModelSortDirection
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeacherLessons(
      teacherID: $teacherID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTeacherLessonPlan = /* GraphQL */ `
  query GetTeacherLessonPlan($id: ID!) {
    getTeacherLessonPlan(id: $id) {
      id
      lessonPlanName
      lessonID
      studentID
      schoolID
      teacherID
      classRoomID
      acCodeID
      currentYearLevelID
      schoolYear
      editedPlanUrl
      editedPlanNoControlUrl
      deckDetailJSON
      deckUserJSON
      deckContentJSON
      createdAt
      updatedAt
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
      lessons {
        items {
          id
          lessonID
          lessonPlanID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTeacherLessonPlans = /* GraphQL */ `
  query ListTeacherLessonPlans(
    $filter: ModelTeacherLessonPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeacherLessonPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStudentLessonPlans = /* GraphQL */ `
  query GetStudentLessonPlans(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherLessonPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentLessonPlans(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTeacherLessonPlans = /* GraphQL */ `
  query GetTeacherLessonPlans(
    $teacherID: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherLessonPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeacherLessonPlans(
      teacherID: $teacherID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSchoolLessonPlans = /* GraphQL */ `
  query GetSchoolLessonPlans(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherLessonPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolLessonPlans(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLessonPlanNameLessonPlans = /* GraphQL */ `
  query GetLessonPlanNameLessonPlans(
    $lessonPlanName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherLessonPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLessonPlanNameLessonPlans(
      lessonPlanName: $lessonPlanName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTestAssignment = /* GraphQL */ `
  query GetTestAssignment($id: ID!) {
    getTestAssignment(id: $id) {
      id
      testDate
      schoolYear
      status
      userId
      testID
      testUploadID
      schoolID
      yearLevelID
      classroomID
      typeID
      createdAt
      updatedAt
      user {
        items {
          userId
          firstName
          lastName
          wondeID
          MISID
          email
          mobileNumber
          userGroup
          userType
          enabled
          userSchoolID
          lastSignIn
          dbType
          createdAt
          updatedAt
        }
        nextToken
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
        }
        overrideWAMAccess
        overridePAMAccess
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
        }
        attributeCategories {
          nextToken
        }
        schoolIcseas {
          nextToken
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
        }
        students {
          nextToken
        }
      }
      classroom {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        wondeID
        MISID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        yearLevels {
          nextToken
        }
        teachers {
          nextToken
        }
        learningAreas {
          nextToken
        }
        students {
          nextToken
        }
      }
      testType {
        id
        typeName
        description
        testUnitID
        createdAt
        updatedAt
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
      }
      test {
        id
        dataType
        typeID
        subTestTypeID
        testName
        testYearLevelId
        year
        nationalMean
        creatorUserID
        sharedByUserID
        sharedWithUserIDs
        isRandomOrder
        isSortedByComplexity
        testUnitID
        schoolID
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
        shortName
        folderID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        subTestType {
          id
          subTestTypeName
          description
          typeID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        learningAreas {
          nextToken
        }
        questions {
          nextToken
        }
        testUploads {
          nextToken
        }
      }
      testUpload {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        creatorUser {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testResults {
          nextToken
        }
        assessments {
          nextToken
        }
      }
    }
  }
`;
export const listTestAssignments = /* GraphQL */ `
  query ListTestAssignments(
    $filter: ModelTestAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testDate
        schoolYear
        status
        userId
        testID
        testUploadID
        schoolID
        yearLevelID
        classroomID
        typeID
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestAssignmentByTestId = /* GraphQL */ `
  query GetTestAssignmentByTestId(
    $testID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestAssignmentByTestId(
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        schoolYear
        status
        userId
        testID
        testUploadID
        schoolID
        yearLevelID
        classroomID
        typeID
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestAssignmentByTestUploadId = /* GraphQL */ `
  query GetTestAssignmentByTestUploadId(
    $testUploadID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestAssignmentByTestUploadId(
      testUploadID: $testUploadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        schoolYear
        status
        userId
        testID
        testUploadID
        schoolID
        yearLevelID
        classroomID
        typeID
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestAssignmentBySchool = /* GraphQL */ `
  query GetTestAssignmentBySchool(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestAssignmentBySchool(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        schoolYear
        status
        userId
        testID
        testUploadID
        schoolID
        yearLevelID
        classroomID
        typeID
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestAssignmentBySchoolByYear = /* GraphQL */ `
  query GetTestAssignmentBySchoolByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestAssignmentBySchoolByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        schoolYear
        status
        userId
        testID
        testUploadID
        schoolID
        yearLevelID
        classroomID
        typeID
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTestAssignmentByUserBySchoolByYear = /* GraphQL */ `
  query GetTestAssignmentByUserBySchoolByYear(
    $userId: String
    $schoolIDSchoolYear: ModelTestAssignmentByUserBySchoolByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestAssignmentByUserBySchoolByYear(
      userId: $userId
      schoolIDSchoolYear: $schoolIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        schoolYear
        status
        userId
        testID
        testUploadID
        schoolID
        yearLevelID
        classroomID
        typeID
        createdAt
        updatedAt
        user {
          nextToken
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          wondeID
          MISID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
