export const createSchoolWAM = /* GraphQL */ `
  mutation createSchoolWAM(
    $input: CreateSchoolWAMInput!
    $condition: ModelSchoolWAMConditionInput
  ) {
    createSchoolWAM(input: $input, condition: $condition) {
      id
      schoolID
      currentStatus
      totalLicences
      currentLicencesAvailable
      moduleType
      startDate
      endDate
      updatedAt
    }
  }
`;

export const createWAMLicenceRequestHistory = /* GraphQL */ `
  mutation createWAMLicenceRequestHistory(
    $input: CreateWAMLicenceRequestHistoryInput!
    $condition: ModelWAMLicenceRequestHistoryConditionInput
  ) {
    createWAMLicenceRequestHistory(input: $input, condition: $condition) {
      id
      schoolID
      startDate
      endDate
      numberOfLicencesAssigned
      transactionType
      moduleType
      requesterEmail
      schoolYear
      remarks
      isATrial
      wasReversed
      createdAt
      updatedAt
    }
  }
`;

export const updateSchoolWAM = /* GraphQL */ `
  mutation updateSchoolWAM(
    $input: UpdateSchoolWAMInput!
    $condition: ModelSchoolWAMConditionInput
  ) {
    updateSchoolWAM(input: $input, condition: $condition) {
      id
      schoolID
      currentStatus
      totalLicences
      currentLicencesAvailable
      moduleType
      startDate
      endDate
      updatedAt
    }
  }
`;

export const updateWAMLicenceRequestHistory = /* GraphQL */ `
  mutation updateWAMLicenceRequestHistory(
    $input: UpdateWAMLicenceRequestHistoryInput!
    $condition: ModelWAMLicenceRequestHistoryConditionInput
  ) {
    updateWAMLicenceRequestHistory(input: $input, condition: $condition) {
      id
      schoolID
      startDate
      endDate
      numberOfLicencesAssigned
      transactionType
      moduleType
      requesterEmail
      schoolYear
      remarks
      isATrial
      wasReversed
      createdAt
      updatedAt
    }
  }
`;
