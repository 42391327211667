import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderNav,
  CSubheader,
  CBreadcrumbRouter,
  CImg,
} from "@coreui/react";

// routes config
import routes from "../routes";
import {
  TheHeaderDropdown,
  // TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  // TheHeaderDropdownTasks,
  TheHeaderVersionInfo,
} from "./index";
import { showFilterPanelAction } from "../redux/app/ui.actions";
import SchoolHeaderDetail from "../components/school/SchoolHeaderDetail";
import { Button } from "devextreme-react";
import SchoolSelector from "../components/school/SchoolSelector";
import SchoolRolledYearWarning from "./SchoolRolledYearWarning";
import CIcon from "@coreui/icons-react";
import { MessageUsFloatingWidget } from "components/system/MessageUsFloatingWidget";

const TheHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.app.sidebarShow);
  const sidebarMinimize = useSelector((state) => state.app.sidebarMinimize);
  const userGroup = useSelector((state) => state.user.userProfile.userGroup);
  const userType = useSelector((state) => state.user.userProfile.userType);
  const userProfile = useSelector((state) => state.user.userProfile);
  const showFilterButton = useSelector((state) => state.app.showFilterButton);
  const showFilterPanel = useSelector((state) => state.app.showFilterPanel);
  const showPrintButton = useSelector((state) => state.app.showPrintButton);
  const showStartButton = useSelector((state) => state.app.showStartButton);
  const showSaveButton = useSelector((state) => state.app.showSaveButton);
  const showEditButton = useSelector((state) => state.app.showEditButton);
  const showAddToLessonPlan = useSelector(
    (state) => state.app.showAddToLessonPlan
  );
  const editHandler = useSelector((state) => state.app.editHandler);
  const printButtonHandler = useSelector(
    (state) => state.app.printButtonHandler
  );
  const addToPlanHandler = useSelector((state) => state.app.addToPlanHandler);
  const startButtonHandler = useSelector(
    (state) => state.app.startButtonHandler
  );
  const saveButtonHandler = useSelector((state) => state.app.saveButtonHandler);

  const [showSelector, setShowSelector] = useState(false);

  function toggleSidebarMobile() {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({
      type: "set",
      payload: {
        sidebarShow: val,
        sidebarMinimize: !sidebarMinimize,
      },
    });
  }

  function handleFilterClick() {
    dispatch(showFilterPanelAction(!showFilterPanel));
    window.scroll(0, 0);
  }

  function handleSchoolClick() {
    if (userGroup === "Admins") {
      setShowSelector(true);
    }
  }

  const showSubheader =
    showAddToLessonPlan ||
    showEditButton ||
    showFilterButton ||
    showPrintButton ||
    showStartButton ||
    showSaveButton;

  return (
    <>
      <SchoolRolledYearWarning />
      <CHeader className="justify-content-between">
        <CToggler inHeader className="ml-md-3" onClick={toggleSidebarMobile} />

        <CHeaderNav onClick={handleSchoolClick}>
          <SchoolHeaderDetail />
          {showSelector && (
            <SchoolSelector
              showForm={showSelector}
              closeForm={setShowSelector}
            />
          )}
        </CHeaderNav>
        <CHeaderNav className="px-3">
          <CImg
            className="elastikTaglineImage"
            src="/Elastik_Interface_Tagline-08.png"
            style={{
              marginRight: "auto",
              marginLeft: "15px",
              height: "2rem",
            }}
          />
          <div className="userProfile">
            <p className="userInfo" data-cy="header-userprofile-email">
              {userProfile.email}
            </p>
            <p className="userInfo">
              {userProfile.userType === "SystemAdmin"
                ? "System Admin"
                : userProfile.userType}
            </p>
            <TheHeaderVersionInfo
              gitSHA={process.env.REACT_APP_CURRENT_GIT_SHA}
              gitCommitTimestamp={process.env.REACT_APP_GIT_COMMIT_TIMESTAMP}
              userRole={userType}
            />
          </div>
          <CIcon name="cil-info" className="tour-icon" title="Page tour" />
          <TheHeaderDropdownNotif />
          <MessageUsFloatingWidget className="chat-widget-container" />
          <TheHeaderDropdown />
        </CHeaderNav>

        {showSubheader && (
          <CSubheader className="px-3 justify-content-between">
            <CBreadcrumbRouter
              className="border-0 c-subheader-nav m-0 px-0 px-md-3"
              routes={routes.filter((route) => route.group.includes(userGroup))}
            />
            <div className="d-md-down-none mfe-2 c-subheader-nav">
              {showAddToLessonPlan && (
                <Button
                  onClick={addToPlanHandler}
                  icon="plus"
                  text="Add to Lesson Guide"
                  className="mr-1"
                  type="normal"
                  stylingMode="text"
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                />
              )}
              {showEditButton === true &&
                editHandler !== undefined &&
                editHandler !== null && (
                  <Button
                    elementAttr={{ "data-cy": "gap-review-export-pptx" }}
                    onClick={editHandler}
                    icon="edit"
                    text="Export to PowerPoint"
                    className="mr-1"
                    type="normal"
                    stylingMode="text"
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                  />
                )}
              {showFilterButton && (
                <Button
                  onClick={handleFilterClick}
                  icon={showFilterPanel ? "close" : "filter"}
                  text="Filter"
                  className="mr-1"
                  type="normal"
                  stylingMode="text"
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                />
              )}
              {showPrintButton && (
                <Button
                  elementAttr={{ "data-cy": "gap-review-export-pdf" }}
                  onClick={printButtonHandler}
                  icon="print"
                  text="Export to PDF"
                  className="mr-1"
                  type="normal"
                  stylingMode="text"
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                />
              )}
              {showStartButton && (
                <Button
                  icon="clock"
                  text="Start"
                  type="normal"
                  stylingMode="text"
                  onClick={startButtonHandler}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                />
              )}
              {showSaveButton && (
                <Button
                  elementAttr={{ "data-cy": "gap-review-create-test" }}
                  icon="plus"
                  text="Save Gap Review"
                  type="normal"
                  stylingMode="text"
                  onClick={saveButtonHandler}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                />
              )}
            </div>
          </CSubheader>
        )}
      </CHeader>
    </>
  );
};

export default TheHeader;
