import { lazy } from "react";
import { MenuUrls } from "./utils/constants";

const AcCodeDetailView = lazy(() =>
  import("./views/curriculum/AcCodeDetailView")
);
const AcCodesView = lazy(() => import("./views/curriculum/AcCodesView"));
const BookletEditView = lazy(() => import("./views/booklet/BookletEditView"));
const BookletListview = lazy(() => import("./views/booklet/BookletListView"));
const ClassroomsView = lazy(() => import("./views/classroom/ClassroomsView"));
const EALDProgressMapsView = lazy(() =>
  import("./views/curriculum/EALDProgressMapsView")
);
const HelpGuideView = lazy(() => import("./views/helpGuide/HelpGuide"));
const IST = lazy(() => import("./views/studentTrack/StudentTrack"));
const LearningAreaView = lazy(() =>
  import("./views/curriculum/LearningAreaView")
);
const LessonDetail = lazy(() => import("./views/learning/LessonDetail"));
const LessonEdit = lazy(() => import("./views/learning/LessonEdit"));
const LessonGuides = lazy(() => import("./views/learning/LessonGuides"));
const LessonPlanSelection = lazy(() =>
  import("./views/learning/LessonPlanSelection")
);
const LiteratuView = lazy(() => import("./views/literatu/LiteratuView"));
const LoginPage = lazy(() => import("./views/pages/users/LoginPage"));
const LoginPageForSchoolStudent = lazy(() =>
  import("./views/pages/users/LoginPageForSchoolStudent")
);
const MainMenu = lazy(() => import("./views/dashboard/MainMenu"));
const MarksbookView = lazy(() => import("./views/marksBook/MarksbookView"));
const MergedStudentList = lazy(() =>
  import("./views/mergedStudent/MergedStudent")
);
const NotificationsView = lazy(() =>
  import("./views/system/NotificationsView")
);
const Page404 = lazy(() => import("./views/pages/page404/Page404"));
const PamLicenseRequestNew = lazy(() => import("./views/pam/request/Add"));
const PamSchools = lazy(() => import("./views/pam/licencedSchool/List"));
const PremiumAssessmentStudentLicenseManagement = lazy(() =>
  import("views/pam/studentLicenceManagement")
);
const ProficiencyView = lazy(() => import("./views/grades/ProficiencyView"));
const QuestionsView = lazy(() => import("./views/curriculum/QuestionsView"));
const QuizDetail = lazy(() => import("./views/quiz/QuizDetail"));
const QuizDocViewer = lazy(() => import("./views/quiz/QuizDocViewer"));
const QuizResult = lazy(() => import("./views/quiz/QuizResult"));
const QuizView = lazy(() => import("./views/quiz/QuizView"));
const SchoolDetailView = lazy(() => import("./views/schools/SchoolDetailView"));
const SchoolsView = lazy(() => import("./views/schools/SchoolsView"));
const StatusAPI = lazy(() => import("./views/learning/StatusAPI"));
const StrandsView = lazy(() => import("./views/curriculum/StrandsView"));
const StudentDashboard = lazy(() =>
  import("./views/dashboard/StudentDashboard")
);
const StudentLicenseManagementView = lazy(() =>
  import(
    "./views/wam/components/admin/StudentLicenseManagementView/StudentLicenseManagementView"
  )
);
const StudentProfileView = lazy(() =>
  import("./views/schools/StudentProfileView")
);
const SystemAdminView = lazy(() =>
  import("./views/systemAdmin/SystemAdminView")
);
const TestAdminView = lazy(() => import("./views/tests/TestAdminView"));
const TestsView = lazy(() => import("./views/assessments/TestsView"));
const UnderConstruction = lazy(() => import("./views/pages/UnderConstruction"));
const UserDashboard = lazy(() => import("./views/dashboard/UserDashboard"));
const UserPage = lazy(() => import("./views/pages/users/UserPage"));
const UsersPage = lazy(() => import("./views/pages/users/UsersPage"));
const WamLicenseRequestNew = lazy(() =>
  import("./views/wam/wamLicenceRequestHistory/Add")
);
const WAMReportsView = lazy(() => import("./views/wam/WAMReportsView"));
const WamSchools = lazy(() => import("./views/wam/schoolWam/List"));
const WAMView = lazy(() => import("./views/wam/WAMView"));
const YearLevelView = lazy(() => import("./views/curriculum/YearLevelView"));
const AQAResultsView = lazy(() =>
  import("./views/autoMarking/AutoMarkingResultsView")
);
const AQAQuestionDetailView = lazy(() =>
  import("./views/autoMarking/AutoMarkingQuestionDetailView")
);
const AQAAnalytics = lazy(() =>
  import("./views/autoMarking/AutoMarkingAnalytics")
);

// const WamHelp = lazy(() => import("./views/wam/helpGuide/HelpGuide"));

const routes = [
  {
    group: ["AQA", "Pearson", "Cambridge", "PGOnline"],
    path: "/automarkingAnalytics",
    exact: true,
    name: "Analytics",
    component: AQAAnalytics,
  },
  {
    group: ["AQA", "Pearson", "Student", "Cambridge", "PGOnline"],
    path: "/automarkingDetailView",
    exact: true,
    name: "AQA",
    component: AQAQuestionDetailView,
  },
  {
    group: ["AQA", "Pearson", "Cambridge", "PGOnline"],
    path: "/",
    exact: true,
    name: "AQA",
    component: AQAResultsView,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: "/",
    exact: true,
    name: "Home",
    component: UserDashboard,
  },
  {
    group: ["Educator"],
    path: "/",
    exact: true,
    name: "Home",
    component: UserDashboard,
  },
  {
    group: ["Coordinator"],
    path: "/",
    exact: true,
    name: "Home",
    component: MainMenu,
  },
  {
    group: ["Coordinator", "Educator"],
    path: "/mainMenu",
    exact: true,
    name: "Main Menu",
    component: MainMenu,
  },
  {
    group: ["Student"],
    path: "/",
    exact: true,
    name: "Home",
    component: StudentDashboard,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin", "Cambridge", "Student", "PGOnline"],
    path: "/gapanalysis",
    exact: true,
    name: "Gap Analysis",
    component: UserDashboard,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/helpguide",
    exact: true,
    name: "Help Guide",
    component: HelpGuideView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin", "Student"],
    path: "/quizresult",
    exact: true,
    name: "Student Assessment Report",
    component: QuizResult,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/marksbook",
    exact: true,
    name: "Marks Book",
    component: MarksbookView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/progression",
    exact: true,
    name: "Student Tracking",
    component: IST,
  },
  {
    group: ["Admin", "SystemAdmin", "Student"],
    path: "/wam",
    exact: true,
    name: "Writemark",
    component: WAMView,
  },
  {
    group: ["Admin", "SystemAdmin", "Educator", "Coordinator"],
    path: "/wamreports",
    exact: true,
    name: "Reports",
    component: WAMReportsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/proficiency",
    exact: true,
    name: "Proficiency",
    component: ProficiencyView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/lessonedit/:code",
    exact: true,
    name: "Lesson Edit",
    component: LessonEdit,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/api/v1/status_json",
    exact: true,
    name: "Slide Status",
    component: StatusAPI,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/api/v1/decks/:action/:id",
    component: StatusAPI,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/lessonplans/lessondetail/:code",
    exact: true,
    name: "Lesson Detail",
    component: LessonDetail,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/lessonplans",
    // exact: true,
    name: "Lesson Plans",
    component: LessonPlanSelection,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/lesson-guides",
    // exact: true,
    name: "Lesson Guides",
    component: LessonGuides,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/focusgroups",
    exact: true,
    name: "Focus Groups",
    component: ClassroomsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/classrooms",
    exact: true,
    name: "Classrooms",
    component: ClassroomsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/login",
    // exact: true,
    name: "Sign In",
    component: LoginPage,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/schoolLogin/:schoolID",
    name: "Login for school",
    component: LoginPageForSchoolStudent,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/studentLogin/:schoolID/:username",
    name: "Login for school",
    component: LoginPageForSchoolStudent,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/404",
    exact: true,
    name: "Page 404",
    component: Page404,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin", "Student"],
    path: "/UnderConstruction",
    exact: true,
    name: "Coming Soon",
    component: UnderConstruction,
  },
  {
    group: ["Coordinator", "Admin", "SystemAdmin"],
    path: "/users",
    exact: true,
    name: "Users",
    component: UsersPage,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/users/:id",
    exact: true,
    name: "User Details",
    component: UserPage,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: "/dashboard",
    name: "Dashboard",
    component: UserDashboard,
  },
  {
    group: ["Educator"],
    path: "/dashboard",
    name: "Dashboard",
    component: UserDashboard,
  },
  {
    group: ["Coordinator"],
    path: "/dashboard",
    name: "Dashboard",
    component: MainMenu,
  },
  {
    group: ["Student"],
    path: "/dashboard",
    name: "Dashboard",
    component: StudentDashboard,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/profile/:id",
    exact: true,
    name: "Profile",
    component: UserPage,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/assessment",
    exact: true,
    name: "Assessment",
    component: QuizDetail,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "Student", "SystemAdmin"],
    path: "/premade-test",
    exact: true,
    name: "PremadeTest",
    component: TestsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "Student", "SystemAdmin"],
    path: "/assessments",
    exact: true,
    name: "Assessments",
    component: TestsView,
  },

  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/assessmentdoc",
    exact: true,
    name: "Assessment PDF",
    component: QuizDocViewer,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin", "Student"],
    path: "/startassessment",
    exact: true,
    name: "Start Assessment",
    component: QuizView,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: "/yearlevels",
    exact: true,
    name: "Year Levels",
    component: YearLevelView,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: "/learningareas",
    exact: true,
    name: "Learning Areas",
    component: LearningAreaView,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: "/strands",
    exact: true,
    name: "Strands & Substrands",
    component: StrandsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/accodes/:code",
    exact: true,
    name: "Code Details",
    component: AcCodeDetailView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/accodes",
    exact: true,
    name: "AC Codes",
    component: AcCodesView,
  },
  {
    group: ["SystemAdmin"],
    path: "/ealdprogressmaps",
    exact: true,
    name: "EALD Progress Maps",
    component: EALDProgressMapsView,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: "/testadmin",
    exact: true,
    name: "Test Admin",
    component: TestAdminView,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: "/sysadmin",
    exact: true,
    name: "System Admin",
    component: SystemAdminView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/schools/:name",
    exact: true,
    name: "School Detail",
    component: SchoolDetailView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/schools",
    exact: true,
    name: "Schools",
    component: SchoolsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/notifications",
    exact: true,
    name: "Notifications",
    component: NotificationsView,
  },
  {
    group: ["Educator", "Coordinator", "Student", "Admin", "SystemAdmin"],
    path: "/writingassessment",
    exact: true,
    name: "Online Assessment",
    component: LiteratuView,
  },
  {
    group: ["Admin", "SystemAdmin", "Coordinator"],
    path: MenuUrls.WAM_STUDENT_LICENCE_MANAGE,
    exact: true,
    name: "Licence management",
    component: StudentLicenseManagementView,
  },
  {
    group: ["Admin", "SystemAdmin", "Coordinator"],
    path: MenuUrls.PAM_STUDENT_LICENCE_MANAGE,
    exact: true,
    name: "Licence management",
    component: PremiumAssessmentStudentLicenseManagement,
  },
  // {
  //   group: ["Admin", "SystemAdmin", "Coordinator"],
  //   path: "/wam/help",
  //   exact: true,
  //   name: "WAM Help",
  //   component: WamHelp,
  // },
  {
    group: ["Educator", "Coordinator", "Student", "Admin", "SystemAdmin"],
    path: "/wam",
    exact: true,
    name: "Writing Activities",
    component: WAMView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/wamDashboard",
    exact: true,
    name: "WAM Dashboard",
    component: WAMView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/questionbank",
    exact: true,
    name: "Question Bank",
    component: QuestionsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/questionbank2",
    exact: true,
    name: "Question Bank",
    component: QuestionsView,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "/student/:name",
    exact: true,
    name: "Student Profile",
    component: StudentProfileView,
  },
  {
    group: ["Coordinator", "SystemAdmin"],
    path: MenuUrls.WAM_NEWREQUEST,
    exact: true,
    name: "Licence History",
    component: WamLicenseRequestNew,
  },
  {
    group: ["Coordinator", "SystemAdmin"],
    path: MenuUrls.PAM_NEWREQUEST,
    exact: true,
    name: "Licence History",
    component: PamLicenseRequestNew,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: MenuUrls.WAM_SCHOOLS,
    exact: true,
    name: "Licenced Schools",
    component: WamSchools,
  },
  {
    group: ["SystemAdmin"],
    path: "/booklet/edit",
    exact: true,
    name: "Edit Booklet",
    component: BookletEditView,
  },
  {
    group: ["SystemAdmin"],
    path: "/booklet/list",
    exact: true,
    name: "List Booklets",
    component: BookletListview,
  },
  {
    group: ["Admin", "SystemAdmin"],
    path: MenuUrls.PAM_SCHOOLS,
    exact: true,
    name: "Licenced Schools",
    component: PamSchools,
  },
  {
    group: ["SystemAdmin"],
    path: "/mergedstudent",
    exact: true,
    name: "List Merged students",
    component: MergedStudentList,
  },
  {
    group: ["Educator", "Coordinator", "Admin", "SystemAdmin"],
    path: "*",
    component: Page404,
  },
];

export default routes;
