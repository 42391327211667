const REGIONS = {
  Australia: "ap-southeast-2",
  London: "eu-west-2",
};

export const NAMES_FOR_AWS_REGIONS = {
  [REGIONS.Australia]: "Australia",
  [REGIONS.London]: "UK",
};

export const ISO_CODES_FOR_AWS_REGIONS = {
  [REGIONS.Australia]: "au",
  [REGIONS.London]: "gb",
};

export const DOMAINS_FOR_ISO_CODES = {
  development: {
    au: "dev.au.elastik.com",
    gb: "dev.elastik.com",
  },
  production: {
    au: "au.elastik.com",
    gb: "app.elastik.com",
  },
};

export default REGIONS;
