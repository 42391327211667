import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";

import CustomStore from "devextreme/data/custom_store";
import {
  getStudent,
  getStudentBySchoolMinimal,
  getStudentsByClassroom,
  getStudentBySchoolIdOnly,
} from "../../graphql/bpqueries";
import { deleteClassroomStudent, updateStudent } from "../../graphql/mutations";
import {
  createSchoolStudent,
  updateSchoolStudentNames,
  createClassroomStudent,
} from "../../graphql/bpmutations";
import { getFilter } from "../../graphql/utils";
import { getStudentsByClassroomWithEmail } from "./ClassroomStudentDataStoreQuery";
import { getSchoolYear } from "../../utils/helper";
import { createStudentRecord } from "./StudentsAPI";

export const ClassroomStudentDataStore = new CustomStore({
  key: "id",
  loadMode: "processed",
  schoolID: null,
  errorHandler: (error) => {
    console.error("unhandled data store error", error);
  },
  byKey: (key) => {
    const input = { id: key };
    return new Promise((resolve) => {
      API.graphql(graphqlOperation(getStudent, input))
        .then((resp) => {
          resolve(resp.data.getStudent);
        })
        .catch((error) => {
          console.error("bykey error", error);
        });
    });
  },
  // skipcq: JS-0240
  load: function (options) {
    const self = this;
    const input = {
      limit: options.isLoadingAll ? 5000 : options.take ? options.take : 5000,
    };

    if (options.skip && options.skip !== 0) {
      input.nextToken = options.userData.nextToken
        ? options.userData.nextToken
        : null;
    }

    let query = getStudentsByClassroomWithEmail;
    let queryName = "getStudentsByClassroom";
    let filter = null;

    if (options.filter) {
      filter = getFilter(options.filter);
      input.classroomID = filter.classroomID.eq;
    }

    return API.graphql(graphqlOperation(query, input))
      .then(({ data }) => {
        const { items } = data[queryName];
        const dataR = [];

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item) {
            if (item.student?.schoolYears && item.student.schoolYears.items) {
              const duplicatedItem = item;
              const schoolStudent = item.student.schoolYears.items.find(
                (schoolStudentR) =>
                  schoolStudentR.schoolYear === getSchoolYear() &&
                  schoolStudentR.schoolID === self.selectedSchoolID
              );
              if (schoolStudent) {
                delete duplicatedItem.student.schoolYears;

                const email =
                  schoolStudent.user?.items &&
                  schoolStudent.user.items.length > 0
                    ? schoolStudent.user.items[0].email
                    : "";
                dataR.push({ ...duplicatedItem, email });
              } else {
                dataR.push(item);
              }
            } else {
              dataR.push(item);
            }
          }
        }

        options.data = dataR;

        if (options.userData) {
          options.userData.nextToken = data[queryName].nextToken;
        }

        return options;
      })
      .catch((error) => {
        console.error("error getting classroom student data", error);
        return options;
      });
  },
  // skipcq: JS-0045
  insert: async (values) => {
    if (!values.studentID) {
      try {
        const student = await createStudentRecord(
          values.student,
          values.yearLevelID
        );

        // Check if student is in school
        const schoolSearch = {
          schoolID: values.schoolID,
          schoolYearStudentID: {
            eq: {
              studentID: student.id,
              schoolYear: values.schoolYear,
            },
          },
        };

        const schoolResp = await API.graphql(
          graphqlOperation(getStudentBySchoolMinimal, schoolSearch)
        );

        if (schoolResp.data.getStudentBySchool.items.length === 0) {
          const schoolInput = {
            schoolID: values.schoolID,
            studentID: student.id,
            schoolYear: values.schoolYear,
            yearLevelID: values.yearLevelID,
            firstName: values.student.firstName,
            lastName: values.student.lastName,
          };

          await API.graphql(
            graphqlOperation(createSchoolStudent, { input: schoolInput })
          );
        }

        // Check if student is in classroom
        const classSearch = {
          classroomID: values.classroomID,
          studentID: { eq: student.id },
        };

        const classResp = await API.graphql(
          graphqlOperation(getStudentsByClassroom, classSearch)
        );

        if (classResp.data.getStudentsByClassroom.items.length === 0) {
          const classInput = {
            classroomID: values.classroomID,
            studentID: student.id,
          };

          const addClassResp = await API.graphql(
            graphqlOperation(createClassroomStudent, { input: classInput })
          );

          return addClassResp.data.createClassroomStudent;
        } else {
          throw new Error("Student already exists in class");
        }
      } catch (error) {
        console.error(
          "Error creating student and linking to school/classroom",
          error
        );
        throw error.errors[0].message;
      }
    } else {
      if (values.studentID && values.classroomID) {
        const input = {
          classroomID: values.classroomID,
          studentID: values.studentID,
        };

        return new Promise((resolve, reject) => {
          API.graphql(graphqlOperation(createClassroomStudent, { input }))
            .then((resp) => {
              resolve(resp.data.createClassroomStudent);
            })
            .catch((error) => {
              console.error(
                "Error creating classroom student link",
                input,
                error
              );
              reject(error.errors[0].message);
            });
        });
      }
    }
  },
  update: (key, values) => {
    return new Promise((resolve, reject) => {
      values.student.firstName = _.capitalize(_.trim(values.student.firstName));
      values.student.lastName = _.capitalize(_.trim(values.student.lastName));

      values.student.middleName = values.student.middleName
        ? _.capitalize(_.trim(values.student.middleName))
        : "";

      const genderlist = {
        "Non-Binary": "NonBinary",
        "Not Provided": "NotProvided",
      };

      if (
        values.student.gender === "Non-Binary" ||
        values.student.gender === "Not Provided"
      ) {
        values.student.gender = genderlist[values.student.gender];
      }

      const input = {
        id: values.studentID,
        ...values.student,
      };

      API.graphql(graphqlOperation(updateStudent, { input }))
        .then(async () => {
          if (values.student.firstName) {
            try {
              const payloadStudent = {
                schoolID: values.schoolID,
                filter: {
                  schoolYear: { eq: values.schoolYear },
                  studentID: { eq: values.studentID },
                },
              };
              const {
                data: {
                  getStudentBySchool: { items },
                },
              } = await API.graphql(
                graphqlOperation(getStudentBySchoolIdOnly, payloadStudent)
              );
              items.forEach(async (item) => {
                const studentInputApi = {
                  id: item.id,
                  studentID: values.studentID,
                  schoolYear: values.schoolYear,
                  yearLevelID: values.student.yearLevelID,
                  firstName: values.student.firstName,
                  lastName: values.student.lastName,
                };

                await API.graphql(
                  graphqlOperation(updateSchoolStudentNames, {
                    studentInputApi,
                  })
                );
              });
            } catch (error) {
              console.error("error updating school-student records");
              reject(error.errors[0].message);
            }
          }

          resolve();
        })
        .catch((error) => {
          console.error("error updating student", error);
          reject(error.errors[0].message);
        });
    });
  },
  remove: (key) => {
    return new Promise((resolve, reject) => {
      const input = {
        id: key,
      };

      API.graphql(graphqlOperation(deleteClassroomStudent, { input }))
        .then((resp) => {
          resolve();
        })
        .catch((error) => {
          console.error("error removing class student", error);
          reject(error.errors[0].message);
        });
    });
  },
});
