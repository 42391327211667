import React, { useRef, useState } from "react"; 
import { useDispatch, useSelector } from "react-redux"; 
import Form, { 
  GroupItem, 
  SimpleItem, 
  Label, 
  CompareRule, 
  RequiredRule, 
  StringLengthRule, 
  PatternRule, 
} from "devextreme-react/form"; 
import { Button } from "devextreme-react/button"; 
import { CCardGroup, CCard, CCardBody, CFade } from "@coreui/react"; 
 
import PasswordValidation from "../system/PasswordValidation"; 
 
import { 
  completeUserNewPassword, 
  setCompletePassword, 
} from "../../redux/user/user.actions"; 
 
const NewPasswordComponent = () => { 
  const dispatch = useDispatch(); 
  const [newPassword, setNewPassword] = useState(""); 
  const currentUser = useSelector((state) => state.user.currentUser); 
  const formData = useRef({ 
    newPassword: "", 
    confPassword: "", 
  }); 
 
  const passwordOptions = { 
    mode: "password", 
  }; 
 
  const passwordComparison = () => { 
    return formData.current.newPassword; 
  }; 
 
  const handleCancel = () => { 
    dispatch(setCompletePassword(null, false)); 
  }; 
 
  const handleSubmit = async (e) => { 
    e.preventDefault(); 
    dispatch( 
      completeUserNewPassword(currentUser, formData.current.newPassword) 
    ); 
  }; 
 
  function handleChangePassword(e) { 
    if (e && e.dataField === "newPassword") { 
      setNewPassword(e.value); 
    } 
  } 
 
  return ( 
    <CFade> 
      <CCardGroup> 
        <CCard className="not-opaque login-card"> 
          <CCardBody style={{ display: "grid" }}> 
            <h6 className="mb-4 brand-text">Enter new password</h6> 
            <form action="submit" onSubmit={handleSubmit}> 
              <Form 
                formData={formData.current} 
                showColonAfterLabel={false} 
                validationGroup="passwordData" 
                onFieldDataChanged={handleChangePassword} 
              > 
                <GroupItem> 
                  <SimpleItem 
                    dataField="newPassword" 
                    editorType="dxTextBox" 
                    editorOptions={passwordOptions} 
                  > 
                    <Label text="New Password" /> 
                    <RequiredRule message="The new password field is required" /> 
                    <StringLengthRule 
                      min={8} 
                      message="The new password must be minimum 8 characters" 
                    /> 
                    <PatternRule 
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])" 
                      message="The new password must contain Uppercase & Lowercase characters & at least 1 number" 
                    /> 
                  </SimpleItem> 
                  <SimpleItem 
                    dataField="confPassword" 
                    editorType="dxTextBox" 
                    editorOptions={passwordOptions} 
                  > 
                    <Label text="Confirm Password" /> 
                    <RequiredRule message="The confirm password field is required" /> 
                    <CompareRule 
                      message="Passwords do not match" 
                      comparisonTarget={passwordComparison} 
                    /> 
                  </SimpleItem> 
                </GroupItem> 
              </Form> 
              <div> 
                <Button 
                  text="Cancel" 
                  type="normal" 
                  stylingMode="contained" 
                  className="mt-2 mb-2 float-right" 
                  onClick={handleCancel} 
                /> 
                <Button 
                  text="Submit" 
                  type="success" 
                  stylingMode="contained" 
                  useSubmitBehavior 
                  className="m-2 float-right" 
                  validationGroup="passwordData" 
                /> 
              </div> 
            </form> 
            <PasswordValidation password={newPassword} /> 
          </CCardBody> 
        </CCard> 
      </CCardGroup> 
    </CFade> 
  ); 
}; 
 
export default NewPasswordComponent; 
