/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://z5i6wzsuhf.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "ClientEmailServiceAPI",
            "endpoint": "https://uk0r46o53e.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "EmtServiceApi",
            "endpoint": "https://ocx2wkr582.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "FroalaS3SignatureAPI",
            "endpoint": "https://fo9976cyq9.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "GapAnalysisApi",
            "endpoint": "https://x53d1mb00g.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "GraphQLQueries",
            "endpoint": "https://vr138t10ml.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "Handwriting",
            "endpoint": "https://qvprjlklyl.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "ProcessUndefinedStudentHandwritingEssays",
            "endpoint": "https://dohtnw50fg.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "UserLoginsManagerAPI",
            "endpoint": "https://s9far2oubj.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "WAMAPI",
            "endpoint": "https://o96byyhnll.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "bprestapi",
            "endpoint": "https://phrmmep33m.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        },
        {
            "name": "mergeStudents",
            "endpoint": "https://2gdi6yptze.execute-api.eu-west-2.amazonaws.com/ukmaverick",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5nmt67ukdndk7iuksinrp6v22m.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:388a4296-4fea-47a0-bdde-17201b6d0b11",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_6FGOi4Fc0",
    "aws_user_pools_web_client_id": "7pra6a299roqp1h1f4nsv2mot7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bpedsysuserstorage141009-ukmaverick",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
