import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { API } from "aws-amplify";
import { AMPLIFY_AUTH_HUB_CONSTANTS } from './constants';

/**
 * Retrieves and returns the JSON Web Token from the current Amplify Auth session.
 * @returns {Promise<string>} - the JWT from the current Amplify Auth session
 */
export async function getJWTFromAmplifyAuth() {
  const currentAuthSess = await Auth.currentSession();
  const accessToken = currentAuthSess.getAccessToken();
  const jwt = accessToken.getJwtToken();
  return jwt;
}
/**
  * @typedef {Object} APIHTTPResponse - the response object returned by one of the functions in the
 *  {@link API | Amplify API library} (possibly via a {@link APIHTTPFunction} ), containing a `status` and `data`
 * @property {number} status - the HTTP status code of the response
 * @property {any} data - the JSON data contained in the response
 */
/**
 * Just a helper that calls the Amplify API HTTP function, depending on the HTTP method provided.
 * @param {string} apiName - The api name of the request
 * @param {string} path - The path of the request
 * @param {Object} [init] - Request extra params
 * @param {import("axios").Method} httpMethod - the HTTP method to use for the Admin API call
 * @return {Promise<APIHTTPResponse>} - A promise that resolves to an object with response status and JSON data, if successful.
 * @throws {Error} if the provided HTTP method is not supported
 */
export async function callAPIWithHTTPMethod(apiName, path, init, httpMethod) {
  const httpMethodLower = httpMethod.toLowerCase();
  /** @type {APIHTTPResponse} */
  let apiResponse = null;
  switch (httpMethodLower) {
    case "post":
      apiResponse = await API.post(apiName, path, init);
      break;
    case "get":
      apiResponse = await API.get(apiName, path, init);
      break;
    case "put":
      apiResponse = await API.put(apiName, path, init);
      break;
    case "patch":
      apiResponse = await API.patch(apiName, path, init);
      break;
    case "delete":
      apiResponse = await API.del(apiName, path, init);
      break;
    default:
      throw new Error(`Invalid httpMethod: ${httpMethod}`);
  }
  return apiResponse;
}

/**
 * @typedef {import ('@aws-amplify/ui-components').AuthState } AuthState
 */
/**
 * This can be used to notify the Amplify Auth UI components of auth events that occur elsewhere (e.g. in our redux saga), to
 * ensure the UI state of any amplify auth react components is in sync.
 * @param {AuthState} nextAuthState - the next auth state
 * @param {[object]} data - the payload
 */
export const dispatchAuthStateChangeEvent = (
  nextAuthState,
  data
) => {
  Hub.dispatch(AMPLIFY_AUTH_HUB_CONSTANTS.UI_AUTH_CHANNEL, {
    event: AMPLIFY_AUTH_HUB_CONSTANTS.AUTH_STATE_CHANGE_EVENT,
    message: nextAuthState,
    data,
  });
};
