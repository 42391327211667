export const getStudentsByClassroomWithEmail = /* GraphQL */ `
  query GetStudentsByClassroom(
    $classroomID: ID
    $studentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentsByClassroom(
      classroomID: $classroomID
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        studentID
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          yearLevelID
          schoolYears {
            items {
              schoolYear
              schoolID
              user {
                items {
                  email
                }
                nextToken
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
