/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onNotificationByRecipient = /* GraphQL */ `
  subscription OnNotificationByRecipient($recipient: String!) {
    onNotificationByRecipient(recipient: $recipient) {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
      notificationPayload
    }
  }
`;
export const onNotificationByRecipientUpdate = /* GraphQL */ `
  subscription OnNotificationByRecipientUpdate($recipient: String!) {
    onNotificationByRecipientUpdate(recipient: $recipient) {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
      notificationPayload
    }
  }
`;
export const onNotificationByRecipientDelete = /* GraphQL */ `
  subscription OnNotificationByRecipientDelete($recipient: String!) {
    onNotificationByRecipientDelete(recipient: $recipient) {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
      notificationPayload
    }
  }
`;
export const onCreateStudentData = /* GraphQL */ `
  subscription OnCreateStudentData {
    onCreateStudentData {
      id
      studentID
      schoolYear
      attributeID
      value
      createdAt
      updatedAt
      attribute {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateStudentData = /* GraphQL */ `
  subscription OnUpdateStudentData {
    onUpdateStudentData {
      id
      studentID
      schoolYear
      attributeID
      value
      createdAt
      updatedAt
      attribute {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteStudentData = /* GraphQL */ `
  subscription OnDeleteStudentData {
    onDeleteStudentData {
      id
      studentID
      schoolYear
      attributeID
      value
      createdAt
      updatedAt
      attribute {
        id
        schoolID
        categoryID
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
        category {
          id
          schoolID
          categoryName
          createdAt
          updatedAt
        }
      }
      student {
        id
        firstName
        middleName
        lastName
        wondeID
        MISID
        gender
        birthDate
        photo {
          bucket
          region
          key
        }
        yearLevelID
        createdAt
        updatedAt
        currentYear {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        classrooms {
          nextToken
        }
        schoolYears {
          nextToken
        }
        studentData {
          nextToken
        }
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      type
      sysType
      message
      recipient
      sender
      read
      readDate
      createdAt
      expiryTime
      updatedAt
    }
  }
`;
export const onCreateLessonTeacherLessonPlan = /* GraphQL */ `
  subscription OnCreateLessonTeacherLessonPlan {
    onCreateLessonTeacherLessonPlan {
      id
      lessonID
      lessonPlanID
      createdAt
      updatedAt
      lesson {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      lessonPlan {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateLessonTeacherLessonPlan = /* GraphQL */ `
  subscription OnUpdateLessonTeacherLessonPlan {
    onUpdateLessonTeacherLessonPlan {
      id
      lessonID
      lessonPlanID
      createdAt
      updatedAt
      lesson {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      lessonPlan {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteLessonTeacherLessonPlan = /* GraphQL */ `
  subscription OnDeleteLessonTeacherLessonPlan {
    onDeleteLessonTeacherLessonPlan {
      id
      lessonID
      lessonPlanID
      createdAt
      updatedAt
      lesson {
        id
        schoolID
        teacherID
        acCode
        isModified
        pagesJSON
        presentUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        originalLessonId
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        lessonPlans {
          nextToken
        }
      }
      lessonPlan {
        id
        lessonPlanName
        lessonID
        studentID
        schoolID
        teacherID
        classRoomID
        acCodeID
        currentYearLevelID
        schoolYear
        editedPlanUrl
        editedPlanNoControlUrl
        deckDetailJSON
        deckUserJSON
        deckContentJSON
        createdAt
        updatedAt
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          curriculumEntry
          skill
          criteria
          elaboration
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        lessons {
          nextToken
        }
      }
    }
  }
`;
