import { API, graphqlOperation } from "aws-amplify";
import {
  getWAMDataBySchoolByModuleType,
  listSchoolWAMs,
  listWAMLicenceRequestHistorys,
  listWAMStudentLicenceHistorys,
} from "./queries";
import { getStudentsByClassroom } from "../../../../views/wam/queries";
import { getSchoolStudentsByYear } from "../../../../graphql/bpqueries";
import {
  createSchoolWAM,
  createWAMLicenceRequestHistory,
  updateSchoolWAM,
  updateWAMLicenceRequestHistory,
} from "./mutations";
import { getSchool } from "../../../../graphql/queries";
import { getSchoolYear } from "../../../../utils/helper";
import { LicenceModuleType } from "../../../../utils/constants";
import { fetchAllNextTokenData } from "utils/AppsyncCommonMethods";
const _ = require("lodash");

export const fetchWAMDataBySchool = async (schoolID) => {
  let input = {
    schoolID,
    moduleType: { eq: LicenceModuleType.WRITING_ASSESSMENT },
  };
  return await API.graphql(
    graphqlOperation(getWAMDataBySchoolByModuleType, input)
  );
};

export const fetchSchoolWAMList = async (searchValue) => {
  try {
    const input = {
      filter: {
        moduleType: { ne: LicenceModuleType.PREMIUM_ASSESSMENT },
      },
    };
    const query = listSchoolWAMs;
    const queryName = "listSchoolWAMs";
    const items = await fetchAllNextTokenData(queryName, query, input);

    // To avoid nullable error unless we change in schema, we are re-quering school record
    let schoolWamsWithSchool = await Promise.all(
      await items.map(async (item) => {
        const responseSchool = await API.graphql(
          graphqlOperation(getSchool, { id: item.schoolID })
        );
        if (responseSchool.data.getSchool) {
          let schoolRecord = responseSchool.data.getSchool;
          item.School = schoolRecord;
          item.schoolName = schoolRecord.schoolName;
          item.overrideWAMAccess = schoolRecord.overrideWAMAccess;
          item.overridePAMAccess = schoolRecord.overridePAMAccess;
          // Mostly used from selectbox i.e. <SchoolWamSelectBox />
          if (searchValue) {
            let schoolName = item.School.schoolName;
            let found = schoolName.includes(searchValue);
            if (found) {
              return item;
            }
          } else {
            return item;
          }
        }
      })
    );

    schoolWamsWithSchool = _.flatten(schoolWamsWithSchool);

    // Remove undefined, null rows
    schoolWamsWithSchool = _.compact(schoolWamsWithSchool);

    // Sort by school name
    schoolWamsWithSchool = _.orderBy(
      schoolWamsWithSchool,
      ["schoolName"],
      ["asc"]
    );

    return schoolWamsWithSchool;
  } catch (error) {
    console.log("error on getting students licence histories", error);
  }
};

export const fetchSchoolStudentsWithAccessToWAM = async (schoolID) => {
  try {
    const input = {
      limit: 1000,
      schoolID,
      schoolYear: {
        eq: getSchoolYear(),
      },
      filter: {
        hasAccessToWAM: { eq: true },
      },
    };
    const response = await API.graphql(
      graphqlOperation(getSchoolStudentsByYear, input)
    );
    return response.data.getSchoolStudentsByYear.items;
  } catch (error) {
    console.log("error on getting school students with WAM", error);
  }
};

export const createSchoolWAMRecord = async (input) => {
  try {
    const response = await API.graphql(
      graphqlOperation(createSchoolWAM, { input })
    );
    return response.data.createSchoolWAM;
  } catch (error) {
    console.log("error on creating School WAM", error);
  }
};

export const createWAMLicenceRequestHistoryRecord = async (input) => {
  try {
    const response = await API.graphql(
      graphqlOperation(createWAMLicenceRequestHistory, { input })
    );
    return response.data.createWAMLicenceRequestHistory;
  } catch (error) {
    console.log("error on creating WAM license request", error);
  }
};

export const updateSchoolWAMRecord = async (schoolWAM) => {
  try {
    delete schoolWAM.createdAt;
    delete schoolWAM.updatedAt;
    delete schoolWAM.School;

    const response = await API.graphql(
      graphqlOperation(updateSchoolWAM, { input: schoolWAM })
    );
    return response.data.updateSchoolWAM;
  } catch (error) {
    console.log("error on updating school WAM", error);
  }
};

export const fetchWAMLicenseRequestHistorys = async (input) => {
  try {
    const response = await API.graphql(
      graphqlOperation(listWAMLicenceRequestHistorys, input)
    );
    return response.data.listWAMLicenceRequestHistorys.items;
  } catch (error) {
    console.log("error on getting school students with WAM", error);
  }
};
export const fetchWAMStudentLicenseHistorys = async (input) => {
  try {
    const response = await API.graphql(
      graphqlOperation(listWAMStudentLicenceHistorys, input)
    );
    return response.data.listWAMStudentLicenceHistorys.items;
  } catch (error) {
    console.log("error on getting students licence histories", error);
  }
};

export const updateWAMLicenceRequestHistoryRecord = async (input) => {
  try {
    delete input.createdAt;
    delete input.updatedAt;

    const response = await API.graphql(
      graphqlOperation(updateWAMLicenceRequestHistory, { input })
    );
    return response.data.updateWAMLicenceRequestHistory;
  } catch (error) {
    console.log("error on updating WAM licence request history", error);
  }
};

export const fetchSchoolStudentWithAccessToWAM = async (
  schoolID,
  schoolYear,
  studentID
) => {
  try {
    const input = {
      limit: 1000,
      schoolID,
      schoolYear: {
        eq: schoolYear,
      },
      filter: {
        studentID: { eq: studentID },
        hasAccessToWAM: { eq: true },
      },
    };
    const query = getSchoolStudentsByYear;
    const queryName = "getSchoolStudentsByYear";

    return await fetchAllNextTokenData(queryName, query, input);
  } catch (error) {
    console.log("error on getting school students with WAM", error);
  }
};

export const getStudentsWithWamByClassroomId = async (classroomId, assignedStudentIds) => {
  const response = await API.graphql(
    graphqlOperation(getStudentsByClassroom, {
      classroomID: classroomId,
    })
  );

  let resultItems = response.data.getStudentsByClassroom.items;
  if (resultItems.length === 0) {
    return [];
  }

  let promises = resultItems.map(async (item) => {
    let { firstName, middleName, lastName } = item.student;
    let name = `${firstName} ${lastName}`;
    let fullName = `${firstName}${
      middleName ? ` ${middleName}` : ""
    } ${lastName}`;

    let schoolStudentsWithWAM = await fetchSchoolStudentWithAccessToWAM(
      item.classroom.schoolID,
      item.classroom.schoolYear,
      item.student.id
    );

    if (schoolStudentsWithWAM.length) {
      return { ...item.student, name, fullName, hasAccessToWAM: true };
    } else {
      return { ...item.student, name, fullName, hasAccessToWAM: false };
    }
  });
  let studentRecords = await Promise.all(promises);

  // Remove undefined, null rows
  studentRecords = _.compact(studentRecords);
  studentRecords = _.uniqBy(studentRecords, "id");
  studentRecords = _.orderBy(studentRecords, "name", "asc");

  if(assignedStudentIds && assignedStudentIds.length>0){
    studentRecords = studentRecords.filter(student=> !assignedStudentIds.includes(student.id))
  }

  return studentRecords;
};
