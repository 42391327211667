import { API, Storage, graphqlOperation } from "aws-amplify";
import {
  getClassesByTypeByYear,
  getClassroomStudentLinks,
  getSchoolStudentsByYear,
  listRubricVideos,
  getHandwritingLogByActivityID,
  getStudentHandwritingLogByLogParent,
} from "../../graphql/bpqueries";
import { getStudent, getSystemParameter } from "../../graphql/queries";
import {
  getActivityByID,
  getStudentHandwritingLog,
  listActivitys,
} from "../wam/queries";
import { getAllEssays } from "./emtService";
import _ from "lodash";
import { getSchoolYear } from "../../utils/helper";
import { getClassroomsByTeacherEmail } from "./queries";
import { fetchAllNextTokenData } from "utils/AppsyncCommonMethods";
import { JPG_HEADER, PNG_HEADER } from "utils/constants";
import { deleteActivity, updateActivity } from "graphql/mutations";
import notify from "devextreme/ui/notify";
import {
  deleteHandwritingLog,
  deleteStudentHandwritingLog,
} from "graphql/bpmutations";
const dayjs = require("dayjs");

export const getHandwritingUploadLog = async (activityID) => {
  try {
    const input = {
      activityID: activityID,
    };
    const data = await fetchAllNextTokenData(
      "getHandwritingLogByActivityID",
      getHandwritingLogByActivityID,
      input
    );
    if (data && data.length > 0) {
      return data.filter((log) => log.fileUrl);
    }
    return [];
  } catch (error) {
    console.log("error on getting writing upload logs", error);
  }
};

export const getAllHandwritingUploadLogByActivity = async (activityID) => {
  try {
    const input = {
      activityID: activityID,
    };
    const data = await fetchAllNextTokenData(
      "getHandwritingLogByActivityID",
      getHandwritingLogByActivityID,
      input
    );
    return data;
  } catch (error) {
    console.log("error on getting writing upload logs", error);
  }
};

export const fetchSchoolStudentWithAccessToWAM = async (
  studentID,
  schoolID
) => {
  try {
    const input = {
      schoolID,
      schoolYear: {
        eq: getSchoolYear(),
      },
      filter: {
        studentID: { eq: studentID },
        hasAccessToWAM: { eq: true },
      },
      limit: 1000,
    };
    const query = getSchoolStudentsByYear;
    const queryName = "getSchoolStudentsByYear";

    return await fetchAllNextTokenData(queryName, query, input);
  } catch (error) {
    console.log(
      "error on getting classroom students",
      studentID,
      schoolID,
      error
    );
  }
};

export const fetchClassroomStudentsWithWam = async (
  classroomStudents,
  schoolID
) => {
  try {
    let classroomStudentWithWam = await Promise.all(
      await classroomStudents.map(async (item) => {
        if (typeof item !== "undefined" && item && item.studentID) {
          let studentWithWAM = await fetchSchoolStudentWithAccessToWAM(
            item.studentID,
            schoolID
          );

          if (studentWithWAM?.length) {
            let row = {};
            row.hasAccessToWAM = studentWithWAM[0].hasAccessToWAM;
            row.studentID = studentWithWAM[0].studentID;
            row.schoolID = studentWithWAM[0].schoolID;
            return row;
          }
        }
      })
    );

    classroomStudentWithWam = _.flatten(classroomStudentWithWam);

    // Remove undefined, null rows
    classroomStudentWithWam = _.compact(classroomStudentWithWam);

    return classroomStudentWithWam;
  } catch (error) {
    console.log("error on getting classroom students", error);
  }
};

export const fetchClassroomStudents = async (classroomID) => {
  try {
    const input = {
      classroomID,
      limit: 1000,
    };

    const response = await API.graphql(
      graphqlOperation(getClassroomStudentLinks, input)
    );

    let results = response.data.getStudentsByClassroom.items;

    results = _.uniqBy(results, "studentID");

    return results;
  } catch (error) {
    console.log("error on getting classroom students", error);
  }
};

export const fetchClassesByTypeByYear = async (schoolID, schoolYear) => {
  try {
    const input = {
      schoolID: schoolID,
      classTypeSchoolYear: {
        eq: {
          classType: "Classroom",
          schoolYear,
        },
      },
      limit: 1000,
    };

    const response = await API.graphql(
      graphqlOperation(getClassesByTypeByYear, input)
    );

    const items = response.data.getClassesByTypeByYear.items;
    let classrooms = await Promise.all(
      await items.map(async (item) => {
        let row = {};
        let classroomStudentList = await fetchClassroomStudents(item.id);

        // let classroomStudentWithWamList = await fetchClassroomStudentsWithWam(
        //   classroomStudentList,
        //   schoolID
        // );
        let yearLevels = [];
        if (item?.yearLevels?.items.length > 0) {
          item.yearLevels.items.forEach((list) => {
            yearLevels.push(list.yearLevel.description);
          });
        }

        row.id = item.id;
        row.schoolID = item.schoolID;
        row.classRoom = item.className;
        row.studentsCount = classroomStudentList.length;
        row.classYear = yearLevels.join(", ");

        return row;
      })
    );

    classrooms = _.flatten(classrooms);

    // Remove undefined, null rows
    classrooms = _.compact(classrooms);

    return classrooms;
  } catch (error) {
    console.log("error on getting classes", error);
  }
};

export const getStudentHandwritingLogByLogID = async (handwritingLogID) => {
  const input = {
    uploadID: handwritingLogID,
  };

  const data = await fetchAllNextTokenData(
    "getStudentHandwritingLogByLogParent",
    getStudentHandwritingLogByLogParent,
    input
  );
  return data;
};

export const getHandwritingLogByStudent = async (studentID, activityID) => {
  const studentsHandwritingLogs = await fetchAllNextTokenData(
    "getStudentHandwritingLogByStudentID",
    getStudentHandwritingLog,
    { studentID: studentID }
  );
  const studentsHandwritingLogsForCurrentActivity =
    studentsHandwritingLogs.filter(
      (handwritingLog) =>
        handwritingLog?.handwritingUpload?.activityID === activityID
    );

  return studentsHandwritingLogsForCurrentActivity;
};

export const deleteActivityRecord = async (selectedActivity) => {
  try {
    const handwritingLogRecords = await getAllHandwritingUploadLogByActivity(
      selectedActivity.id
    );

    let studentHandwritingLogRecords = [];

    for (let index = 0; index < handwritingLogRecords.length; index++) {
      const handwritingLog = handwritingLogRecords[index];

      const studentHandwritingLog = await getStudentHandwritingLogByLogID(
        handwritingLog.id
      );

      studentHandwritingLogRecords = [
        ...studentHandwritingLogRecords,
        ...studentHandwritingLog,
      ];
    }

    // deleting studentHandwritingLogRecords
    for (let index = 0; index < studentHandwritingLogRecords.length; index++) {
      const studentHandwritingLogRecord = studentHandwritingLogRecords[index];
      await API.graphql(
        graphqlOperation(deleteStudentHandwritingLog, {
          input: { id: studentHandwritingLogRecord.id },
        })
      );
    }

    // deleting handwritingLogRecords
    for (let index = 0; index < handwritingLogRecords.length; index++) {
      const handwritingLogRecord = handwritingLogRecords[index];
      await API.graphql(
        graphqlOperation(deleteHandwritingLog, {
          input: { id: handwritingLogRecord.id },
        })
      );
    }

    // deleting activity
    await API.graphql(
      graphqlOperation(deleteActivity, {
        input: { id: selectedActivity.id },
      })
    );
  } catch (error) {
    console.log(`There was an error while deleting the activity, ${error}`);
  }
};

export const fetchActivityByID = async (activityID) => {
  const response = await API.graphql(
    graphqlOperation(getActivityByID, {
      id: activityID,
    })
  );

  if (response?.data.getActivity) {
    const activity = response.data.getActivity;
    const updatedSelectedActivity = {
      id: activity.id,
      activityName: activity.prompt.promptName,
      creatorName: activity.user
        ? `${activity.user.firstName} ${activity.user.lastName}`
        : "",
      essayType: activity.prompt.taskType,
      assignedStudentIds: activity.AssignedStudents,
      assignedStudentsCount:
        activity.AssignedStudents && activity.AssignedStudents.length > 0
          ? activity.AssignedStudents.length
          : 0,
      dateCreated: dayjs(activity.createdAt).format("DD MMM YYYY HH:mm"),
      promptID: activity.promptID,
      promptName: activity.prompt?.promptName,
    };

    return updatedSelectedActivity;
  }
  return null;
};

export const updateActivityStudents = async (
  activityID,
  AssignedStudents,
  sucessMessage,
  errorMessage
) => {
  const input = {
    id: activityID,
    AssignedStudents,
  };
  try {
    await API.graphql(graphqlOperation(updateActivity, { input }));

    notify({
      message: sucessMessage,
      type: "Success",
      displayTime: 3000,
      position: "bottom",
    });
  } catch (error) {
    console.error(
      `There was an error while trying to update the activity ${error}`
    );
    notify({
      message: errorMessage,
      type: "error",
      displayTime: 3000,
      position: "bottom",
    });
  }
};

export const getAllActivityStudents = async (
  selectedActivity,
  allEssaysResponse
) => {
  if (!selectedActivity) return [];
  let essays =
    typeof allEssaysResponse !== "undefined" ? allEssaysResponse.essays : [];

  const result = await Promise.all(
    await selectedActivity.assignedStudentIds?.map(async (studentId) => {
      let studentResponse = await API.graphql(
        graphqlOperation(getStudent, { id: studentId })
      );
      const studentsHandwritingLogsForCurrentActivity =
        await getHandwritingLogByStudent(studentId, selectedActivity.id);

      let student = studentResponse.data.getStudent;

      let studentEssay = _.find(essays, function (o) {
        return (
          o.studentId === studentId && o.activityId === selectedActivity.id
        );
      });
      let essayId = essays && studentEssay ? studentEssay.essayId : "";
      let essayWordCount = essays && studentEssay ? studentEssay.wordCount : 0;
      let essayStatusValue =
        essays && studentEssay ? studentEssay.status : "BLANK";

      let row = {
        assignedOn: selectedActivity.dateCreated,
        essayId,
        statusOfEssay: essayStatusValue,
        studentName: `${student?.firstName} ${student?.lastName}`,
        birthDate: student.birthDate,
        wordsWritten: essayWordCount,
        studentID: student?.id,
        studentsHandwritingLogsForCurrentActivity:
          studentsHandwritingLogsForCurrentActivity &&
          studentsHandwritingLogsForCurrentActivity.length === 1
            ? studentsHandwritingLogsForCurrentActivity[0]
            : {},
      };

      return row;
    })
  );
  return result;
};

export const getHandwritingStudentFileData = async (
  studentsHandwritingLogsForCurrentActivity
) => {
  if (studentsHandwritingLogsForCurrentActivity?.splitFileS3URL) {
    let fileData = {};
    try {
      const s3FileUrl =
        studentsHandwritingLogsForCurrentActivity.splitFileS3URL;
      const res = await Storage.get(
        studentsHandwritingLogsForCurrentActivity.splitFileS3URL,
        { download: true }
      );
      const resArrayBuffer = await res.Body.arrayBuffer();
      const uint8Array = new Uint8Array(resArrayBuffer);

      if (s3FileUrl.includes("pdf")) {
        var pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const file = window.URL.createObjectURL(pdfBlob);
        fileData = {
          data: file,
          type: "pdf",
        };
      } else {
        let fileExt;
        if (uint8Array) {
          let fileType = uint8Array.slice(0, 8).join("");
          if (fileType === PNG_HEADER) {
            fileExt = "png";
          } else {
            if (fileType.slice(0, 3) === JPG_HEADER) {
              fileExt = "jpg";
            }
          }
        } else {
          fileExt = "jpg";
        }

        var imageBlob = new Blob([uint8Array], {
          type: { type: `image/${fileExt}` },
        });
        const src = window.URL.createObjectURL(imageBlob);
        fileData = {
          data: src,
          type: "image",
        };
      }
      return fileData;
    } catch (exception) {
      console.log(exception);
    }
  }
};

export const getUncompletedStudentEssays = async (
  selectedActivity,
  allEssaysResponse
) => {
  let essays =
    typeof allEssaysResponse !== "undefined" && Array.isArray(allEssaysResponse)
      ? allEssaysResponse.essays
      : [];

  const studentIDsWithEssays = essays.map((essay) => essay.studentId);
  const uncompletedEssaysStudentIDs =
    selectedActivity.assignedStudentIds.filter(
      (studentID) => !studentIDsWithEssays.includes(studentID)
    );

  return await Promise.all(
    await uncompletedEssaysStudentIDs.map(async (studentId) => {
      let studentResponse = await API.graphql(
        graphqlOperation(getStudent, { id: studentId })
      );

      const student = studentResponse.data.getStudent;
      const birthDate = dayjs(student.birthDate).format("DD/MM/YYYY");
      const studentRow = {
        id: student.id,
        identifier: `${student.firstName} ${student.lastName} - DOB: ${birthDate}`,
        birthDate,
        firstName: student.firstName,
        lastName: student.lastName,
      };

      return studentRow;
    })
  );
};

export const getAllActivitiesBySchoolId = async (schoolID) => {
  let activitiesResponse = await API.graphql(
    graphqlOperation(listActivitys, {
      filter: { schoolID: { eq: schoolID } },
      limit: 1000,
    })
  );

  let activities = activitiesResponse.data.listActivitys;
  return activities;
};

export const getAllInReviewActivities = async (activities, schoolId) => {
  let activityInReviewList;
  if (activities?.items && activities.items.length > 0) {
    activityInReviewList = await Promise.all(
      await activities.items?.map(async (activity) => {
        try {
          let essayListResponse = await getAllEssays({
            activityId: activity.id,
            schoolId,
          });
          let essayList;
          if (
            essayListResponse?.essays &&
            essayListResponse.essays.length > 0
          ) {
            essayList = await Promise.all(
              await essayListResponse.essays?.map(async (essayItem) => {
                let studentResponse = await API.graphql(
                  graphqlOperation(getStudent, { id: essayItem.studentId })
                );
                let student = studentResponse.data.getStudent;

                let classYear =
                  activity.classroom.yearLevels.items?.[0].yearLevel
                    .description;

                let row = {
                  id: activity.id,
                  promptName: activity.prompt.promptName,
                  className: activity.classroom.className,
                  classYear,
                  ...essayItem,
                  student,
                  studentName: `${student?.firstName} ${student?.lastName}`,
                  creatorName: activity.user
                    ? `${activity.user?.firstName} ${activity.user?.lastName}`
                    : "",
                  assignedOn: dayjs(activity.createdAt).format(
                    "DD MMM YYYY HH:MM"
                  ),
                };

                return row;
              })
            );
          }

          return essayList;
        } catch (err) {
          console.error("error on getting activities", err);
        }
      })
    );

    activityInReviewList = _.flatten(activityInReviewList);
    // Remove undefined, null rows
    activityInReviewList = _.compact(activityInReviewList);
    // filter status IN_REVIEW only
    activityInReviewList = _.filter(activityInReviewList, function (o) {
      return o.status === "IN_REVIEW";
    });
    // sort by student
    activityInReviewList = _.sortBy(activityInReviewList, "studentName");
  }

  return activityInReviewList;
};

export const fetchRubricVideos = async (classroomId, rubricId) => {
  const input = {};
  const response = await API.graphql(graphqlOperation(listRubricVideos, input));
  const result = response.data.listRubricVideos.items;

  if (result.length > 0) {
    const rubricVideos = result.map((item, index) => {
      return { item };
    });

    return rubricVideos;
  }
};

export const getRubricStudents = async (schoolStudentIds) => {
  let studentList = await Promise.all(
    await schoolStudentIds.map(async (studentId) => {
      let input = {
        id: studentId,
      };
      const result = await API.graphql(graphqlOperation(getStudent, input));
      let schoolStudent = result.data.getStudent;

      if (schoolStudent) {
        return {
          id: studentId,
          firstName: schoolStudent.firstName,
          lastName: schoolStudent.lastName,
          fullName: `${schoolStudent.firstName} ${schoolStudent.lastName}`,
        };
      }
    })
  );

  studentList = _.flatten(studentList);
  // Remove undefined, null rows
  studentList = _.compact(studentList);

  return studentList;
};

export const getAllRubricVideoLink = async (reportData) => {
  return await Promise.all(
    await reportData.map(async (report) => {
      let rubricVideoLink = await getRubricVideoLink(report.rubricId);
      if (typeof rubricVideoLink === "undefined") {
        rubricVideoLink = "";
      }

      return { ...report, rubricVideoLink };
    })
  );
};

/**
 * This method finds the first classroom for a teacher to assign activities in one click.
 * If the teacher has more than one classroom, this will get one randomly.
 */
export const getClassroomForTeacher = async (teacherEmail, schoolId) => {
  let nextToken = null;
  let classrooms = [];
  const input = { email: teacherEmail };
  do {
    input.nextToken = nextToken;
    let searchResults = await API.graphql(
      graphqlOperation(getClassroomsByTeacherEmail, input)
    );
    const resultData = searchResults?.data?.getClassroomsByTeacherEmail?.items;
    if (resultData) {
      for (let i = 0; i < resultData.length; i++) {
        const currentClassroom = resultData[i];
        searchResults.data.getClassroomsByTeacherEmail.items[
          i
        ].classroom.classRoom = currentClassroom.classroom.className;
        if (currentClassroom.classroom.yearLevels?.items.length > 0) {
          const yearLevels = [];
          currentClassroom.classroom.yearLevels.items.forEach((list) => {
            yearLevels.push(list.yearLevel.description);
          });

          searchResults.data.getClassroomsByTeacherEmail.items[
            i
          ].classroom.classYear = yearLevels.join(", ");
        }
      }
    }

    classrooms = [
      ...classrooms,
      ...searchResults.data.getClassroomsByTeacherEmail.items,
    ];

    nextToken = searchResults.data.getClassroomsByTeacherEmail.nextToken;
  } while (nextToken != null);

  if (classrooms.length > 0) {
    const classroomsData = classrooms
      .map((classroom) => {
        if (classroom.classroom) {
          return { ...classroom.classroom };
        } else {
          return {};
        }
      })
      .filter((classroom) => {
        return (
          classroom.schoolYear === getSchoolYear() &&
          classroom.schoolID === schoolId
        );
      });
    return classroomsData;
  }

  return null;
};

export const getRubricVideoLink = async (rubricId) => {
  let inp = {
    filter: {
      rubricID: { eq: rubricId },
    },
  };
  const responseRubricVideos = await API.graphql(
    graphqlOperation(listRubricVideos, inp)
  );
  const result = responseRubricVideos.data.listRubricVideos;
  if (result.items && result.items.length > 0) {
    let videoRow = result.items[0];
    return videoRow.videoLink;
  }

  /* Disable dynamic yearlevelId, as for now we are displaying rubric video based on rubric Id only */
  /* const input = { id: classroomId };
  const response = await API.graphql(graphqlOperation(getClassroom, input));

  let yearLevelID = "";
  if (
    response.data.getClassroom &&
    response.data.getClassroom.yearLevels.items.length > 0
  ) {
    yearLevelID = response.data.getClassroom.yearLevels.items[0].yearLevelID;
  }

  if (yearLevelID && rubricId) {
    let inp = {
      rubricID: rubricId,
      yearLevelID: { eq: yearLevelID },
    };
    const responseRubricVideos = await API.graphql(
      graphqlOperation(getRubricVideoByUserId, inp)
    );
    const resV = responseRubricVideos.data.getRubricYearLevel;

    if (resV && resV.items) {
      let videoRow = resV.items[0];
      if (videoRow && videoRow.videoLink) {
        return videoRow.videoLink;
      }
    }
  } */
};

export const fetchSystemParameter = async (key) => {
  try {
    const input = {
      key,
    };

    const response = await API.graphql(
      graphqlOperation(getSystemParameter, input)
    );

    return response?.data.getSystemParameter
      ? response.data.getSystemParameter.paramData
      : null;
  } catch (error) {
    console.log("error on getting classroom students", error);
  }
};
