//const { request } = require("appSyncRequest");
import { API, graphqlOperation } from "aws-amplify"; // only for local version
import {
  testUploadsBySchoolByYearCache,
  classroomsByIDCache,
} from "utils/InMemoryQueryCaches";
import { listYearLevels } from "../../graphql/queries";
import {
  getSchoolYearWithStudentCheck,
  getStartDateStringOfSchoolYear,
} from "../helper";
const dayjs = require("dayjs");

const {
  getTestUploadsForAnalysis,
  getSchoolIDsFromStudentID,
  getSchoolIDsFromClassroomID,
  getSchoolCohortLinks,
  getSchoolsByNetwork,
} = require("./graphql/bpqueries");

const YEAR_SIX_CODE = "Y6";
const YEAR_SEVEN_CODE = "Y7";

// validateInput is unchanged from the original version of GapAnalysisTests()
function validateInput(learningAreas, testTypes) {
  if (!learningAreas) {
    throw new Error("Learning Areas are required");
  }
  if (!testTypes) {
    throw new Error("Test Types are required");
  }
}

// This method find if the year level id received as parameter is the year 7 -> this applies only for high schools receiving students from primary schools and trying to see gap analysis for those ones.
function isYearSevenSelected(yearLevels, yearLevelId) {
  const yearLevel = yearLevels.find((item) => item.id === yearLevelId);

  if (yearLevel === undefined) {
    return false;
  }

  return yearLevel.yearCode === YEAR_SEVEN_CODE;
}

// This method returns the year six level id in the database -> this applies only for high schools receiving students from primary schools and trying to see gap analysis for those ones.
const findYearSixId = (yearLevels) => {
  const yearLevel = yearLevels.find((item) => item.yearCode === YEAR_SIX_CODE);

  if (yearLevel === undefined) {
    return false;
  }

  return yearLevel.id;
};

// This method receives a network id and based on that returns all the school ids in that network.
const fetchNetworkList = async (networkID) => {
  try {
    const input = {
      limit: 1000,
      sortDirection: "ASC",
      networkID: networkID,
    };
    const query = getSchoolsByNetwork;
    const queryName = "getSchoolsByNetwork";

    const result = await API.graphql(graphqlOperation(query, input));
    if ( result?.data) {
      return result.data[queryName].items.map((school) => school.id);
    }

    return [];
  } catch (error) {
    console.log("Unable to fetch the network list data", error);
  }
};

// New for new getGapAnalysisTests
async function getTestUploads(
  schoolIDs, // use uploads from these schcoolIDs
  startYear,
  endYear, // uses this and the previous
  testTypes, // array of testTypes
  testDate, // the earliest date for candidate uploads
  learningAreas, // array of learningAreas
  studentIDs // an array of studentIDS
) {
  const schoolYears = getSchoolYearRange(startYear, endYear);
  let goodUploads = [];
  // cycle through the schools
  let schoolIDPromises = [];
  schoolIDPromises = schoolIDs.map(async (schoolID) => {
    // cycle through the school years - ie this year and last year
    let schoolYearPromises = [];
    schoolYearPromises = schoolYears.map(async (year) => {
      let rawResponse;

      const cachedItem = testUploadsBySchoolByYearCache.getItem(
        `${schoolID},${year}`
      );
      if (cachedItem) {
        rawResponse = cachedItem;
        // console.log(
        //   `got cached response for testUploadsBySchoolByYear(${schoolID},${year})`
        // );
      } else {
        const input = {
          schoolID,
          schoolYear: {
            eq: year,
          },
          limit: 1000,
        };
        rawResponse = await API.graphql(
          graphqlOperation(getTestUploadsForAnalysis, input)
        );
        testUploadsBySchoolByYearCache.setItem(
          `${schoolID},${year}`,
          rawResponse
        );
      }
      //now remove uploads that dont meet the criteria
      if (rawResponse?.data?.getTestUploadsBySchoolByYear?.items) {
        rawResponse.data.getTestUploadsBySchoolByYear.items.forEach(
          (upload) => {
            let studentList = [];
            let goodUpload = true;
            if (upload.testDate < testDate) goodUpload = false; //eliminate if bad testDate
            if (goodUpload && !testTypes.includes(upload.test.typeID))
              // eliminate if bad testType
              goodUpload = false;
            if (goodUpload) {
              goodUpload = false;
              upload.test.learningAreas.items.forEach((learningArea) => {
                // eliminate if bad learningArea
                if (learningAreas.includes(learningArea.learningArea.id))
                  goodUpload = true;
              });
            }
            if (goodUpload) {
              // elimininate if no matching students
              // add students to the total students for that upload
              goodUpload = false;
              upload.testResults.items.forEach((item) => {
                studentList.push(item.studentID);
              });
              for (let n = 0; n < studentIDs.length; n++) {
                if (studentList.includes(studentIDs[n])) {
                  goodUpload = true;
                  break;
                }
              }
            }

            // Pass back the qualifying TestUpload
            if (goodUpload) {
              // dont think duplicate TestUploads are possible
              let tempUpload = {};
              tempUpload.id = upload.id;
              tempUpload.learningAreaID =
                upload.test.learningAreas.items.length > 0
                  ? upload.test.learningAreas.items[0].learningArea.id
                  : null;
              tempUpload.testDate = upload.testDate;
              tempUpload.testID = upload.testID;
              tempUpload.testKey = `${upload.test.testName}#${upload.testDate}`;
              tempUpload.testName = upload.test.testName;
              // gap review names
              if (
                
                upload.test?.testType &&
                upload.test.testType.description
              ) {
                tempUpload.shortName =
                   upload.test?.shortName
                    ? upload.test.shortName
                    : upload.test.testName;
              } else {
                tempUpload.shortName = upload.test.shortName
                  ? upload.test.shortName
                  : upload.test.testName;
              }

              tempUpload.testInfo = upload.school.schoolName;
              tempUpload.schoolID = upload.schoolID;
              tempUpload.schoolYear = upload.schoolYear;
              goodUploads.push(tempUpload);
            }
          }
        );
      }
      return "done";
    });
    await Promise.all(schoolYearPromises);
    return "done";
  });
  await Promise.all(schoolIDPromises);
  return goodUploads; // these uploads meet the selected criteria
}

function getSchoolYearRange(startYear, endYear) {
  if (!startYear || !endYear) return [];

  if (endYear < startYear) throw new Error("endYear is less than startYear");

  const result = [startYear];
  if (startYear === endYear) return result;

  while (startYear !== endYear) {
    startYear++;
    if (startYear === endYear) continue;
    result.push(startYear);
  }

  result.push(endYear);
  return result;
}

//Find the students in a cohort
export async function getStudentsInCohort(schoolID, yearLevelID, schoolYear) {
  let studentIDs = [];

  try {
    const input = {
      schoolID,
      schoolYearYearLevelID: {
        eq: {
          schoolYear: schoolYear,
          yearLevelID: yearLevelID,
        },
      },
      limit: 1000,
    };
    const resp = await API.graphql(
      graphqlOperation(getSchoolCohortLinks, input)
    );
    studentIDs = resp.data.getSchoolStudentsByYearAndYearLevel.items;
  } catch (error) {
    console.error(
      "error finding cohort students",
      schoolID,
      yearLevelID,
      error
    );
  }
  return studentIDs; //this is an array of {studentID:"djhasdhhhh"}
}

// Note: A student may have multiple schoolIDs in a single year
// We have to return an array of schoolIDs
// If a single student is tested for analysis - we include past schools also
// For all other student groups we only use the current school
async function getSchoolDataFromStudentID(studentID, schoolYear) {
  let schoolData = [];

  const input = {
    studentID,
    schoolYear,
  };

  try {
    const resp = await API.graphql(
      graphqlOperation(getSchoolIDsFromStudentID, input)
    );
    schoolData = resp.data.getStudentSchools.items;
  } catch (error) {
    console.error("error getting schoolData from studentID", error);
    throw error;
  }
  return schoolData; // an array of schoolIDs for that student
}

//Note: A classroom may be either a classroom or a Focus Group
//      if classroom then the array of yearLevelIDs is usually provided
//      If focus group we have to find teh yearLevels from the students
async function getSchoolDataFromClassroomID(classroomID) {
  let schoolData;

  const input = {
    id: classroomID,
  };
  try {
    let resp = classroomsByIDCache.getItem(classroomID);
    if (!resp) {
      resp = await API.graphql(
        graphqlOperation(getSchoolIDsFromClassroomID, input)
      );
      classroomsByIDCache.setItem(classroomID, resp);
    }
    schoolData = resp.data.getClassroom;
  } catch (error) {
    console.error("error getting schoolData from classroomID", error);
    throw error;
  }
  return schoolData; // a single schoolID and and array of students
}

export async function getGapAnalysisTestsLocal(event) {
  const learningAreas = event.learningAreas;
  const testTypes = event.testTypes;
  let startYear = event.startYear;
  let endYear = event.endYear;
  const studentID = event.studentID;
  const classroomID = event.classroomID;
  const schoolID = event.schoolID;
  const yearLevelID = event.yearLevelID;
  const networkSchools = event.networkSchools;
  const networkId = event.networkId; // this is only valid for year 7 high schools to get data from previous schools.

  let goodUploads = []; // this is for the new getTestUploads
  let schoolIDs = []; // the list of schoolIDs to be constructed
  let studentIDs = []; // the list of studentIDs to be constructed

  try {
    const yearLevels = await API.graphql(
      graphqlOperation(listYearLevels, { limit: 10000 })
    ).then((response) => response.data.listYearLevels.items);

    validateInput(learningAreas, testTypes);
    if (!startYear) {
      startYear = parseInt(
        dayjs().subtract(1, "year").format("YYYY").toString()
      );
    }
    if (!endYear) {
      endYear = parseInt(dayjs().format("YYYY").toString());
    }

    // New getGapAnalysisTests
    // We separately find the
    //  studentIDs in the testgroup
    //  testUploads that match the test criteria

    // We call getTestUploads separately for each schoolID
    // because TestUploads are matched to only one schoolID
    if (networkSchools && yearLevelID) {
      // do this once for every schoolID
      let promises = networkSchools.map(async (schoolID) => {
        // these have to be local variables inside the asyn
        // or timing of initialisations will be off if global
        let schoolIDs = [];
        let studentIDs = [];
        // find the student list in each school
        let resp;

        // Check if the year level selected is Seven.
        if (isYearSevenSelected(yearLevels, yearLevelID)) {
          // get the students of that school that were in year 6 in the previous school year - this is only valid for high schools that receives students from primary
          resp = await getStudentsInCohort(
            schoolID,
            findYearSixId(yearLevels),
            dayjs().subtract(1, "year").format("YYYY").toString()
          );
        } else {
          const schoolYear = await getSchoolYearWithStudentCheck(schoolID);
          resp = await getStudentsInCohort(schoolID, yearLevelID, schoolYear);
        }

        resp.forEach((response) => {
          studentIDs.push(response.studentID);
        });
        schoolIDs.push(schoolID); // schoolIDs has to be an array
        // now find the list of testUploads for that school

        const schoolYearStartDate = getStartDateStringOfSchoolYear(startYear);
        let goodUploadsTmp = await getTestUploads(
          schoolIDs,
          startYear,
          endYear,
          testTypes,
          schoolYearStartDate, // testDate
          learningAreas,
          studentIDs
        );
        goodUploadsTmp.forEach((upload) => {
          goodUploads.push(upload);
        });
      });
      await Promise.all(promises);
    } else {
      // for all other cases we can first find the studentID first
      if (studentID) {
        // find the list of schools for that student (normally only 1, but can be more)
        let resp = await getSchoolDataFromStudentID(studentID, startYear);
        resp.forEach((item) => {
          //resp is an array of {schoolIDs,yearLevelIDs}
          if (!schoolIDs.includes(item.schoolID)) schoolIDs.push(item.schoolID);
        });
        studentIDs.push(studentID);
      } else if (classroomID) {
        // classroomIDs are unique so we find the schoolID, and the list of students in the class
        let { isAYearSevenClassroom, studentIDsResponse, schoolIDResponse } =
          await getStudentIDListForClassroom(classroomID);
        studentIDs = studentIDsResponse;
        // if the classroom is year 7 and the school belongs to a network, the current students of the classroom
        if (networkId && isAYearSevenClassroom) {
          // get the schools ids of the network and fetch results for those students.
          schoolIDs = await fetchNetworkList(networkId);
        } else if (schoolIDResponse) {
          schoolIDs.push(schoolIDResponse);
        }
      } else if (schoolID && yearLevelID) {
        // validating if year 7 is selected and the school is part of a network.
        if (networkId && isYearSevenSelected(yearLevels, yearLevelID)) {
          schoolIDs = await fetchNetworkList(networkId);
        } else {
          schoolIDs.push(schoolID);
        }

        const schoolYear = await getSchoolYearWithStudentCheck(schoolID);
        // now find the list of students in that cohort
        let resp = await getStudentsInCohort(schoolID, yearLevelID, schoolYear);
        resp.forEach((response) => {
          studentIDs.push(response.studentID);
        });
      }

      const schoolYearStartDate = getStartDateStringOfSchoolYear(startYear);
      // now find the list of testUploads
      goodUploads = await getTestUploads(
        schoolIDs,
        startYear,
        endYear,
        testTypes,
        schoolYearStartDate, // testDate,
        learningAreas,
        studentIDs
      );
    }
  } catch (error) {
    console.error("Error processing", error);
    return error;
  }
  return goodUploads;
}
export async function getStudentIDListForClassroom(classroomID) {
  const studentIDsResponse = [];
  let resp = await getSchoolDataFromClassroomID(classroomID);
  // flag to define if the classroom is for year 7
  let isAYearSevenClassroom = false;
  // validating if the classroom year level is year  seven
  if (resp?.yearLevels?.items) {
    resp.yearLevels.items.forEach((item) => {
      if (
        
        item?.yearLevel &&
        item.yearLevel.yearCode === YEAR_SEVEN_CODE
      ) {
        isAYearSevenClassroom = true;
      }
    });
  }
  if (resp?.students?.items) {
    resp.students.items.forEach((item) => {
      studentIDsResponse.push(item.studentID);
    });
  }
  return {
    isAYearSevenClassroom,
    studentIDsResponse,
    schoolIDResponse: resp?.schoolID || undefined,
  };
}
