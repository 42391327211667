import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";

import { getStudentByNameByBirthDate } from "../../graphql/bpqueries";
import { createStudent, createSchoolStudent } from "../../graphql/bpmutations";

/**
 * @typedef {object} Student
 * @property {string} [id] - the id of the student (if it exists in the database)
 * @property {string} student.firstName
 * @property {string} student.lastName
 * @property {string} [student.middleName]
 * @property {Date} student.birthDate
 * @property {String} [student.studentUniqueIdentifier]
 * @property {String} [student.wondeID]
 * @property {String} [student.MISID]
 * @property {"Male"|"Female"|"NonBinary"|"NotProvided"} student.gender
 */

/**
 * Use this function as a central API to create a SchoolStudent record.
 * @param {Student} student - a Student object with an id property
 * @param {string} schoolID - the id of the school
 * @param {number} schoolYear - the school year e.g. 2020
 * @param {string} yearLevelID - the id of the year level for the student
 * @returns {SchoolStudent} - the new SchoolStudent object with the id property set
 */
export const createSchoolStudentRecord = async (
  student,
  schoolID,
  schoolYear,
  yearLevelID
) => {
  // Create school student
  let schoolInput = {
    schoolID: schoolID,
    studentID: student.id,
    schoolYear: schoolYear,
    yearLevelID: yearLevelID,
    firstName: student.firstName,
    lastName: student.lastName,
  };
  try {
    const schoolStudentResult = await API.graphql(
      graphqlOperation(createSchoolStudent, { input: schoolInput })
    );
    return schoolStudentResult.data.createSchoolStudent;
  } catch (error) {
    console.error("error adding student to school", error);
    throw error; // rethrow
  }
};

/**
 * Use this function as a central API to create a student record.
 * @param {Student} student - a Student object to store in the database
 * @param {string} yearLevelID
 * @returns {object} studentResponseItem
 */
export const createStudentRecord = async (student, yearLevelID) => {
  student.firstName = _.capitalize(_.trim(student.firstName));
  student.lastName = _.capitalize(_.trim(student.lastName));

  student.middleName = student.middleName
    ? _.capitalize(_.trim(student.middleName))
    : "";

  // check if student exists
  let studentSearchResult = await getStudentByNameBirthDate(
    student.firstName,
    student.lastName,
    student.birthDate
  );

  const input = {
    firstName: student.firstName,
    lastNameBirthDate: {
      eq: {
        birthDate: student.birthDate,
        lastName: student.lastName,
      },
    },
  };

  if (!studentSearchResult?.id) {
    // create student
    input.yearLevelID = yearLevelID;
    input.gender = student.gender;
    input.middleName = student.middleName ? student.middleName : "";
    input.lastName = student.lastName;
    input.birthDate = student.birthDate;
    input.studentUniqueIdentifier = student?.studentUniqueIdentifier;
    delete input.filter;
    delete input.lastNameBirthDate;

    try {
      const resp = await API.graphql(
        graphqlOperation(createStudent, { input })
      );
      const studentResponseItem = resp.data.createStudent;
      return studentResponseItem;
    } catch (error) {
      console.error("error adding student", error);
      throw error; // rethrow
    }
  } else {
    return studentSearchResult;
  }
};

export const getStudentByNameBirthDate = async (
  firstName,
  lastName,
  birthDate
) => {
  firstName = _.capitalize(_.trim(firstName));
  lastName = _.capitalize(_.trim(lastName));

  if (birthDate instanceof Date) {
    birthDate = birthDate.toISOString().split("T")[0];
  }

  const input = {
    firstName,
    lastNameBirthDate: {
      eq: {
        birthDate: birthDate,
        lastName,
      },
    },
  };

  // check if student exists
  const resp = await API.graphql(
    graphqlOperation(getStudentByNameByBirthDate, input)
  );

  const studentSearchResultItems =
    resp?.data?.getStudentByNameByBirthDate?.items;

  const studentSearchResult = studentSearchResultItems?.length
    ? studentSearchResultItems[0]
    : [];

  return studentSearchResult;
};
