import { API, Auth, graphqlOperation } from "aws-amplify";
import { updateUser } from "../graphql/mutations";
const AdminApi = "AdminQueries";

export async function disableUserAccount({ payload }) {
  const path = "/disableUser";
  const init = {
    body: { username: payload },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  const resp = await API.post(AdminApi, path, init);
  const input = { email: payload, enabled: false };
  await API.graphql(graphqlOperation(updateUser, { input }));
  return resp.message;
}

export async function enableUserAccount({ payload }) {
  const path = "/enableUser";
  const init = {
    body: { username: payload },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  const resp = await API.post(AdminApi, path, init);
  const input = { email: payload, enabled: true };
  await API.graphql(graphqlOperation(updateUser, { input }));
  return resp.message;
}
