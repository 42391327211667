export default {
  SET_ASSESSMENT_CODES: "SET_ASSESSMENT_CODES",
  SAVE_ASSESSMENT: "SAVE_ASSESSMENT",
  SET_STUDENT_ASSESSMENT: "SET_STUDENT_ASSESSMENT",
  SET_SELECTED_STUDENTS: "SET_SELECTED_STUDENTS",
  SET_PREMADE_TESTS: "SET_PREMADE_TESTS",
  SET_BP_ASSESSMENT_TESTS: "SET_BP_ASSESSMENT_TESTS",
  SET_CLASSROOM_DATA: "SET_CLASSROOM_DATA",
  SET_LIBRARY_TESTS: "SET_LIBRARY_TESTS",
  SET_PREMIUM_TESTS: "SET_PREMIUM_TESTS",
  SET_CLASS_ACTIVITIES: "SET_CLASS_ACTIVITIES",
};
