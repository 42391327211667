import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Amplify, { Storage } from "aws-amplify";
import LogRocket from "logrocket";
import { isDevHostname, isLocalhost } from "./utils/helper";

import awsExports from "./aws-exports";
import {
  getPinpointConfigForThisEnv,
  initialisePinpoint,
} from "./pinpointConfig";

import * as serviceWorker from "./serviceWorker";
import swConfig from "./swConfig";

import App from "./App";
import { icons } from "./assets/icons";

import { store, persistor } from "./redux/store";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

// disable logrocket on localhost and dev
if (!isLocalhost && !isDevHostname) {
  // tell logrocket to use a different URL for static assets
  if (process.env.REACT_APP_LOGROCKET_BASE_HREF) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT, {
      dom: {
        baseHref: process.env.REACT_APP_LOGROCKET_BASE_HREF,
      },
    });
  } else {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT);
  }
}
// NB: I'm leaving this comment below as it's super useful for developers to know they can uncomment it on their local machines
// window.LOG_LEVEL = "VERBOSE";

// get pinpoint config for current detected environment
const pinpointForThisEnv = getPinpointConfigForThisEnv();
// now add the pinpoint config to the AWS config (i.e. for amplify): aws_mobile_analytics_app_id and aws_mobile_analytics_app_region.
const awsExportsWithPinpoint = { ...awsExports, ...pinpointForThisEnv };
initialisePinpoint(pinpointForThisEnv);

Amplify.configure(awsExportsWithPinpoint);
console.log("Amplify configured; notifying listeners...");
// You can register a listener to execute after amplify has been configured, as per the commented line below.
// window.addEventListener("message", (event) => {  if (event.source !== window) {return;} if (event.data.type && event.data.type === "AMPLIFY_CONFIGURED") {console.log('amplify listener');}});

window.postMessage(
  {
    type: "AMPLIFY_CONFIGURED",
  },
  "*"
);
Storage.configure({ level: "public" });

React.icons = icons;

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// expose Redux store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

serviceWorker.register(swConfig);
