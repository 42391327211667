import React, { useCallback, useEffect, useState } from "react";
import { ProgressBar } from "devextreme-react/progress-bar";

const PasswordValidation = ({ password }) => {
  const MAX_STRENGTH = 7;
  const WRONG_INDICATOR = "#ee4491";
  const SUCCESS_INDICATOR = "#5fbf7f";
  const NO_INDICATOR = "#252d65";
  const ONE_LOWERCASE = new RegExp("((?=.*[a-z]))");
  const ONE_UPPERCASE = new RegExp("((?=.*[A-Z]))");
  const ONE_DIGIT = new RegExp("\\d");
  const NUMBERS_AND_LETTERS = new RegExp("((?=.*[^A-Za-z0-9]))");
  const STRONG_PASSWORD = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  const MEDIUM_PASSWORD = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [validLength, setValidLength] = useState("");
  const [hasUppercase, setHasUppercase] = useState("");
  const [hasLowercase, setHasLowercase] = useState("");
  const [hasNumber, setHasNumber] = useState("");

  const calculatePasswordStrength = useCallback(() => {
    let strength = 0;
    if (password) {
      // validate correct length
      if (password.length > 8) {
        strength++;
        setValidLength(SUCCESS_INDICATOR);
      } else {
        setValidLength(WRONG_INDICATOR);
      }
      // validate one lowercase letter
      if (ONE_LOWERCASE.test(password)) {
        setHasLowercase(SUCCESS_INDICATOR);
        strength++;
      } else {
        setHasLowercase(WRONG_INDICATOR);
      }
      // validate one uppercase letter
      if (ONE_UPPERCASE.test(password)) {
        setHasUppercase(SUCCESS_INDICATOR);
        strength++;
      } else {
        setHasUppercase(WRONG_INDICATOR);
      }
      // validate that the password contains a digit
      if (ONE_DIGIT.test(password)) {
        setHasNumber(SUCCESS_INDICATOR);
        strength++;
      } else {
        setHasNumber(WRONG_INDICATOR);
      }

      // validate that the password has number and letters.
      if (NUMBERS_AND_LETTERS.test(password)) {
        strength++;
      }
      // validate if it's a medium password
      if (MEDIUM_PASSWORD.test(password)) strength++;
      // validate if it's a strong password
      if (STRONG_PASSWORD.test(password)) strength++;
    } else {
      setHasLowercase(NO_INDICATOR);
      setHasUppercase(NO_INDICATOR);
      setHasNumber(NO_INDICATOR);
      setValidLength(NO_INDICATOR);
    }
    setPasswordStrength(strength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    calculatePasswordStrength();
  }, [password, calculatePasswordStrength]);

  function statusFormat(value) {
    let label = "Weak";
    if (value <= 0.4) {
      label = "Weak";
    }
    if (value >= 0.41 && value <= 0.7) {
      label = "Medium";
    }
    if (value >= 0.71) {
      label = "Strong";
    }
    return label;
  }

  return (
    <div style={{ marginTop: "15px" }} className="password-requirements">
      <h6 style={{ color: NO_INDICATOR }}>Password Requirements</h6>
      <ProgressBar
        id="progress-bar-status"
        className={"complete"}
        width="60%"
        min={0}
        max={MAX_STRENGTH}
        statusFormat={statusFormat}
        value={passwordStrength ? passwordStrength : 0}
      />
      <h6 style={{ color: validLength ? validLength : NO_INDICATOR }}>
        MUST contain at least 8 characters (12+ recommended)
      </h6>
      <h6 style={{ color: hasUppercase ? hasUppercase : NO_INDICATOR }}>
        MUST contain at least one uppercase letter
      </h6>
      <h6 style={{ color: hasLowercase ? hasLowercase : NO_INDICATOR }}>
        MUST contain at least one lowercase letter
      </h6>
      <h6 style={{ color: hasNumber ? hasNumber : NO_INDICATOR }}>
        MUST contain at least one number
      </h6>
    </div>
  );
};

export default PasswordValidation;
