import { API, Auth, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getGapAnalysisTestsLocal } from "../../utils/GapAnalysisLocal/gapAnalysisTestsLocal";
import DataSource from "devextreme/data/data_source";
import _ from "lodash";

import UIActions from "./ui.actiontypes";
import {
  createClassroom,
  createClassroomTeacher,
} from "../../graphql/mutations";

import {
  clearGapSettingsAction,
  processingFailureAction,
  setClassroomGapValuesAction,
  setGapAnalysisFilter,
  setGapAnalysisTitle,
  setGapDataAction,
  setGapFilterValueAction,
  setGapLearningAreasAction,
  setLoadingFalse,
  setLoadingTrue,
  setStudentGapValuesAction,
  setUserGapValuesAction,
  setWasASplitClassroomSelected,
} from "./ui.actions";

import {
  createUserSettingsData,
  updateNotification,
  updateUserSettingsData,
} from "../../graphql/mutations";
import {
  getSystemParameter,
  getUserSettingsByKey,
  listLearningAreas,
  listTestTypes,
} from "../../graphql/queries";
import { Lambda } from "aws-sdk";
import awsExports from "../../aws-exports";
import {
  getSchoolYear,
  makeid,
  getSchoolYearWithStudentCheck,
} from "../../utils/helper";
import { ClassroomStudentDataStore } from "dataSources/schools/ClassroomStudentDataStore";

const getGapAnalysisFilter = (state) => state.app.gapAnalysisFilter;
const getGapFilterType = (state) => state.app.gapFilterType;
const getGapFilterValue = (state) => state.app.gapFilterValue;
const getGapSchools = (state) => state.app.gapSchools;
const getGapTests = (state) => state.app.gapTests;
const getGapTestTypes = (state) => state.app.gapTestTypes;
const getGapTitle = (state) => state.app.gapAnalysisTitle;
const getGapLearningAreas = (state) => state.app.gapLearningAreas;
const getUserProfile = (state) => state.user.userProfile;
const getSelectedSchool = (state) => state.app.selectedSchool;

export function* markNotificationAsRead({ payload: { id } }) {
  try {
    const updateDate = new Date();
    const expiryTime = Math.floor(
      new Date().setDate(updateDate.getDate() + 7) / 1000
    );
    const input = {
      id,
      read: true,
      readDate: updateDate.toISOString(),
      expiryTime,
    };
    yield API.graphql(graphqlOperation(updateNotification, { input }));
  } catch (error) {
    console.error("error marking notification as read", error);
    yield put(processingFailureAction(error.errors[0].message));
  }
}

export function* saveUserGapAnalysisFilter({ payload }) {
  if (!_.isEmpty(payload)) {
    try {
      // see if settings data already exists & update if it does
      const input = {
        email: payload.email,
        settingsKey: { eq: `${payload.schoolID}#GapAnalysisFilter` },
      };

      const resp = yield API.graphql(
        graphqlOperation(getUserSettingsByKey, input)
      );

      if (resp.data.getUserSettingsByKey.items.length > 0 || payload.id) {
        const input = {
          id: payload.id
            ? payload.id
            : resp.data.getUserSettingsByKey.items[0].id,
          settingsData: JSON.stringify(payload),
        };

        yield API.graphql(graphqlOperation(updateUserSettingsData, { input }));
      } else {
        const input = {
          email: payload.email,
          settingsKey: `${payload.schoolID}#GapAnalysisFilter`,
          settingsData: JSON.stringify(payload),
        };

        yield API.graphql(graphqlOperation(createUserSettingsData, { input }));
      }
      yield put(setGapAnalysisFilter(payload));
    } catch (error) {
      console.error("error saving gap analysis filter", error);
      yield put(processingFailureAction(error.errors[0].message));
    }
  }
}

export function* getUserGapAnalysisSettings({ user, school }) {
  yield put(clearGapSettingsAction());

  const input = {
    email: user.email,
    settingsKey: { eq: `${school.id}#GapAnalysisFilter` },
  };

  try {
    const resp = yield API.graphql(
      graphqlOperation(getUserSettingsByKey, input)
    );

    let filterData = {};

    if (resp.data.getUserSettingsByKey.items.length > 0) {
      filterData = JSON.parse(
        resp.data.getUserSettingsByKey.items[0].settingsData
      );
      filterData.id = resp.data.getUserSettingsByKey.items[0].id;
    }
    yield put(setGapAnalysisFilter(filterData));

    if (_.isEmpty(filterData)) {
      yield put(clearGapSettingsAction());
    } else {
      yield put(setUserGapValuesAction(filterData));
    }
  } catch (error) {
    console.error(
      "error getting user settings for gap analysis filter ui",
      error
    );
  }
}

export function* setUserGapAnalysisFilter({ payload: school }) {
  const gapFilter = yield select(getGapAnalysisFilter);
  const user = yield select(getUserProfile);

  if (!_.isEmpty(gapFilter)) {
    if (user.email) {
      // filter is set for different user & school
      if (gapFilter.email !== user.email || gapFilter.schoolID !== school.id) {
        yield call(getUserGapAnalysisSettings, { user, school });
      }
    }
  } else {
    if (user.email) {
      yield call(getUserGapAnalysisSettings, { user, school });
    }
  }
}

export function* runClassroomGapAnalysis({ payload }) {
  const school = yield select(getSelectedSchool);

  const title = `${payload.classType === "Classroom" ? "Class:" : ""} ${
    payload.className
  }`;

  yield put(setLoadingTrue(`Analysing gaps - ${title}...`));
  yield put(setGapDataAction([]));

  let areas = yield select(getGapLearningAreas);
  let types = yield select(getGapTestTypes);

  if (areas.length === 0) {
    yield getGapAnalysisAreasTests();

    areas = yield select(getGapLearningAreas);
    types = yield select(getGapTestTypes);
  }

  let input = {
    studentID: null,
    schoolID: school.id,
    networkId: school.networkID,
    yearLevelID: null,
    networkSchools: null,
    classroomID: payload.id,
    learningAreas: areas,
    testTypes: types,
  };

  const tests = yield getGapAnalysisTestsLocal(input);

  // set gap analysis values
  yield put(
    setClassroomGapValuesAction(payload.id, payload.classType, tests, title)
  );

  let uniqueTests = new Map();

  tests.forEach((test) => {
    let key = `${test.testID}${test.testDate}`;
    if (!uniqueTests.get(key)) {
      uniqueTests.set(key, test);
    }
  });

  let gapTests = Array.from(uniqueTests.values());

  tests.forEach((tu) => {
    if (tu.id) {
      tu["testUploadID"] = tu.id;
      delete tu.id;
    }
  });

  input = {
    classroomID: payload.id,
    schoolID: null,
    tests: gapTests,
    learningAreas: areas,
    allTestUploads: tests,
    schoolYear: getSchoolYear(),
  };

  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token,
      },
      body: input,
    };

    // console.log("parameters sent to the lamda",requestInfo);
    const data = yield API.put("GapAnalysisApi", "/doGA", requestInfo);
    //let resData = JSON.parse(data);
    // console.log("resData at ui.sagas.js:384", data);

    let resData = data;
    // console.log("data returned from the lambda",data);
    if (data.FunctionError) {
      console.error(
        "error running gap analysis for a classroom/focusgroup",
        data
      );
      yield put(
        processingFailureAction(
          "Error running gap analysis for a classroom/focusgroup"
        )
      );
    } else {
      yield put(setGapDataAction(resData));
      yield put(setLoadingFalse());
    }
  } catch (error) {
    console.error("error getting gap analysis data for a classroom", error);
    yield put(
      processingFailureAction("Error getting Gap Analysis Data for a classroom")
    );
  }
}

export function* runStudentGapAnalysis({ payload }) {
  const title = `${payload.student.firstName} ${payload.student.lastName} (${payload.student.currentYear.description})`;

  yield put(setLoadingTrue(`Analysing gaps - ${title}...`));
  yield put(setGapDataAction([]));

  let areas = yield select(getGapLearningAreas);
  let types = yield select(getGapTestTypes);

  if (areas.length === 0) {
    yield getGapAnalysisAreasTests();

    areas = yield select(getGapLearningAreas);
    types = yield select(getGapTestTypes);
  }

  let input = {
    studentID: payload.student.id,
    schoolID: null,
    yearLevelID: null,
    networkSchools: null,
    classroomID: null,
    learningAreas: areas,
    testTypes: types,
  };

  const tests = yield getGapAnalysisTestsLocal(input);

  // set gap analysis values
  yield put(setStudentGapValuesAction(payload.student.id, tests, title));

  let uniqueTests = new Map();

  tests.forEach((test) => {
    let key = `${test.testID}${test.testDate}`;
    if (!uniqueTests.get(key)) {
      uniqueTests.set(key, test);
    }
  });

  let gapTests = Array.from(uniqueTests.values());

  tests.forEach((tu) => {
    if (tu.id) {
      tu["testUploadID"] = tu.id;
      delete tu.id;
    }
  });

  input = {
    classroomID: null,
    schoolID: null,
    studentID: payload.student.id,
    tests: gapTests,
    learningAreas: areas,
    allTestUploads: tests,
    schoolYear: getSchoolYear(),
  };

  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token,
      },
      body: input,
    };

    // console.log("parameters sent to the lamda",requestInfo);
    const data = yield API.put("GapAnalysisApi", "/doGA", requestInfo);
    //let resData = JSON.parse(data);
    // console.log("resData at ui.sagas.js:384", data);

    let resData = data;
    // console.log("data returned from the lambda",data);
    if (data.FunctionError) {
      console.error("error running gap analysis for the student", data);
      yield put(
        processingFailureAction("Error performing gap analysis for a student")
      );
    } else {
      yield put(setGapDataAction(resData));
      yield put(setLoadingFalse());
    }
  } catch (error) {
    console.error("error getting analysis data for a student", error);
    yield put(
      processingFailureAction("error getting analysis data for a student")
    );
  }
}

async function ApiCall(query, input) {
  if (!input) {
    return await API.graphql(graphqlOperation(query));
  }
  return await API.graphql(graphqlOperation(query, { input }));
}

function* createClassRoom(school) {
  let classRoomId = "";

  try {
    // Create classroom
    const input = {
      classType: "TemporaryFocusGroup",
      className: "Temporary-class-" + Date.now() + makeid(5),
      schoolYear: getSchoolYear(),
      schoolID: school.id,
      focusGroupType: "Support",
    };
    const resp = yield call(ApiCall, createClassroom, input);
    classRoomId = resp?.data?.createClassroom?.id;
  } catch (error) {
    console.error("error creating classroom", error);
  }

  return classRoomId;
}

function* createClassRoomTeacher(classRoomId, user) {
  try {
    // Create classroom teacher
    const input = {
      classroomID: classRoomId,
      email: user.email,
    };

    yield call(ApiCall, createClassroomTeacher, input);
  } catch (error) {
    console.error("error assigning classroom to teacher", error);
  }
}

const assignStudentsInClass = async (students, classRoomId) => {
  let promises = [];

  const studentDS = new DataSource({
    store: ClassroomStudentDataStore,
  });

  students.forEach((studentID) => {
    const promise = new Promise((resolve, reject) => {
      const input = {
        classroomID: classRoomId,
        studentID: studentID,
      };

      studentDS
        .store()
        .insert(input)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error linking student to focus group", error);
          reject(error);
        });
    });

    promises.push(promise);
  });

  return Promise.all(promises).catch((error) => {
    console.error("Error assigning classroom to students", error);
  });
};

export function* runGapAnalysisMarksbook(action) {
  const students = action.payload.students;
  const history = action.payload.history;
  const school = yield select(getSelectedSchool);
  const user = yield select(getUserProfile);
  let learningAreas = yield call(ApiCall, listLearningAreas);
  let testTypes = yield call(ApiCall, listTestTypes);

  // Get learningAreas id only
  learningAreas = learningAreas
    ? learningAreas?.data?.listLearningAreas?.items.map((item) => item.id)
    : [];

  // Get testTypes id only
  testTypes = testTypes
    ? testTypes?.data?.listTestTypes?.items.map((item) => item.id)
    : [];

  let classRoomId = yield call(createClassRoom, school);

  yield call(createClassRoomTeacher, classRoomId, user);
  yield call(assignStudentsInClass, students, classRoomId);

  // get test uploads
  yield put(setGapDataAction([]));

  let input = {
    studentID: null,
    schoolID: null,
    yearLevelID: null,
    networkSchools: null,
    classroomID: classRoomId,
    learningAreas: learningAreas,
    testTypes: testTypes,
    startYear: getSchoolYear() - 1,
    endYear: getSchoolYear(),
    testDate: dayjs().subtract(1, "year").format("YYYY-MM-DD").toString(),
    networkId: school.networkID,
  };
  const receivedGapTests = yield call(getGapAnalysisTestsLocal, input);

  let uniqueTests = new Map();

  receivedGapTests.forEach((test) => {
    let key = `${test.testID}${test.testDate}`;
    if (!uniqueTests.get(key)) {
      uniqueTests.set(key, test);
    }
  });

  let gapTests = Array.from(uniqueTests.values());
  receivedGapTests.forEach((tu) => {
    if (tu.id) {
      tu["testUploadID"] = tu.id;
      delete tu.id;
    }
  });

  input = {
    studentID: null,
    classroomID: classRoomId,
    schoolID: null,
    yearLevelID: null,
    networkSchools: null,
    tests: gapTests,
    learningAreas: learningAreas,
    allTestUploads: receivedGapTests,
    schoolYear: getSchoolYear(),
  };

  yield put(setGapFilterValueAction(null));
  yield put(setWasASplitClassroomSelected(false));
  yield put(setGapAnalysisTitle("Group: Marksbook selected students"));

  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token,
      },
      body: input,
    };
    // run gap analysis
    const data = yield API.put("GapAnalysisApi", "/doGA", requestInfo);
    let resData = data;

    if (data.FunctionError) {
      console.error("error running gap analysis", data);
      yield put(processingFailureAction("Error performing gap analysis"));
    } else {
      yield put(setGapDataAction(resData));
      history.push("/gapanalysis");
      yield put(setLoadingFalse());
    }
  } catch (error) {
    console.error("error getting network data", error);
    yield put(processingFailureAction("Error getting Gap Analysis Data"));
  }
}

export function* runGapAnalysis() {
  yield put(setGapDataAction([]));

  const gapFilterType = yield select(getGapFilterType);
  const gapFilterValue = yield select(getGapFilterValue);
  const gapSchools = yield select(getGapSchools);
  const receivedGapTests = yield select(getGapTests);

  let uniqueTests = new Map();

  receivedGapTests.forEach((test) => {
    let key = `${test.testID}${test.testDate}`;
    if (!uniqueTests.get(key)) {
      uniqueTests.set(key, test);
    }
  });

  let gapTests = Array.from(uniqueTests.values());

  const gapTitle = yield select(getGapTitle);
  const gapLearningAreas = yield select(getGapLearningAreas);
  const school = yield select(getSelectedSchool);

  yield put(setLoadingTrue(`Analysing gaps - ${gapTitle}...`));

  let input = {
    key: "env",
  };

  receivedGapTests.forEach((tu) => {
    if (tu.id) {
      tu["testUploadID"] = tu.id;
      delete tu.id;
    }
  });
  const schoolYear = yield getSchoolYearWithStudentCheck(school.id);

  input = {
    studentID:
      gapFilterType === "Student" && gapFilterValue?.studentID
        ? gapFilterValue.studentID
        : null,
    classroomID:
      gapFilterType === "Classroom" || gapFilterType === "FocusGroup"
        ? gapFilterValue !== null && gapFilterValue.id !== undefined
          ? gapFilterValue.id
          : gapFilterValue
        : null,
    schoolID: gapFilterType === "Cohort" ? school.id : null,
    yearLevelID:
      gapFilterType === "Cohort" || gapFilterType === "Network"
        ? gapFilterValue !== null && gapFilterValue.id !== undefined
          ? gapFilterValue.id
          : gapFilterValue
        : null,
    networkSchools: gapFilterType === "Network" ? gapSchools : null,
    tests: gapTests, //JSON.stringify(gapTests),
    learningAreas: gapLearningAreas,
    allTestUploads: receivedGapTests, //JSON.stringify(receivedGapTests),
    schoolYear: schoolYear,
  };

  try {
    const user = yield Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const requestInfo = {
      headers: {
        Authorization: token,
      },
      body: input,
    };

    // console.log("parameters sent to the lamda",requestInfo);
    const data = yield API.put("GapAnalysisApi", "/doGA", requestInfo);
    //let resData = JSON.parse(data);
    // console.log("resData at ui.sagas.js:384", data);

    let resData = data;
    // console.log("data returned from the lambda",data);
    if (data.FunctionError) {
      console.error("error running gap analysis", data);
      yield put(processingFailureAction("Error performing gap analysis"));
    } else {
      yield put(setGapDataAction(resData));
    }
  } catch (error) {
    console.error("error getting network data", error);
    yield put(processingFailureAction("Error getting Gap Analysis Data"));
  }
}

// NOTE: reused logic from Gap Analysis
export function* getMarksData() {
  yield put(setGapDataAction([]));

  const gapFilterType = yield select(getGapFilterType);
  const gapFilterValue = yield select(getGapFilterValue);
  const gapSchools = yield select(getGapSchools);
  const gapTests = yield select(getGapTests);
  const gapTitle = yield select(getGapTitle);
  const gapLearningAreas = yield select(getGapLearningAreas);
  const school = yield select(getSelectedSchool);

  yield put(setLoadingTrue(`Loading Marks Book  - ${gapTitle}...`));

  let input = {
    key: "env",
  };

  const {
    data: {
      getSystemParameter: { paramData },
    },
  } = yield API.graphql(graphqlOperation(getSystemParameter, input));

  const currentEnv = paramData;

  input = {
    studentID: gapFilterType === "Student" ? gapFilterValue.studentID : null,
    classroomID:
      gapFilterType === "Classroom" || gapFilterType === "FocusGroup"
        ? gapFilterValue !== null && gapFilterValue.id !== undefined
          ? gapFilterValue.id
          : gapFilterValue
        : null,
    schoolID: gapFilterType === "Cohort" ? school.id : null,
    yearLevelID:
      gapFilterType === "Cohort" || gapFilterType === "Network"
        ? gapFilterValue !== null && gapFilterValue.id !== undefined
          ? gapFilterValue.id
          : gapFilterValue
        : null,
    networkSchools: gapFilterType === "Network" ? gapSchools : null,
    tests: JSON.stringify(gapTests),
    learningAreas: gapLearningAreas,
  };

  try {
    const credentials = yield Auth.currentCredentials();
    const lambda = new Lambda({
      region: awsExports.aws_project_region,
      credentials: Auth.essentialCredentials(credentials),
    });

    const params = {
      FunctionName: `getGapAnalysisData-${currentEnv}`,
      Payload: JSON.stringify({
        arguments: input,
      }),
    };

    const data = yield lambda.invoke(params).promise();
    const resData = JSON.parse(data.Payload);

    if (data.FunctionError) {
      console.error("error running gap analysis", data);
      yield put(processingFailureAction("Error performing gap analysis"));
    } else {
      yield put(
        setGapDataAction(_.isEmpty(resData) ? ["EMPTY_RESULT"] : resData)
      );
      yield put(setLoadingFalse());
    }
  } catch (error) {
    console.error("error getting network data", error);
    yield put(processingFailureAction("Error getting Gap Analysis Data"));
  }
}

export function* getGapAnalysisAreasTests() {
  const {
    data: {
      listLearningAreas: { items: areas },
    },
  } = yield API.graphql(graphqlOperation(listLearningAreas));
  yield put(setGapLearningAreasAction(areas.map((a) => a.id)));

  const {
    data: {
      listTestTypes: { items: types },
    },
  } = yield API.graphql(graphqlOperation(listTestTypes));
  yield put(setGapLearningAreasAction(types.map((a) => a.id)));
}

export function* onNotificationMarkedAsRead() {
  yield takeLatest(UIActions.MARK_NOTIFICATION_READ, markNotificationAsRead);
}

export function* onUserGapFilterChanged() {
  yield takeLatest(
    UIActions.SAVE_USER_GAPFILTER_SETTINGS,
    saveUserGapAnalysisFilter
  );
}

export function* onSchoolSelected() {
  yield takeLatest(UIActions.SET_SELECTED_SCHOOL, setUserGapAnalysisFilter);
}

export function* onRunGapAnalysis() {
  yield takeLatest(UIActions.RUN_GAP_ANALYSIS, runGapAnalysis);
}

export function* onRunGapAnalysisMarksbook() {
  yield takeLatest(
    UIActions.RUN_GAP_ANALYSIS_MARKSBOOK,
    runGapAnalysisMarksbook
  );
}

export function* onRunGetMarksData() {
  yield takeLatest(UIActions.RUN_GET_MARKS_DATA, getMarksData);
}

export function* onRunStudentGapAnalysis() {
  yield takeLatest(UIActions.RUN_STUDENT_GAP_ANALYSIS, runStudentGapAnalysis);
}

export function* onRunClassroomGapAnalysis() {
  yield takeLatest(UIActions.RUN_CLASS_GAP_ANALYSIS, runClassroomGapAnalysis);
}

export function* uiSagas() {
  yield all([
    call(onNotificationMarkedAsRead),
    call(onUserGapFilterChanged),
    call(onSchoolSelected),
    call(onRunGapAnalysis),
    call(onRunGapAnalysisMarksbook),
    call(onRunStudentGapAnalysis),
    call(onRunClassroomGapAnalysis),
    call(onRunGetMarksData),
  ]);
}
