import UserActions from "./user.actiontypes";

export const signInSuccess = (user, profile) => ({
  type: UserActions.SIGN_IN_SUCCESS,
  payload: { user, profile },
});

export const signInFailure = (error) => ({
  type: UserActions.SIGN_IN_FAILURE,
  payload: error,
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActions.EMAIL_SIGN_IN_START,
  payload: { ...emailAndPassword, message: "Signing In..." },
});

export const checkUserSession = (message) => ({
  type: UserActions.CHECK_USER_SESSION,
  payload: { message },
});

export const signOutStart = (schoolID, userType) => ({
  type: UserActions.SIGN_OUT_START,
  payload: { schoolID, userType },
});

export const signOutSuccess = () => ({
  type: UserActions.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActions.SIGN_OUT_FAILURE,
  payload: error,
});

export const setCognitoChallenge = (user, value) => ({
  type: UserActions.SET_COGNITO_CHALLENGE,
  payload: { currentUser: user, cognitoChallenge: value},
});

export const setCognitoChallengeSuccess = () => ({
  type: UserActions.COGNITO_CHALLENGE_SUCCESS,
  payload: { cognitoChallenge: false},
});

export const setCompletePassword = (user, value) => ({
  type: UserActions.SET_COMPLETE_PASSWORD,
  payload: { currentUser: user, completeNewPassword: value },
});

export const setCurrentUser = (user) => ({
  type: UserActions.SET_CURRENT_USER,
  payload: user,
});

export const completeUserNewPassword = (user, password) => ({
  type: UserActions.COMPLETE_USER_PASSWORD,
  payload: { currentUser: user, password },
});

export const resetUserPassword = (username, email, newpassword, permanent) => ({
  type: UserActions.RESET_USER_PASSWORD_START,
  payload: { username, email, password: newpassword, permanent },
});

export const resetUserPasswordFailure = (username, error) => ({
  type: UserActions.RESET_USER_PASSWORD_FAILURE,
  payload: { username, error },
});

export const resetUserPasswordSuccess = (username) => ({
  type: UserActions.RESET_USER_PASSWORD_SUCCESS,
  payload: username,
});

export const userForgotPasswordStart = (
  username,
  setError,
  setShowChangePassword
) => ({
  type: UserActions.USER_FORGOT_PASSWORD_START,
  payload: { username, setError, setShowChangePassword },
});

export const userForgotPasswordFailure = (error) => ({
  type: UserActions.USER_FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const userForgotPasswordSuccess = () => ({
  type: UserActions.USER_FORGOT_PASSWORD_SUCCESS,
});

export const userForgotPasswordSubmit = (
  username,
  code,
  newPassword,
  statusFunc
) => ({
  type: UserActions.USER_FORGOT_PASSWORD_SUBMIT,
  payload: { username, code, newPassword, statusFunc },
});

export const userChangePasswordStart = (passwordData) => ({
  type: UserActions.USER_CHANGE_PASSWORD_START,
  payload: { ...passwordData },
});

export const userChangePasswordSuccess = () => ({
  type: UserActions.USER_CHANGE_PASSWORD_SUCCESS,
});

export const userChangePasswordFailure = (error) => ({
  type: UserActions.USER_CHANGE_PASSWORD_FAILURE,
  payload: error,
});

export const disableUserAccountStart = (username) => ({
  type: UserActions.DISABLE_USER_START,
  payload: username,
});

export const enableUserAccountStart = (username) => ({
  type: UserActions.ENABLE_USER_START,
  payload: username,
});

export const setUserParametersAction = (parameters) => ({
  type: UserActions.SET_USER_PARAMETERS,
  payload: parameters,
});
