import Actions from "./wam.actionTypes";
const initialState = {
  wamSelectedTaskType: "",
  wamSelectedActivities: null,
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case Actions.SET_SELECTED_ACTIVITIES:
      return { ...state, wamSelectedActivities: payload };

    case Actions.SET_SELECTED_TASK_TYPE:
      return { ...state, wamSelectedTaskType: payload };

    default:
      return state;
  }
};
