// This is the floating action button that shows/hides the "email us" form (a.k.a the MessageUsFormComponent)
import React from "react";
import { Button } from "devextreme-react/button";
import { Popover } from "devextreme-react/popover";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { MessageUsFormComponent } from "./MessageUsFormComponent";

import ROLES from "utils/UserTypes";

/**
 * An action button that shows/hides the "email us" form (a.k.a the MessageUsFormComponent)
 * @param {object} props
 * @param {string} [props.className = "chat-widget-container"] - the css class name(s) to apply to the component
 * @returns {JSX.Element}
 */
const MessageUsFloatingWidget = ({className = "chat-widget-container"}) => {
  const userType = useSelector((state) => state.user.userProfile.userType);
  // only display for non-students
  return (
    userType !== ROLES.Student && (
      <div className={className}>
        <div className="chat-widget">
          <Button
            id="chat-widget-button"
            icon="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-forward_to_inbox' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M20.016 3.984h-16.031q-0.797 0-1.383 0.586t-0.586 1.43v12q0 0.844 0.586 1.43t1.383 0.586h9v-2.016h-9v-9.984l8.016 4.969 8.016-4.969v4.969h1.969v-6.984q0-0.844-0.586-1.43t-1.383-0.586zM12 11.016l-8.016-5.016h16.031zM18.984 15l4.031 3.984-4.031 4.031v-3h-3.984v-2.016h3.984v-3z'%3E%3C/path%3E%3C/svg%3E"
            hint="Message Us"
          />
          <Popover
            target="#chat-widget-button"
            title="Email Us"
            showTitle
            showEvent="click"
            showCloseButton
            position="top"
            minWidth={500}
          >
            <MessageUsFormComponent />
          </Popover>
        </div>
      </div>
    )
  );
};

MessageUsFloatingWidget.propTypes = {
  className: PropTypes.string,
};

export { MessageUsFloatingWidget};
