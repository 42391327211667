import React from "react";
import { useSelector } from "react-redux";
import SchoolLogoName from "./SchoolLogoName";

const SchoolHeaderDetail = () => {
  const school = useSelector((state) => state.app.selectedSchool);
  const userGroup = useSelector((state) => state.user.userProfile.userGroup);
  const className = userGroup === "Admins" ? { cursor: "pointer" } : null;

  return school ? (
   <div style={className}>
      <SchoolLogoName school={school} />
    </div>
  ) : (
    <div />
  );
};

export default SchoolHeaderDetail;
