export const getUser = /* GraphQL */ `
  query GetUser($email: String!) {
    getUser(email: $email) {
      userId
      firstName
      lastName
      email
      userGroup
      userType
      enabled
      avatar {
        bucket
        region
        key
      }
      userSchoolID
      lastSignIn
      school {
        id
        schoolName
      }
    }
  }
`;

export const getUserByUserId = /* GraphQL */ `
  query GetUserByUserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        email
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        tour
      }
    }
  }
`;

export const getAcCodeIdByCode = /* GraphQL */ `
  query AcCodeByCode(
    $acCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelAcCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acCodeByCode(
      acCode: $acCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        acCode
      }
    }
  }
`;

export const getSchoolByName = /* GraphQL */ `
  query GetSchoolByName(
    $schoolName: String
    $countryID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolByName(
      schoolName: $schoolName
      countryID: $countryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolName
        domainName
        motto
        studentLoginEnabled
        logo {
          bucket
          region
          key
        }
      }
    }
  }
`;

export const getClassByYear = /* GraphQL */ `
  query GetClassByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        className
      }
    }
  }
`;

export const getClassesByTypeByYear = /* GraphQL */ `
  query GetClassesByTypeByYear(
    $schoolID: ID
    $classTypeSchoolYear: ModelClassroomByTypeBySchoolYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassesByTypeByYear(
      schoolID: $schoolID
      classTypeSchoolYear: $classTypeSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classType
        focusGroupType
        className
        schoolYear
        schoolID
        teachers {
          items {
            user {
              userId
              firstName
              lastName
              email
            }
            id
          }
        }
        learningAreas {
          items {
            learningArea {
              id
              areaName
            }
            id
          }
        }
        yearLevels {
          items {
            yearLevel {
              id
              description
            }
            id
          }
        }
        students {
          items {
            id
            classroomID
            studentID
          }
        }
      }
      nextToken
    }
  }
`;

export const getClassroomDetail = /* GraphQL */ `
  query GetClassroom($id: ID!) {
    getClassroom(id: $id) {
      id
      classType
      focusGroupType
      className
      schoolYear
      schoolID
      school {
        id
        schoolName
        networkID
      }
      yearLevels {
        items {
          id
          classroomID
          schoolID
          yearLevelID
        }
      }
      teachers {
        items {
          id
          email
        }
      }
      students {
        items {
          id
          classroomID
          studentID
        }
      }
    }
  }
`;

export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      firstName
      middleName
      lastName
      gender
      birthDate
      photo {
        bucket
        region
        key
      }
      classrooms {
        items {
          classroomID
        }
      }
      yearLevelID
    }
  }
`;

export const getStudentFull = /* GraphQL */ `
  query GetStudentFull($id: ID!) {
    getStudent(id: $id) {
      id
      firstName
      middleName
      lastName
      gender
      birthDate
      photo {
        bucket
        region
        key
      }
      yearLevelID
      currentYear {
        id
        yearCode
        description
        type
        createdAt
        updatedAt
      }
      classrooms {
        items {
          classroom {
            className
            schoolYear
            teachers {
              items {
                user {
                  firstName
                  lastName
                }
              }
            }
            id # classroomID
          }
          id # classroomStudentID
        }
        nextToken
      }
    }
  }
`;

export const getStudentsByClassroom = /* GraphQL */ `
  query GetStudentsByClassroom(
    $classroomID: ID
    $studentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentsByClassroom(
      classroomID: $classroomID
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        studentID
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          yearLevelID
        }
      }
      nextToken
    }
  }
`;

export const getClassroomStudentLinks = /* GraphQL */ `
  query GetStudentsByClassroom(
    $classroomID: ID
    $studentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentsByClassroom(
      classroomID: $classroomID
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        studentID
      }
      nextToken
    }
  }
`;

export const getStudentByNameByBirthDate = /* GraphQL */ `
  query GetStudentByNameByBirthDate(
    $firstName: String
    $lastNameBirthDate: ModelStudentByStudentNamesBirthDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentByNameByBirthDate(
      firstName: $firstName
      lastNameBirthDate: $lastNameBirthDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        birthDate
      }
      nextToken
    }
  }
`;

export const getStudentByUserIdMinimal = /* GraphQL */ `
  query GetSchoolStudentByUserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        userId
      }
      nextToken
    }
  }
`;

export const getStudentBySchoolMinimal = /* GraphQL */ `
  query GetStudentBySchool(
    $schoolID: ID
    $schoolYearStudentID: ModelSchoolStudentBySchoolCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentBySchool(
      schoolID: $schoolID
      schoolYearStudentID: $schoolYearStudentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        userId
      }
      nextToken
    }
  }
`;

export const getSchoolStudentsByYear = /* GraphQL */ `
  query GetSchoolStudentsByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        userId
        firstName
        lastName
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        studentDeparted
        yearLevel {
          description
        }
        student {
          id
          firstName
          middleName
          lastName
          studentUniqueIdentifier
          gender
          birthDate
          classrooms {
            items {
              classroom {
                className
                schoolID
                schoolYear
                classType
              }
            }
          }
        }
        school {
          schoolName
        }
        user {
          items {
            email
            enabled
          }
        }
      }
      nextToken
    }
  }
`;

export const getSchoolStudent = /* GraphQL */ `
  query GetSchoolStudent($id: ID!) {
    getSchoolStudent(id: $id) {
      id
      schoolID
      studentID
      schoolYear
      yearLevelID
      userId
      yearLevel {
        description
      }
      student {
        id
        firstName
        middleName
        lastName
        gender
        birthDate
      }
      school {
        schoolName
      }
    }
  }
`;

export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolName
        motto
        countryID
        stateID
        networkID
        logo {
          bucket
          region
          key
        }
      }
      nextToken
    }
  }
`;

export const getSchoolsSorted = /* GraphQL */ `
  query GetSchoolsSorted(
    $dummy: String
    $schoolName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolsSorted(
      dummy: $dummy
      schoolName: $schoolName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolName
        domainName
        motto
        studentLoginEnabled
        ealdProgress
        countryID
        stateID
        overrideWAMAccess
        overridePAMAccess
        networkID
        schoolNetwork {
          id
          networkName
        }
        logo {
          bucket
          region
          key
        }
        salesForceAccountID
        state {
          stateCode
        }
        country {
          countryCode
        }
      }
      nextToken
    }
  }
`;

export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      schoolName
      domainName
      motto
      studentLoginEnabled
      ealdProgress
      countryID
      stateID
      overrideWAMAccess
      overridePAMAccess
      networkID
      schoolNetwork {
        id
        networkName
      }
      logo {
        bucket
        region
        key
      }
    }
  }
`;

export const getStudentBySchoolIdOnly = /* GraphQL */ `
  query GetStudentBySchool(
    $schoolID: ID
    $schoolYearStudentID: ModelSchoolStudentBySchoolCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentBySchool(
      schoolID: $schoolID
      schoolYearStudentID: $schoolYearStudentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getStudentBySchoolList = /* GraphQL */ `
  query GetStudentBySchool(
    $schoolID: ID
    $schoolYearStudentID: ModelSchoolStudentBySchoolCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentBySchool(
      schoolID: $schoolID
      schoolYearStudentID: $schoolYearStudentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        yearLevelID
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          yearLevelID
        }
      }
      nextToken
    }
  }
`;

export const getQuestionMinimal = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      acCodeID
      isPremium
      acCode {
        acCode
      }
      yearLevelID
      yearLevel {
        description
      }
      learningAreaID
      strandID
      substrandID
      question
      questionType
      image {
        bucket
        region
        key
      }
      answers {
        items {
          correct
          createdAt
          id
          image {
            bucket
            key
            region
          }
          questionID
          text
          inputFieldWidth
          weight
          updatedAt
        }
      }
      imageRow
      hasHTMLContent
      isClozeQuestion
      answerSelectionType
      answerTitle
      messageForCorrectAnswer
      messageForIncorrectAnswer
      explanation
      points
      difficulty
    }
  }
`;

export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      acCodeID
      yearLevelID
      learningAreaID
      strandID
      substrandID
      question
      questionType
      image {
        bucket
        region
        key
      }
      imageRow
      answerSelectionType
      answerTitle
      messageForCorrectAnswer
      messageForIncorrectAnswer
      explanation
      points
      difficulty
      hasHTMLContent
      isPremium
      bookletID
      booklet {
        id
        name
        bookletContent
        areQuestionsRandomised
        createdAt
        updatedAt
        bookletQuestions {
          nextToken
        }
      }
      orderInBooklet
      isClozeQuestion
      isArchived
      createdAt
      updatedAt
      learningArea {
        id
        areaName
        colour
        createdAt
        updatedAt
      }
      strand {
        id
        strandName
        learningAreaID
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        substrands {
          nextToken
        }
      }
      subStrand {
        id
        substrandName
        strandID
        image {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
      }
      yearLevel {
        id
        yearCode
        numericYearCode
        description
        type
        createdAt
        updatedAt
      }
      acCode {
        id
        acCode
        strandID
        substrandID
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        criteria
        elaboration
        type
        createdAt
        updatedAt
        learningArea {
          id
          areaName
          colour
          createdAt
          updatedAt
        }
        strand {
          id
          strandName
          learningAreaID
          createdAt
          updatedAt
        }
        substrand {
          id
          substrandName
          strandID
          createdAt
          updatedAt
        }
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
      }
      answers {
        items {
          id
          questionID
          text
          correct
          inputFieldWidth
          weight
          image {
            key
            bucket
            region
          }
          score
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isPremium
        acCodeID
        acCode {
          acCode
        }
        yearLevelID
        yearLevel {
          description
        }
        learningAreaID
        learningArea {
          areaName
        }
        strandID
        strand {
          strandName
        }
        substrandID
        subStrand {
          substrandName
        }
        question
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        bookletID
        booklet {
          name
        }
        points
        difficulty
        hasHTMLContent
        isClozeQuestion
        isArchived
        answers {
          items {
            id
            questionID
            text
            correct
            inputFieldWidth
            weight
            image {
              key
              bucket
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getQuestionsByAcCode = /* GraphQL */ `
  query GetQuestionsByAcCode(
    $acCodeID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByAcCode(
      acCodeID: $acCodeID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isPremium
        acCodeID
        acCode {
          acCode
        }
        yearLevelID
        yearLevel {
          description
        }
        learningAreaID
        learningArea {
          areaName
        }
        strandID
        strand {
          strandName
        }
        substrandID
        subStrand {
          substrandName
        }
        question
        questionType
        isClozeQuestion
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        hasHTMLContent
        difficulty
        bookletID
        orderInBooklet
        answers {
          items {
            id
            questionID
            text
            correct
            inputFieldWidth
            weight
            image {
              key
              bucket
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getQuestionsByLearningArea = /* GraphQL */ `
  query GetQuestionsByLearningArea(
    $learningAreaID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByLearningArea(
      learningAreaID: $learningAreaID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isPremium
        acCodeID
        acCode {
          acCode
        }
        yearLevelID
        yearLevel {
          description
        }
        learningAreaID
        learningArea {
          areaName
        }
        strandID
        strand {
          strandName
        }
        substrandID
        subStrand {
          substrandName
        }
        question
        hasHTMLContent
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        bookletID
        booklet {
          name
        }
        answers {
          items {
            id
            questionID
            text
            correct
            image {
              key
              bucket
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getQuestionsByYearLevel = /* GraphQL */ `
  query GetQuestionsByYearLevel(
    $yearLevelID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByYearLevel(
      yearLevelID: $yearLevelID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isPremium
        acCodeID
        acCode {
          acCode
        }
        yearLevelID
        yearLevel {
          description
        }
        learningAreaID
        learningArea {
          areaName
        }
        strandID
        strand {
          strandName
        }
        substrandID
        subStrand {
          substrandName
        }
        question
        isClozeQuestion
        isArchived
        hasHTMLContent
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        bookletID
        booklet {
          name
        }
        difficulty
        answers {
          items {
            id
            questionID
            text
            inputFieldWidth
            correct
            weight
            image {
              key
              bucket
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getQuestionsByStrand = /* GraphQL */ `
  query GetQuestionsByStrand(
    $strandID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByStrand(
      strandID: $strandID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isPremium
        acCodeID
        acCode {
          acCode
        }
        yearLevelID
        yearLevel {
          description
        }
        learningAreaID
        learningArea {
          areaName
        }
        strandID
        strand {
          strandName
        }
        substrandID
        subStrand {
          substrandName
        }
        question
        hasHTMLContent
        bookletID
        booklet {
          name
        }
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        answers {
          items {
            id
            questionID
            text
            correct
            image {
              key
              bucket
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getQuestionsBySubtrand = /* GraphQL */ `
  query GetQuestionsBySubtrand(
    $substrandID: ID
    $difficulty: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsBySubtrand(
      substrandID: $substrandID
      difficulty: $difficulty
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isPremium
        acCodeID
        acCode {
          acCode
        }
        yearLevelID
        yearLevel {
          description
        }
        learningAreaID
        learningArea {
          areaName
        }
        strandID
        strand {
          strandName
        }
        substrandID
        subStrand {
          substrandName
        }
        question
        hasHTMLContent
        bookletID
        questionType
        image {
          bucket
          region
          key
        }
        imageRow
        answerSelectionType
        answerTitle
        messageForCorrectAnswer
        messageForIncorrectAnswer
        explanation
        points
        difficulty
        answers {
          items {
            id
            questionID
            text
            correct
            image {
              key
              bucket
              region
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getClassByNameByYear = /* GraphQL */ `
  query GetClassByNameByYear(
    $schoolID: ID
    $schoolYearClassName: ModelClassroomByClassNameBySchoolYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassByNameByYear(
      schoolID: $schoolID
      schoolYearClassName: $schoolYearClassName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        className
        classType
        schoolYear
        schoolID
      }
    }
  }
`;

export const getClassByNameByYearWithStudents = /* GraphQL */ `
  query GetClassByNameByYear(
    $schoolID: ID
    $schoolYearClassName: ModelClassroomByClassNameBySchoolYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassByNameByYear(
      schoolID: $schoolID
      schoolYearClassName: $schoolYearClassName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        className
        classType
        schoolYear
        schoolID
        teachers {
          items {
            id
            email
          }
        }
        students {
          items {
            id
            classroomID
            studentID
          }
        }
      }
    }
  }
`;

export const getClassTeachers = /* GraphQL */ `
  query GetClassTeachers(
    $classroomID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassTeachers(
      classroomID: $classroomID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        email
      }
    }
  }
`;

export const getNetworkByName = /* GraphQL */ `
  query GetNetworkByName(
    $networkName: String
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNetworkByName(
      networkName: $networkName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        networkName
      }
      nextToken
    }
  }
`;

export const getSchoolNetwork = /* GraphQL */ `
  query GetSchoolNetwork($id: ID!) {
    getSchoolNetwork(id: $id) {
      id
      networkName
    }
  }
`;

export const listSchoolNetworks = /* GraphQL */ `
  query ListSchoolNetworks(
    $filter: ModelSchoolNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolNetworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        networkName
      }
      nextToken
    }
  }
`;

export const getSchoolsByNetwork = /* GraphQL */ `
  query GetSchoolsByNetwork(
    $networkID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolsByNetwork(
      networkID: $networkID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolName
        domainName
        motto
        studentLoginEnabled
        ealdProgress
        networkID
        countryID
        stateID
      }
      nextToken
    }
  }
`;

export const getAttributeCategoriesBySchool = /* GraphQL */ `
  query GetAttributeCategoriesBySchool(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolAttributeCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAttributeCategoriesBySchool(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryName
      }
      nextToken
    }
  }
`;

export const getSchoolAttributeCategory = /* GraphQL */ `
  query GetSchoolAttributeCategory($id: ID!) {
    getSchoolAttributeCategory(id: $id) {
      id
      schoolID
      categoryName
    }
  }
`;

export const getAttributeCategoryBySchoolByName = /* GraphQL */ `
  query GetAttributeCategoryBySchoolByName(
    $schoolID: ID
    $categoryName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolAttributeCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAttributeCategoryBySchoolByName(
      schoolID: $schoolID
      categoryName: $categoryName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryName
      }
      nextToken
    }
  }
`;

export const getStudentAttributesBySchool = /* GraphQL */ `
  query GetStudentAttributesBySchool(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentAttributeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentAttributesBySchool(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        categoryID
        category {
          categoryName
        }
        attributeName
        attributeType
        defaultValue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getStudentDataByYear = /* GraphQL */ `
  query GetStudentDataByYear(
    $studentID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentDataByYear(
      studentID: $studentID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolYear
        attributeID
        value
      }
      nextToken
    }
  }
`;

export const getSchoolStudentsByYearAndYearLevel = /* GraphQL */ `
  query GetSchoolStudentsByYearAndYearLevel(
    $schoolID: ID
    $schoolYearYearLevelID: ModelSchoolStudentBySchoolYearAnYearLevelCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndYearLevel(
      schoolID: $schoolID
      schoolYearYearLevelID: $schoolYearYearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        userId
        firstName
        lastName
        hasAccessToWAM
        assignedDateToWAM
        hasAccessToPremiumAssessment
        assignedDateToPremiumAssessment
        yearLevel {
          id
          yearCode
          description
        }
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          classrooms {
            items {
              classroom {
                id
                className
                schoolID
                schoolYear
                classType
              }
            }
          }
        }
        school {
          schoolName
        }
        user {
          items {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const getSchoolCohortLinks = /* GraphQL */ `
  query GetSchoolStudentsByYearAndYearLevel(
    $schoolID: ID
    $schoolYearYearLevelID: ModelSchoolStudentBySchoolYearAnYearLevelCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndYearLevel(
      schoolID: $schoolID
      schoolYearYearLevelID: $schoolYearYearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
      }
      nextToken
    }
  }
`;

export const getSchoolStudentsByYearAndLastName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndLastName(
    $schoolID: ID
    $schoolYearLastName: ModelSchoolStudentBySchoolYearAndLastNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndLastName(
      schoolID: $schoolID
      schoolYearLastName: $schoolYearLastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        userId
        firstName
        lastName
        yearLevel {
          id
          yearCode
          description
        }
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          yearLevelID
          classrooms {
            items {
              classroom {
                className
                schoolID
                schoolYear
                classType
              }
            }
          }
        }
        school {
          schoolName
        }
        user {
          items {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const getSchoolStudentsByYearAndFirstName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndFirstName(
    $schoolID: ID
    $schoolYearFirstName: ModelSchoolStudentBySchoolYearAndFirstNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndFirstName(
      schoolID: $schoolID
      schoolYearFirstName: $schoolYearFirstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        firstName
        lastName
        studentID
        schoolYear
        yearLevelID
        userId
        yearLevel {
          id
          yearCode
          description
        }
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          yearLevelID
          classrooms {
            items {
              classroom {
                className
                schoolID
                schoolYear
                classType
              }
            }
          }
        }
        school {
          schoolName
        }
        user {
          items {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const getSchoolStudentsByYearAndLevelAndFirstName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndLevelAndFirstName(
    $schoolID: ID
    $schoolYearYearLevelIDFirstName: ModelSchoolStudentBySchoolYearAndLevelAndFirstNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndLevelAndFirstName(
      schoolID: $schoolID
      schoolYearYearLevelIDFirstName: $schoolYearYearLevelIDFirstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        userId
        firstName
        lastName
        yearLevel {
          id
          yearCode
          description
        }
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          classrooms {
            items {
              classroom {
                className
                schoolID
                schoolYear
                classType
              }
            }
          }
        }
        school {
          schoolName
        }
        user {
          items {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const getSchoolStudentsByYearAndLevelAndLastName = /* GraphQL */ `
  query GetSchoolStudentsByYearAndLevelAndLastName(
    $schoolID: ID
    $schoolYearYearLevelIDLastName: ModelSchoolStudentBySchoolYearAndLevelAndlastNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolStudentsByYearAndLevelAndLastName(
      schoolID: $schoolID
      schoolYearYearLevelIDLastName: $schoolYearYearLevelIDLastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        userId
        firstName
        lastName
        yearLevel {
          id
          yearCode
          description
        }
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
          classrooms {
            items {
              classroom {
                className
                schoolID
                schoolYear
                classType
              }
            }
          }
        }
        school {
          schoolName
        }
        user {
          items {
            email
          }
        }
      }
      nextToken
    }
  }
`;

export const getUserMinimal = /* GraphQL */ `
  query GetUser($email: String!) {
    getUser(email: $email) {
      userId
      firstName
      lastName
      email
      userGroup
      userType
      enabled
      userSchoolID
      lastSignIn
    }
  }
`;

export const getTeachersBySchool = /* GraphQL */ `
  query GetTeachersBySchool(
    $userSchoolID: ID
    $userType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeachersBySchool(
      userSchoolID: $userSchoolID
      userType: $userType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        email
        userGroup
        userType
        enabled
        userSchoolID
        lastSignIn
      }
      nextToken
    }
  }
`;

export const getUsersBySchool = /* GraphQL */ `
  query GetUsersBySchool(
    $userSchoolID: ID
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersBySchool(
      userSchoolID: $userSchoolID
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        email
        userGroup
        userType
        enabled
        userSchoolID
        lastSignIn
      }
      nextToken
    }
  }
`;

export const getTeacherClassrooms = /* GraphQL */ `
  query GetTeacherClassrooms(
    $email: String
    $classroomID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeacherClassrooms(
      email: $email
      classroomID: $classroomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;

export const getActivitiesByCreator = /* GraphQL */ `
  query GetActivitiesByCreator($creatorEmail: String, $nextToken: String) {
    getActivitiesByCreator(creatorEmail: $creatorEmail, nextToken: $nextToken) {
      items {
        id
      }
    }
  }
`;

export const getUsersByEmail = /* GraphQL */ `
  query GetUsersByEmail(
    $dbType: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByEmail(
      dbType: $dbType
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        email
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
      }
      nextToken
    }
  }
`;

export const getUsersByFirstName = /* GraphQL */ `
  query GetUsersByFirstName(
    $dbType: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByFirstName(
      dbType: $dbType
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        email
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
      }
      nextToken
    }
  }
`;

export const getUsersByLastName = /* GraphQL */ `
  query GetUsersByLastName(
    $dbType: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByLastName(
      dbType: $dbType
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        firstName
        lastName
        email
        userGroup
        userType
        enabled
        avatar {
          bucket
          region
          key
        }
        userSchoolID
        lastSignIn
        dbType
      }
      nextToken
    }
  }
`;

export const getTestByNameMinimal = /* GraphQL */ `
  query GetTestByName(
    $testName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestByName(
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testName
      }
    }
  }
`;

export const getTestByName = /* GraphQL */ `
  query GetTestByName(
    $testName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestByName(
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        testName
        testYearLevelId
        year
        nationalMean
        testUnitID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
        }
        testType {
          id
          typeName
        }
        testUnit {
          id
          unitName
        }
        learningAreas {
          items {
            id
          }
        }
        questions {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testName
        questions {
          items {
            id
          }
        }
        testUploads {
          items {
            id
            testID
            testDate
            testResults {
              items {
                studentID
                student {
                  firstName
                  lastName
                }
                resultAnswers {
                  items {
                    studentAnswer
                    testQuestion {
                      acCode {
                        acCode
                      }
                      correctAnswer
                      difficulty
                      questionNo
                    }
                  }
                }
              }
            }
            assessments {
              items {
                testID
                schoolID
                assessmentName
                assignedDate
                student {
                  firstName
                  lastName
                }
                score
                isStarted
                isComplete
                completedDate
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listTestsMinimal = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dataType
        typeID
        testName
        shortName
        testYearLevelId
        year
        nationalMean
        testUnitID
        subTestTypeID
        isHidden
        createdAt
        updatedAt
        schoolID
        creatorUser {
          items {
            firstName
            lastName
            school {
              id
            }
          }
        }
        learningAreas {
          items {
            learningArea {
              id
              areaName
            }
          }
        }
        testUploads {
          items {
            id
          }
        }
        yearLevel {
          id
          yearCode
          description
          type
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        questions(limit: 10000) {
          items {
            id
            itemId
          }
        }
        yearLevel {
          description
        }
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
      }
      nextToken
    }
  }
`;

export const getTestsSortedByName = /* GraphQL */ `
  query GetTestsSortedByName(
    $dataType: String
    $testName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsSortedByName(
      dataType: $dataType
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeID
        testName
        testYearLevelId
        year
        nationalMean
        testUnitID
        yearLevel {
          id
          description
        }
        testType {
          id
          typeName
          description
        }
        testUnit {
          id
          unitName
        }
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
      }
      nextToken
    }
  }
`;

export const getTestsByType = /* GraphQL */ `
  query GetTestsByType(
    $typeID: ID
    $testName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByType(
      typeID: $typeID
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        testName
        shortName
        testYearLevelId
        year
        nationalMean
        testUnitID
        subTestTypeID
        createdAt
        updatedAt
        schoolID
        creatorUser {
          items {
            firstName
            lastName
            school {
              id
            }
          }
        }
        learningAreas {
          items {
            learningArea {
              areaName
            }
          }
        }
        testUploads {
          items {
            id
          }
        }
        yearLevel {
          id
          yearCode
          description
          type
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          createdAt
          updatedAt
        }
        testUnit {
          id
          unitName
          createdAt
          updatedAt
        }
        questions(limit: 10000) {
          items {
            id
            itemId
          }
        }
        yearLevel {
          description
        }
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
      }
      nextToken
    }
  }
`;

export const getTestsByYearLevel = /* GraphQL */ `
  query GetTestsByYearLevel(
    $testYearLevelId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByYearLevel(
      testYearLevelId: $testYearLevelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        testName
        testYearLevelId
        year
        nationalMean
        testUnitID
        yearLevel {
          id
          description
        }
        testType {
          id
          typeName
          description
        }
        testUnit {
          id
          unitName
        }
        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
      }
      nextToken
    }
  }
`;

export const getTestsByTypeByYearLevel = /* GraphQL */ `
  query GetTestsByTypeByYearLevel(
    $typeID: ID
    $testYearLevelId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByTypeByYearLevel(
      typeID: $typeID
      testYearLevelId: $testYearLevelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        testName
        testYearLevelId
        year
        nationalMean
        testUnitID
        yearLevel {
          id
          description
        }
        testType {
          id
          typeName
          description
        }
        testUnit {
          id
          unitName
        }

        thresholdScaledBottom
        thresholdScaledTop
        thresholdScoreBottom
        thresholdScoreTop
        thresholdStanineBottom
        thresholdStanineTop
      }
      nextToken
    }
  }
`;

export const getTestQuestion = /* GraphQL */ `
  query GetTestQuestion($id: ID!) {
    getTestQuestion(id: $id) {
      id
      testID
      questionNo
      maxScore
      nationalMean
      expectedMean
      correctAnswer
      acCodeID
      difficulty
      answerType
      acCode {
        id
        acCode
        yearLevelID
        learningAreaID
        curriculumEntry
        skill
        learningArea {
          id
          areaName
        }
        strand {
          id
          strandName
        }
        substrand {
          id
          substrandName
        }
        yearLevel {
          id
          description
        }
      }
    }
  }
`;

export const getQuestionsByTest = /* GraphQL */ `
  query GetQuestionsByTest(
    $testID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionsByTest(
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        acCode {
          id
          acCode
          strandID
          substrandID
          yearLevelID
          learningAreaID
          skill
        }
      }
      nextToken
    }
  }
`;

export const getStudentDataByAttributeByYear = /* GraphQL */ `
  query GetStudentDataByAttributeByYear(
    $studentID: ID
    $attributeIDSchoolYear: ModelStudentDataByAttributeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentDataByAttributeByYear(
      studentID: $studentID
      attributeIDSchoolYear: $attributeIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        attributeID
        value
      }
    }
  }
`;

export const getTestUpload = /* GraphQL */ `
  query GetTestUpload($id: ID!) {
    getTestUpload(id: $id) {
      id
      testDate
      testID
      typeID
      yearLevelID
      schoolID
      schoolYear
      resultFile {
        bucket
        region
        key
      }
      createdAt
      yearLevel {
        description
      }
      school {
        id
        schoolName
      }
      testType {
        id
        typeName
        description
      }
      test {
        id
        testName
        learningAreas {
          items {
            learningAreaID
          }
        }
      }
    }
  }
`;

export const getTestUploadsByTypeByYear = /* GraphQL */ `
  query GetTestUploadsByTypeByYear(
    $typeID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsByTypeByYear(
      typeID: $typeID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        yearLevel {
          description
        }
        school {
          id
          schoolName
        }
        testType {
          id
          typeName
          description
        }
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsBySchoolByYear = /* GraphQL */ `
  query GetTestUploadsBySchoolByYear(
    $schoolID: ID
    $schoolYear: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYear(
      schoolID: $schoolID
      schoolYear: $schoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        yearLevel {
          description
        }
        school {
          id
          schoolName
        }
        testType {
          id
          typeName
          description
        }
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsBySchoolByYearLevel = /* GraphQL */ `
  query GetTestUploadsBySchoolByYearLevel(
    $schoolID: ID
    $schoolYearYearLevelID: ModelTestUploadBySchoolYearLevelCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYearLevel(
      schoolID: $schoolID
      schoolYearYearLevelID: $schoolYearYearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        yearLevel {
          description
        }
        school {
          id
          schoolName
        }
        testType {
          id
          typeName
          description
        }
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadByTestByDate = /* GraphQL */ `
  query GetTestUploadByTestByDate(
    $schoolID: ID
    $schoolYearTestIDTestDate: ModelTestUploadExistUploadCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadByTestByDate(
      schoolID: $schoolID
      schoolYearTestIDTestDate: $schoolYearTestIDTestDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const listTestUploads = /* GraphQL */ `
  query ListTestUploads(
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        learningAreaID
        learningArea {
          areaName
        }
        yearLevel {
          description
        }
        school {
          id
          schoolName
        }
        testType {
          id
          typeName
        }
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsByYear = /* GraphQL */ `
  query GetTestUploadsByYear(
    $schoolYear: Int
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsByYear(
      schoolYear: $schoolYear
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        yearLevel {
          description
        }
        school {
          id
          schoolName
        }
        testType {
          id
          typeName
          description
        }
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsBySchoolByYearByType = /* GraphQL */ `
  query GetTestUploadsBySchoolByYearByType(
    $schoolID: ID
    $schoolYearTypeID: ModelTestUploadBySchoolByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYearByType(
      schoolID: $schoolID
      schoolYearTypeID: $schoolYearTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        creatorUserID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          networkID
          countryID
          stateID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          testName
          shortName
          testYearLevelId
          year
          nationalMean
          testUnitID
          createdAt
          updatedAt
          questions {
            items {
              id
              acCode {
                acCode
              }
              testID
              questionNo
              itemId
              maxScore
              nationalMean
              expectedMean
              correctAnswer
              acCodeID
              difficulty
              answerType
            }
          }
        }
        testResults {
          items {
            completedDate
            student {
              id
              firstName
              middleName
              lastName
              gender
              birthDate
              yearLevelID
            }
          }
          nextToken
        }
        assessments {
          items {
            assignedDate
            completedDate
            isComplete
            isStarted
            questionCount
            assessmentName
            score
            testUploadID
            student {
              id
              firstName
              middleName
              lastName
              gender
              birthDate
              yearLevelID
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsBySchoolByYearByYearLevelByType = /* GraphQL */ `
  query GetTestUploadsBySchoolByYearByYearLevelByType(
    $schoolID: ID
    $schoolYearYearLevelIDTypeID: ModelTestUploadBySchoolByTypeByYearLevelByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYearByYearLevelByType(
      schoolID: $schoolID
      schoolYearYearLevelIDTypeID: $schoolYearYearLevelIDTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        learningAreaID
        learningArea {
          areaName
        }
        yearLevel {
          description
        }
        school {
          id
          schoolName
        }
        testType {
          id
          typeName
          description
        }
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsBySchoolByAreaByTypeByYear = /* GraphQL */ `
  query GetTestUploadsBySchoolByAreaByTypeByYear(
    $schoolID: ID
    $learningAreaIDTypeIDSchoolYear: ModelTestUploadBySchoolByAreaByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByAreaByTypeByYear(
      schoolID: $schoolID
      learningAreaIDTypeIDSchoolYear: $learningAreaIDTypeIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        resultFile {
          bucket
          region
          key
        }
        createdAt
        learningAreaID
        learningArea {
          areaName
        }
        yearLevel {
          description
        }
        school {
          id
          schoolName
        }
        testType {
          id
          typeName
        }
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsBySchoolGapFilter = /* GraphQL */ `
  query GetTestUploadsBySchoolByAreaByTypeByYear(
    $schoolID: ID
    $learningAreaIDTypeIDSchoolYear: ModelTestUploadBySchoolByAreaByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByAreaByTypeByYear(
      schoolID: $schoolID
      learningAreaIDTypeIDSchoolYear: $learningAreaIDTypeIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        testID
        typeID
        yearLevelID
        schoolID
        schoolYear
        test {
          id
          testName
        }
      }
      nextToken
    }
  }
`;

export const getTestResult = /* GraphQL */ `
  query GetTestResult($id: ID!) {
    getTestResult(id: $id) {
      id
      testID
      testDate
      studentID
      testType {
        id
        typeName
      }
      test {
        id
        testName
        learningAreas {
          items {
            learningAreaID
          }
        }
      }
    }
  }
`;

export const getTestResultsByTestUpload = /* GraphQL */ `
  query GetTestResultsByTestUpload(
    $testUploadID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByTestUpload(
      testUploadID: $testUploadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testDate
        completedDate
        studentID
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        testUploadID
        yearLevel {
          id
          description
        }
        school {
          id
          schoolName
          networkID
        }
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
        }
        test {
          id
          typeID
          testName
          year
          nationalMean
          testUnitID
        }
        resultAnswers {
          items {
            id
            testResultID
            testQuestionID
            studentAnswer
            proficiency
            testQuestion {
              id
              testID
              questionNo
              maxScore
              nationalMean
              expectedMean
              correctAnswer
              acCodeID
              acCode {
                id
                acCode
                strandID
                strand {
                  strandName
                }
                substrandID
                substrand {
                  substrandName
                }
              }
              difficulty
              answerType
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getSchoolClassroomsByYearLevel = /* GraphQL */ `
  query GetSchoolClassroomsByYearLevel(
    $schoolID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolClassroomsByYearLevel(
      schoolID: $schoolID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        schoolID
        yearLevelID
      }
    }
  }
`;

export const getClassroomYearLevelById = /* GraphQL */ `
  query GetClassroomYearLevelById(
    $classroomID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClassroomYearLevelByID(
      classroomID: $classroomID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        schoolID
        yearLevelID
      }
    }
  }
`;

export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      dataType
      typeID
      testName
      testYearLevelId
      year
      nationalMean
      testUnitID
      createdAt
      updatedAt
      yearLevel {
        id
        yearCode
        description
      }
      testType {
        id
        typeName
      }
      learningAreas {
        items {
          id
          testID
          learningAreaID
        }
        nextToken
      }
      testUnit {
        id
        unitName
      }
      questions {
        items {
          id
          testID
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
        }
      }
    }
  }
`;

export const getTestResultsByStudentByAreaByTypeByYear = /* GraphQL */ `
  query GetTestResultsByStudentByAreaByTypeByYear(
    $studentID: ID
    $learningAreaIDTypeIDSchoolYear: ModelTestResultLearningAreaByStudentByAreaByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByStudentByAreaByTypeByYear(
      studentID: $studentID
      learningAreaIDTypeIDSchoolYear: $learningAreaIDTypeIDSchoolYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        testDate
        testResult {
          id
          testID
          test {
            testName
          }
        }
      }
      nextToken
    }
  }
`;

export const getTestResultLearningAreaMinimal = /* GraphQL */ `
  query GetTestResultLearningArea($id: ID!) {
    getTestResultLearningArea(id: $id) {
      id
      testResultID
      studentID
      learningAreaID
      typeID
      schoolYear
      yearLevelID
      schoolID
      testDate
      testResult {
        id
        testID
        test {
          id
          testName
        }
      }
    }
  }
`;

export const getTestResultAnswers = /* GraphQL */ `
  query GetTestResultAnswers($id: ID!) {
    getTestResultAnswers(id: $id) {
      id
      testResultID
      testQuestionID
      studentAnswer
      proficiency
      testQuestion {
        id
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        acCode {
          acCode
          strandID
          substrandID
          yearLevelID
          strand {
            strandName
          }
          substrand {
            id
            substrandName
          }
          yearLevel {
            yearCode
            description
          }
        }
      }
      testResult {
        id
        testID
        testDate
        completedDate
        studentID
        learningAreaID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        proficiency
        testUploadID
        student {
          id
          firstName
          middleName
          lastName
          gender
          birthDate
        }
      }
    }
  }
`;

export const getTestResultsAnswersByTestResult = /* GraphQL */ `
  query GetTestResultsAnswersByTestResult(
    $testResultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsAnswersByTestResult(
      testResultID: $testResultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResultID
        testQuestionID
        studentAnswer
        proficiency
        testQuestion {
          id
          questionNo
          itemId
          maxScore
          nationalMean
          expectedMean
          correctAnswer
          acCodeID
          difficulty
          answerType
          acCode {
            acCode
            strandID
            substrandID
            yearLevelID
            curriculumEntry
            skill
            learningArea {
              id
              areaName
              colour
            }
            strand {
              strandName
            }
            substrand {
              id
              substrandName
            }
            yearLevel {
              id
              yearCode
              description
            }
          }
        }
        testResult {
          id
          testID
          testDate
          completedDate
          studentID
          typeID
          schoolYear
          yearLevelID
          schoolID
          score
          scale
          stanine
          percentile
          proficiency
          testUploadID
          student {
            id
            firstName
            middleName
            lastName
            gender
            birthDate
          }
          test {
            testName
          }
        }
      }
      nextToken
    }
  }
`;

export const getTestResultsByTestByStudentByTestDate = /* GraphQL */ `
  query GetTestResultsByTestByStudentByTestDate(
    $testID: ID
    $studentIDTestDate: ModelTestResultByTestByStudentByTestDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByTestByStudentByTestDate(
      testID: $testID
      studentIDTestDate: $studentIDTestDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultAnswers {
          items {
            id
            testResultID
            testQuestionID
            studentAnswer
            proficiency
            testQuestion {
              id
              questionNo
              itemId
              maxScore
              nationalMean
              expectedMean
              correctAnswer
              acCodeID
              difficulty
              answerType
              acCode {
                acCode
                strandID
                substrandID
                yearLevelID
                curriculumEntry
                skill
                learningArea {
                  id
                  areaName
                  colour
                }
                strand {
                  strandName
                }
                substrand {
                  id
                  substrandName
                }
                yearLevel {
                  id
                  yearCode
                  description
                }
              }
            }
            testResult {
              id
              testID
              testDate
              completedDate
              studentID
              typeID
              schoolYear
              yearLevelID
              schoolID
              score
              scale
              stanine
              percentile
              proficiency
              testUploadID
              createdAt
              updatedAt
              student {
                id
                firstName
                middleName
                lastName
                gender
                birthDate
              }
              test {
                testName
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadForMarksbook = /* GraphQL */ `
  query GetTestUpload($id: ID!) {
    getTestUpload(id: $id) {
      assessments(limit: 800) {
        items {
          testID
          isComplete
          isStarted
        }
      }
      testResults(limit: 800) {
        items {
          id
          studentID
          yearLevelID
          schoolID
          schoolYear
          score
          testDate
          completedDate
          percentile
          proficiency
          stanine
          scale
          level
          percentage
          nationalBand
          proficiency
          minStandard
          school {
            schoolName
          }
          yearLevel {
            description
            yearCode
          }
          student {
            id
            firstName
            lastName
            classrooms {
              items {
                classroomID
              }
            }
            currentYear {
              id
            }
            schoolYears {
              items {
                yearLevelID
                schoolYear
              }
            }
          }
          test {
            id
            testName
            shortName
            nationalMean
            thresholdScaledBottom
            thresholdScaledTop
            thresholdScoreBottom
            thresholdScoreTop
            thresholdStanineBottom
            thresholdStanineTop
            yearLevel {
              description
            }
            testUnit {
              unitName
              id
            }
            learningAreas {
              items {
                testID
                learningAreaID
                id
              }
            }
          }
          testType {
            typeName
            description
            testUnit {
              unitName
              id
            }
            testCategory {
              name
            }
          }
          learningAreas {
            items {
              testResultID
              learningAreaID
            }
          }
        }
      }
    }
  }
`;

export const getTestResultsByStudentMarksbook = /* GraphQL */ `
  query GetTestResultsByStudentMarksbook($studentID: ID!) {
    getTestResultsByStudent(limit: 10000, studentID: $studentID) {
      nextToken
      items {
        test {
          id
          shortName
          testName
          year
          yearLevel {
            yearCode
            type
            numericYearCode
            description
            id
          }
        }
        typeID
        testUpload {
          id
          testDate
          schoolYear
        }
        completedDate
        learningAreas {
          items {
            learningAreaID
          }
        }
      }
    }
  }
`;

export const getTestResultsByTest = /* GraphQL */ `
  query GetTestResultsByTest(
    $testID: ID
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByTest(
      testID: $testID
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testDate
        completedDate
        studentID
        typeID
        schoolYear
        yearLevelID
        schoolID
        score
        scale
        stanine
        percentile
        percentage
        nationalBand
        minStandard
        level
        testUploadID
        createdAt
        updatedAt
        yearLevel {
          id
          yearCode
          numericYearCode
          description
          type
          createdAt
          updatedAt
        }
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        testType {
          id
          typeName
          description
          testUnitID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }

        learningAreas {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getTestResultsByTestLinks = /* GraphQL */ `
  query GetTestResultsByTest(
    $testID: ID
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByTest(
      testID: $testID
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        yearLevelID
        schoolID
        score
        testDate
        percentile
        proficiency
        stanine
        scale
        school {
          schoolName
        }
        yearLevel {
          description
          yearCode
        }
        student {
          firstName
          lastName
        }
        test {
          testName
        }
        testType {
          typeName
        }
        learningAreas {
          items {
            testResultID
            learningAreaID
          }
        }
      }
      nextToken
    }
  }
`;

export const getTestUploadsForFilter = /* GraphQL */ `
  query GetTestUploadsBySchoolByYearByType(
    $schoolID: ID
    $schoolYearTypeID: ModelTestUploadBySchoolByTypeByYearCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestUploadsBySchoolByYearByType(
      schoolID: $schoolID
      schoolYearTypeID: $schoolYearTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testDate
        test {
          id
          testName
          testType {
            id
            typeName
          }
          learningAreas {
            items {
              learningAreaID
              learningArea {
                areaName
                colour
              }
            }
          }
        }
        testResults {
          items {
            id
            studentID
          }
        }
      }
      nextToken
    }
  }
`;

export const getTeacherClassroomsForFiltering = /* GraphQL */ `
  query GetTeacherClassrooms(
    $email: String
    $classroomID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeacherClassrooms(
      email: $email
      classroomID: $classroomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classroomID
        email
        user {
          firstName
          lastName
          email
        }
        classroom {
          id
          classType
          focusGroupType
          className
          schoolYear
          schoolID
          yearLevels {
            items {
              yearLevel {
                id
                description
              }
              id
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getClassroom = /* GraphQL */ `
  query GetClassroom($id: ID!) {
    getClassroom(id: $id) {
      id
      classType
      focusGroupType
      className
      schoolYear
      schoolID
      school {
        id
        schoolName
        networkID
      }
      yearLevels {
        items {
          id
          classroomID
          schoolID
          yearLevelID
          yearLevel {
            description
          }
        }
      }
      teachers {
        items {
          id
          classroomID
          email
        }
      }
      learningAreas {
        items {
          id
          classroomID
          learningAreaID
          learningArea {
            areaName
          }
        }
      }
      students {
        items {
          id
          classroomID
          studentID
          student {
            id
            firstName
            lastName
            currentYear {
              id
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getQuestionNoByTest = /* GraphQL */ `
  query GetQuestionNoByTest(
    $testID: ID
    $questionNo: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionNoByTest(
      testID: $testID
      questionNo: $questionNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
        }
      }
    }
  }
`;

export const getQuestionByTestItemId = /* GraphQL */ `
  query GetQuestionByTestItemId(
    $testID: ID
    $itemId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getQuestionByTestItemId(
      testID: $testID
      itemId: $itemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        questionNo
        itemId
        maxScore
        nationalMean
        expectedMean
        correctAnswer
        acCodeID
        difficulty
        answerType
        createdAt
        updatedAt
        acCode {
          id
          acCode
        }
      }
    }
  }
`;

export const getStudentSchoolsForUpdate = /* GraphQL */ `
  query GetStudentSchools(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentSchools(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolID
        studentID
        schoolYear
        yearLevelID
        firstName
        lastName
        userId
      }
    }
  }
`;

export const getGapAnalysisTests = /* GraphQL */ `
  query GetGapAnalysisTests(
    $studentID: String
    $classroomID: String
    $schoolID: String
    $yearLevelID: String
    $networkSchools: [String]
    $learningAreas: [String]
    $testTypes: [String]
    $startYear: Int
    $endYear: Int
    $testDate: String
  ) {
    getGapAnalysisTests(
      studentID: $studentID
      classroomID: $classroomID
      schoolID: $schoolID
      yearLevelID: $yearLevelID
      networkSchools: $networkSchools
      learningAreas: $learningAreas
      testTypes: $testTypes
      startYear: $startYear
      endYear: $endYear
      testDate: $testDate
    ) {
      id
      testID
      testName
      testDate
      learningAreaID
      testKey
    }
  }
`;

export const getStudentTestsForAnalysis = /* GraphQL */ `
  query GetTestResultsByStudent(
    $studentID: ID
    $testDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestResultsByStudent(
      studentID: $studentID
      testDate: $testDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        testDate
        studentID
        typeID
        schoolYear
        yearLevelID
        testUploadID
        percentile
        proficiency
        scale
        score
        stanine
        level
        createdAt
        test {
          testName
        }
        resultAnswers {
          items {
            studentAnswer
            proficiency
            testQuestion {
              questionNo
              correctAnswer
              difficulty
              acCode {
                acCode
              }
            }
          }
        }
        learningAreas {
          items {
            learningAreaID
          }
        }
      }
      nextToken
    }
  }
`;

export const getGapAnalysisData = /* GraphQL */ `
  query GetGapAnalysisData(
    $studentID: String
    $classroomID: String
    $schoolID: String
    $yearLevelID: String
    $networkSchools: [String]
    $tests: [String]
    $learningAreas: [String]
  ) {
    getGapAnalysisData(
      studentID: $studentID
      classroomID: $classroomID
      schoolID: $schoolID
      yearLevelID: $yearLevelID
      networkSchools: $networkSchools
      tests: $tests
      learningAreas: $learningAreas
    ) {
      learningAreaID
      areaName
      colour
      numStudents
      summary {
        year
        totalCodes
        incorrectCodes
        studentCount
        items {
          acCode {
            acCode
            strandID
            substrandID
            yearLevelID
            curriculumEntry
            skill
            learningArea {
              id
              areaName
              colour
            }
            strand {
              strandName
            }
            substrand {
              id
              substrandName
            }
            yearLevel {
              id
              yearCode
              description
            }
          }
          students {
            id
            firstName
            middleName
            lastName
            gender
            birthDate
            gapRatio
          }
        }
      }
    }
  }
`;

export const getStudentEALDProgressMap = /* GraphQL */ `
  query getStudentEALDProgressMap($id: ID!) {
    getStudentEALDProgressMap(id: $id) {
      id
      studentId
      isLocked
      isCarryOver
      previousMapId
      nextMapId
      stage
      currentYearLevelID
      currentYearLevel
      schoolYear
      studentProgressMapLevelResponses {
        id
        studentProgressMapId
        studentProgressMap
        LevelId
        achievement
        comment
        studentProgressMapItemResponses {
          id
          studentProgressMapId
          studentProgressMapLevelResponseId
          itemId
          achievement
        }
      }
    }
  }
`;

export const listLearningAreas = /* GraphQL */ `
  query ListLearningAreas(
    $filter: ModelLearningAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        areaName
        colour
      }
      nextToken
    }
  }
`;

export const getTestType = /* GraphQL */ `
  query GetTestType($id: ID!) {
    getTestType(id: $id) {
      id
      typeName
      description
      testUnitID
      createdAt
      updatedAt
      testUnit {
        id
        unitName
        createdAt
        updatedAt
      }
      testCategoryID
      testCategory {
        id
        name
        description
      }
    }
  }
`;

export const listTestTypes = /* GraphQL */ `
  query ListTestTypes(
    $filter: ModelTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        description
        testUnit {
          unitName
          id
        }
        testCategoryID
        testCategory {
          id
          name
          description
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTestTypeByName = /* GraphQL */ `
  query GetTestTypeByName(
    $typeName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestTypeByName(
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTestUnits =
  /* GraphQL */
  `
    query ListTestUnits(
      $filter: ModelTestUnitFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTestUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          unitName
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

export const listHelpGuides = /* GraphQL */ `
  query ListHelpGuides(
    $filter: ModelHelpGuideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpGuides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        link
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getClassroomsByStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      classrooms {
        items {
          classroom {
            className
            id
            schoolYear
          }
        }
      }
    }
  }
`;
export const getYearLevelsByCode = /* GraphQL */ `
  query GetYearLevelsByCode(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelYearLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getYearLevelsByCode(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearCode
        description
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getSubTestTypeByTestType = /* GraphQL */ `
  query GetSubTestTypeByTestType(
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $typeID: ID
  ) {
    getSubTestTypeByTestType(
      typeID: $typeID
      sortDirection: $sortDirection
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id
        description
        createdAt
        subTestTypeName
        typeID
        updatedAt
      }
      nextToken
    }
  }
`;

export const listRubricVideos = /* GraphQL */ `
  query ListRubricVideos(
    $filter: ModelRubricVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRubricVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rubricID
        yearLevelID
        videoLink
        Rubric {
          id
          rubricName
          description
          taskType
        }
      }
      nextToken
    }
  }
`;

export const getRubricVideoByUserId = /* GraphQL */ `
  query GetRubricYearLevel(
    $rubricID: ID
    $yearLevelID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRubricVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRubricYearLevel(
      rubricID: $rubricID
      yearLevelID: $yearLevelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rubricID
        yearLevelID
        videoLink
      }
    }
  }
`;

export const getStudentAssessment = /* GraphQL */ `
  query GetStudentAssessment(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentAssessment(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        schoolID
        classRoomID
        isComplete
        testID
        assessmentName
        testResultID
        isStarted
        currentYearLevelID
        schoolYear
        score
        outOf
        questionIDsOrdered
        questionCount
        assignedDate
        completedDate
        dueDate
        testUploadID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          createdAt
          updatedAt
        }
        student {
          id
          firstName
          middleName
          lastName
          wondeID
          MISID
          gender
          birthDate
          yearLevelID
          createdAt
          updatedAt
        }
        test {
          id
          dataType
          typeID
          subTestTypeID
          testName
          testYearLevelId
          year
          nationalMean
          creatorUserID
          sharedByUserID
          sharedWithUserIDs
          isRandomOrder
          isSortedByComplexity
          testUnitID
          schoolID
          thresholdScaledBottom
          thresholdScaledTop
          thresholdScoreBottom
          thresholdScoreTop
          thresholdStanineBottom
          thresholdStanineTop
          shortName
          folderID
          createdAt
          updatedAt
        }
        testUpload {
          id
          testDate
          testID
          typeID
          yearLevelID
          schoolID
          schoolYear
          creatorUserID
          createdAt
          updatedAt
          isHideScoresFromStudents
          testResults {
            items {
              id
              studentID
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getTestCategory = /* GraphQL */ `
  query GetTestCategory($id: ID!) {
    getTestCategory(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const listTestCategorys = /* GraphQL */ `
  query ListTestCategorys(
    $filter: ModelTestCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTestCategoryByName = /* GraphQL */ `
  query GetTestCategoryByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelTestCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestCategoryByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTestTypeByTestCategoryID = /* GraphQL */ `
  query GetTestTypeByTestCategoryID(
    $testCategoryID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestTypeByTestCategoryID(
      testCategoryID: $testCategoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeName
        description
        testUnitID
        testCategoryID
        testUnit {
          id
          unitName
        }
        testCategory {
          id
          name
          description
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTestsByTestTypeBySchool = /* GraphQL */ `
  query GetTestsByTestTypeBySchool(
    $typeID: ID!
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByTestTypeBySchool(
      typeID: $typeID
      schoolID: { eq: $schoolID }
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        schoolID
        shortName
        testName
        year
        yearLevel {
          description
          numericYearCode
          yearCode
        }
        typeID
        testType {
          typeName
          description
          testCategory {
            name
            id
          }
        }
        id
        dataType
        testYearLevelId
      }
      nextToken
    }
  }
`;

export const getTestsByTypeMinimal = /* GraphQL */ `
  query getTestsByTypeMinimal(
    $typeID: ID
    $testName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTestsByType(
      typeID: $typeID
      testName: $testName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataType
        typeID
        testName
        shortName
        testYearLevelId
        year
        nationalMean
        testUnitID
        subTestTypeID
        createdAt
        updatedAt
        schoolID
        yearLevel {
          yearCode
          description
          type
        }
        testType {
          typeName
          description
        }
        testUnit {
          unitName
        }
      }
      nextToken
    }
  }
`;

export const getHandwritingLogByActivityID = /* GraphQL */ `
  query getHandwritingLogByActivityID(
    $activityID: ID!
    $filter: ModelHandwritingLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHandwritingLogByActivityID(
      activityID: $activityID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityID
        uploadUserID
        user {
          items {
            firstName
            lastName
            email
          }
        }
        numberOfStudents
        fileUrl
        uploadedFileName
        createdAt
      }
      nextToken
    }
  }
`;

export const getStudentHandwritingLogByLogParent = /* GraphQL */ `
  query GetStudentsHandwritingLogByUploadID(
    $nextToken: String
    $uploadID: ID
    $filter: ModelStudentHandwritingLogFilterInput
  ) {
    getStudentHandwritingLogByLogParent(
      uploadID: $uploadID
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        dobFromTextract
        essayFromTextract
        id
        splitFileS3URL
        studentNameFromTextract
        uploadID
        studentID
        observations
        student {
          firstName
          lastName
          birthDate
        }
      }
      nextToken
    }
  }
`;

export const listMergedStudents = /* GraphQL */ `
  query ListMergedStudents(
    $filter: ModelMergedStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMergedStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        originalSchoolStudentID
        originalStudentID
        duplicatedSchoolStudentID
        duplicatedSchoolStudentObject
        duplicatedStudentID
        duplicatedStudentObject
        isReverted
        createdAt
      }
      nextToken
    }
  }
`;

export const getMergedStudentByOriginalStudentID = /* GraphQL */ `
  query GetMergedStudentByOriginalStudentID(
    $originalStudentID: ID
    $filter: ModelMergedStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMergedStudentByOriginalStudentID(
      originalStudentID: $originalStudentID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalSchoolStudentID
        originalStudentID
        duplicatedSchoolStudentID
        duplicatedSchoolStudentObject
        duplicatedStudentID
        duplicatedStudentObject
        createdAt
      }
      nextToken
    }
  }
`;

export const getApplicationFeatureByFeatureKey = /* GraphQL */ `
  query GetApplicationFeatureByFeatureKey(
    $featureKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getApplicationFeatureByFeatureKey(
      featureKey: $featureKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureFlagGlobalByFeatureID = /* GraphQL */ `
  query GetFeatureFlagGlobalByFeatureID(
    $applicationFeatureID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFeatureFlagGlobalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFeatureFlagGlobalByFeatureID(
      applicationFeatureID: $applicationFeatureID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationFeatureID
        applicationFeature {
          id
          featureKey
          featureValueType
          featureDescription
          createdAt
          updatedAt
          __typename
        }
        featureKey
        featureValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureFlagGlobalByFeatureKey = /* GraphQL */ `
  query GetFeatureFlagGlobalByFeatureKey(
    $featureKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFeatureFlagGlobalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFeatureFlagGlobalByFeatureKey(
      featureKey: $featureKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationFeatureID
        applicationFeature {
          id
          featureKey
          featureValueType
          featureDescription
          createdAt
          updatedAt
          __typename
        }
        featureKey
        featureValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureFlagSchoolByFeatureID = /* GraphQL */ `
  query GetFeatureFlagSchoolByFeatureID(
    $applicationFeatureID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFeatureFlagSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFeatureFlagSchoolByFeatureID(
      applicationFeatureID: $applicationFeatureID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationFeatureID
        applicationFeature {
          id
          featureKey
          featureValueType
          featureDescription
          createdAt
          updatedAt
          __typename
        }
        featureKey
        featureValue
        schoolID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          lastWondeUpdateDate
          salesForceAccountID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureFlagSchoolByFeatureKey = /* GraphQL */ `
  query GetFeatureFlagSchoolByFeatureKey(
    $featureKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFeatureFlagSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFeatureFlagSchoolByFeatureKey(
      featureKey: $featureKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationFeatureID
        applicationFeature {
          id
          featureKey
          featureValueType
          featureDescription
          createdAt
          updatedAt
          __typename
        }
        featureKey
        featureValue
        schoolID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          lastWondeUpdateDate
          salesForceAccountID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureFlagSchoolBySchoolID = /* GraphQL */ `
  query GetFeatureFlagSchoolBySchoolID(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFeatureFlagSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFeatureFlagSchoolBySchoolID(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationFeatureID
        applicationFeature {
          id
          featureKey
          featureValueType
          featureDescription
          createdAt
          updatedAt
          __typename
        }
        featureKey
        featureValue
        schoolID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          lastWondeUpdateDate
          salesForceAccountID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getApplicationFeature = /* GraphQL */ `
  query GetApplicationFeature($id: ID!) {
    getApplicationFeature(id: $id) {
      id
      featureKey
      featureValueType
      featureDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplicationFeatures = /* GraphQL */ `
  query ListApplicationFeatures(
    $filter: ModelApplicationFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationFeatureFlagGlobal = /* GraphQL */ `
  query GetApplicationFeatureFlagGlobal($id: ID!) {
    getApplicationFeatureFlagGlobal(id: $id) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplicationFeatureFlagGlobals = /* GraphQL */ `
  query ListApplicationFeatureFlagGlobals(
    $filter: ModelApplicationFeatureFlagGlobalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationFeatureFlagGlobals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationFeatureID
        applicationFeature {
          id
          featureKey
          featureValueType
          featureDescription
          createdAt
          updatedAt
          __typename
        }
        featureKey
        featureValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationFeatureFlagSchool = /* GraphQL */ `
  query GetApplicationFeatureFlagSchool($id: ID!) {
    getApplicationFeatureFlagSchool(id: $id) {
      id
      applicationFeatureID
      applicationFeature {
        id
        featureKey
        featureValueType
        featureDescription
        createdAt
        updatedAt
        __typename
      }
      featureKey
      featureValue
      schoolID
      createdAt
      updatedAt
      school {
        id
        dummy
        schoolName
        motto
        studentLoginEnabled
        ealdProgress
        wondeID
        MISID
        networkID
        countryID
        stateID
        logo {
          bucket
          region
          key
          __typename
        }
        overrideWAMAccess
        overridePAMAccess
        lastWondeUpdateDate
        salesForceAccountID
        createdAt
        updatedAt
        schoolNetwork {
          id
          networkName
          createdAt
          updatedAt
          __typename
        }
        attributeCategories {
          nextToken
          __typename
        }
        schoolIcseas {
          nextToken
          __typename
        }
        country {
          id
          name
          countryCode
          createdAt
          updatedAt
          __typename
        }
        state {
          id
          name
          stateCode
          countryID
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listApplicationFeatureFlagSchools = /* GraphQL */ `
  query ListApplicationFeatureFlagSchools(
    $filter: ModelApplicationFeatureFlagSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationFeatureFlagSchools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationFeatureID
        applicationFeature {
          id
          featureKey
          featureValueType
          featureDescription
          createdAt
          updatedAt
          __typename
        }
        featureKey
        featureValue
        schoolID
        createdAt
        updatedAt
        school {
          id
          dummy
          schoolName
          motto
          studentLoginEnabled
          ealdProgress
          wondeID
          MISID
          networkID
          countryID
          stateID
          overrideWAMAccess
          overridePAMAccess
          lastWondeUpdateDate
          salesForceAccountID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
