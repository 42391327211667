import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer } from "@coreui/react";
import { useSelector } from "react-redux";

// routes config
import routes from "../routes";

const TheContent = () => {
  const userType = useSelector((state) => state.user.userProfile.userType);

  return (
    <main className="c-main">
      <CContainer fluid style={{ height: "100%", position:"relative" }}>
        <Switch>
          {routes
            .filter((route) => route.group.includes(userType))
            .map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </CContainer>
    </main>
  );
};

export default React.memo(TheContent);
