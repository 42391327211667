import { API, graphqlOperation } from "aws-amplify";
import { getUser, getStudentFull } from "graphql/bpqueries";
import { getSchoolStudentByUserId } from "graphql/queries";
import { getSchoolYear } from "utils/helper";
import { fetchAllNextTokenData } from "utils/AppsyncCommonMethods";
import _ from "lodash";

export const fetchUserByEmail = async (email) => {
  try {
    const response = await API.graphql(graphqlOperation(getUser, { email }));

    return response.data.getUser;
  } catch (error) {
    console.log("error on getting getUser data", error);
  }
};

export const fetchStudentClasses = async (studentID) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getStudentFull, { id: studentID })
    );
    return response.data.getStudent.classrooms.items;
  } catch (error) {
    console.log("error on getting student classes", error);
  }
};

export const fetchSchoolStudents = async (userId) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getSchoolStudentByUserId, { userId })
    );
    return response.data.getSchoolStudentByUserId.items;
  } catch (error) {
    console.log("error on getting student classes", error);
  }
};

export const isLoginValidForStudentClass = async (userId) => {
  let isValid = true;
  let classes = [];
  const schoolYear = getSchoolYear();
  const schoolStudents = await fetchSchoolStudents(userId);

  await Promise.all(
    await schoolStudents.map(async (schoolStudent) => {
      let studentClasses = await fetchStudentClasses(schoolStudent.studentID);

      // filter by current school year
      studentClasses = _.filter(studentClasses, function (obj) {
        return obj.classroom.schoolYear === schoolYear;
      });

      classes.push(...studentClasses);
    })
  );

  if (!classes.length) {
    isValid = false;
  }

  return isValid;
};

export const fetchSchoolStudentsByUserId = async (userId) => {
  try {
    const input = { userId };
    const response = await fetchAllNextTokenData(
      "getSchoolStudentByUserId",
      getSchoolStudentByUserId,
      input
    );

    return response;
  } catch (error) {
    console.log("error on getting school students", error);
  }
};
