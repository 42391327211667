import Actions from "./wam.actionTypes";

export const setSelectedTaskType = (selectedTaskType) => ({
  type: Actions.SET_SELECTED_TASK_TYPE,
  payload: selectedTaskType,
});
export const setSelectedActivities = (selectedActivities) => ({
  type: Actions.SET_SELECTED_ACTIVITIES,
  payload: selectedActivities,
});
