import { InMemoryCache as Cache } from "@aws-amplify/cache";

// set this to true to disable cache in dev, for troubleshooting
const DISABLE_CACHE_IN_DEV = false;

export const DefaultCacheConfig = {
  capacityInBytes: 5 * 1024 * 1024, // 5MB
  itemMaxSize: 5 * 1024 * 1024, // 5MB
  defaultTTL: 5 * 60 * 1000, // 5 mins
};

// if we're in development, collect cache stats.
const isCollectCacheStats = process.env.NODE_ENV === "development";
let queriesSaved = 0;
let dataSaved = 0;
if (isCollectCacheStats) {
  setInterval(() => {
    console.log(
      "cache stats: " +
        queriesSaved +
        " queries saved (" +
        dataSaved +
        " bytes)"
    );
  }, 10000);
}

/**
 * create a new cache instance with the given prefix and the default config
 * @param {string} prefix
 * @returns a new cache instance
 */
export const createCacheInstance = (prefix) => {
  const cache = Cache.createInstance({
    keyPrefix: prefix,
    ...DefaultCacheConfig,
  });
  if (isCollectCacheStats) {
    // only in dev.
    if (DISABLE_CACHE_IN_DEV) {
      // override setItem and getItem
      cache.getItem = (key, options) => null;
      cache.setItem = (key, value, options) => null;
    } else {
      // intercept getItem requests to get cache stats
      const oldGetItem = cache.getItem;
      cache.getItem = (key, options) => {
        const hitOrMiss = oldGetItem(key, options);
        if (hitOrMiss) {
          // got a cache hit.
          queriesSaved++;
          dataSaved += JSON.stringify(hitOrMiss).length;
        }
        return hitOrMiss;
      };
    }
  }
  return cache;
};

// used in GapAnalysisLocal/gapAnalysisTestsLocal.js
export const testUploadsBySchoolByYearCache = createCacheInstance(
  "testUploadsBySchoolByYear"
);

// used in GapAnalysisLocal/gapAnalysisTestsLocal.js
export const classroomsByIDCache = createCacheInstance(
  "classroomsByID"
);

// used in ClassroomSelector
export const classesByTypeByYearCache = createCacheInstance(
  "ClassesByTypeByYear"
);

// used in MarksGridCard
export const marksGridCardCache = createCacheInstance("marksGridCardTest");
export const testUploadForMarksbookCache = createCacheInstance(
  "testUploadForMarksbook"
);

// used in MarksBook
export const testResultsByStudentCache = createCacheInstance(
  "TestResultsByStudentMarksbook"
);
