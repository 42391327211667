import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import { getSchool } from "graphql/bpqueries";
import { fetchWAMDataBySchool } from "../components/wam/wamLicenseRequestHistory/controller/api";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg,
} from "@coreui/react";
import _ from "lodash";
import { MenuUrls, filterTypesOptions } from "../utils/constants";
import { useHistory } from "react-router-dom";
// sidebar nav config
import navigation from "./_nav";
import REGIONS from "utils/Regions";
import UserTypes from "utils/UserTypes";
import { fetchSchoolStudentsByUserId } from "components/user/login/controller/api";

export async function checkWritingAssessmentLicense(school, userProfile) {
  if (!school || Object.keys(school).length === 0) return false;

  try {
    const responseSchool = await API.graphql(
      graphqlOperation(getSchool, { id: school.id })
    );
    const overrideWAMAccess =
      responseSchool?.data?.getSchool?.overrideWAMAccess;

    const responseWAMData = await fetchWAMDataBySchool(school.id);
    let schoolWAMRecord =
      responseWAMData?.data?.getWAMDataBySchoolByModuleType?.items[0];

    const loggedInStudentWamAccess = await hasLoggedInStudentWamAccess(
      userProfile
    );
    return overrideWAMAccess ||
      schoolWAMRecord?.currentLicencesAvailable > 0 ||
      loggedInStudentWamAccess
      ? true
      : false;
  } catch (error) {
    console.log("error", error);
  }
}

const hasLoggedInStudentWamAccess = async (userProfile) => {
  if (userProfile.userType === UserTypes.Student) {
    const schoolStudents = await fetchSchoolStudentsByUserId(
      userProfile.userId
    );

    let hasAccessToWam = _.filter(schoolStudents, function (o) {
      return o.hasAccessToWAM === true;
    });

    return hasAccessToWam;
  }
};

const TheSidebar = ({ tabUrlChanged }) => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.app.sidebarShow);
  const school = useSelector((state) => state.user.userProfile.school || null);
  const userType = useSelector(
    (state) => state.user.userProfile.userType || null
  );
  const sidebarMinimize = useSelector((state) => state.app.sidebarMinimize);
  const userProfile = useSelector((state) => state.user.userProfile);
  const [navItems, setNavItems] = useState([]);

  const ONLINE_ASSESSMENT = "Online Assessment";
  const PROGRESSION = "Progression";
  const history = useHistory();
  const [sidebarMenu, setSidebarMenu] = useState([...navigation]);

  useEffect(() => {
    (async () => {
      // validate writing assessment license
      const access = await checkWritingAssessmentLicense(school, userProfile);
      // if user has not rights
      if (!access && userType !== "SystemAdmin") {
        // remove all submenu under writemark menu
        const menu = _.chain(sidebarMenu)
          .map((item) => {
            if (item.name === "Writemark") {
              const temp = { ...item };
              delete temp._children;
              temp._tag = "CSidebarNavItem";
              return temp;
            }
            return item;
          })
          .value();

        // set again the menu list
        setSidebarMenu(menu);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school, userType]);
  const REGION = useSelector((state) => state.app.region);

  useEffect(() => {
    const items = _.chain(sidebarMenu)
      .filter((item) => {
        if (item.name === ONLINE_ASSESSMENT) {
          return false;
        } else if (item.name === PROGRESSION) {
          if (
            REGION === REGIONS.Australia &&
            userProfile.userType !== filterTypesOptions.STUDENT &&
            userProfile.userType !== filterTypesOptions.AQA
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return _.includes(item.role, userProfile.userType);
        }
      })
      .map((item) => {
        const newItem = { ...item };

        if (newItem._children) {
          const children = newItem._children.filter((c) => {
            c = getC(c);
            return _.includes(c.role, userProfile.userType);
          });

          newItem._children = children;
        }

        return newItem;
      })
      .value();

    setNavItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, tabUrlChanged, sidebarMenu]);

  const getC = (c) => {
    c.activemenu = "";

    if (
      c.to === MenuUrls.WAM_NEWREQUEST &&
      history.location.pathname === MenuUrls.PAM_NEWREQUEST
    ) {
      c.activemenu = "active";
    } else if (
      c.to === MenuUrls.WAM_SCHOOLS &&
      history.location.pathname === MenuUrls.PAM_SCHOOLS
    ) {
      c.activemenu = "active";
    } else if (
      c.to === MenuUrls.WAM_STUDENT_LICENCE_MANAGE &&
      history.location.pathname === MenuUrls.PAM_STUDENT_LICENCE_MANAGE
    ) {
      c.activemenu = "active";
    }

    return c;
  };

  function handleShowChange(val) {
    dispatch({
      type: "set",
      payload: { sidebarShow: val, sidebarMinimize: !show },
    });
  }

  return (
    <CSidebar
      show={show}
      onShowChange={handleShowChange}
      minimize={sidebarMinimize}
      className="c-sidebar-uk"
    >
      <CSidebarBrand className="d-md-down-none bg-white" to="/">
        <CImg
          className="c-sidebar-brand-full"
          src="/Elastik_ID_Blue.png"
          height={45}
          style={{ marginRight: "auto", marginLeft: "15px" }}
        />
        <CImg
          className="c-sidebar-brand-minimized"
          src="/elastik_YouTube_Profile-08.png"
          height={40}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navItems}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
